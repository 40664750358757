import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    cardWrapper: {
        marginVertical: 8,
        marginHorizontal: 4,
        borderRadius: 20,
        backgroundColor: Colors.white,
        borderColor: Colors.gray,
        borderWidth: 1,
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: Colors.dark_gray,
        marginVertical: 8,
        fontFamily: 'eina01-semibold',
        paddingHorizontal: 10,
    },

    selectedWrapper: {
        backgroundColor: Colors.primary,
        borderColor: Colors.primary,
    },
    selectedText: {
        color: Colors.white,
    },

    small: {
        marginVertical: 3,
        marginHorizontal: 2,
    },
    regular: {},
    smallText: {
        fontSize: 12,
        marginVertical: 3,
    },
    regularText: {},
});
