import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    foodPrintCardWrapper: {
        flexDirection: 'row',
        padding: 5,
        alignItems: 'flex-start',
    },
    contentWrapper: {
        flex: 1,
        marginLeft: 12,
    },
    avatarWrapper: {
        // backgroundColor:'red',
        // position: 'absolute',
        // width: 50,
        // height: 50,
        // marginTop: -5,
        // elevation: 5,
        // shadowColor: Colors.charcoal,
        // shadowOffset: { width: 2, height: 2 },
        // shadowOpacity: 0.5,
        // shadowRadius: 2,
    },
    avatar: {
        borderRadius: 75,
        // borderWidth: 1,
        backgroundColor: Colors.gray,
        width: 35,
        height: 35,
        overflow: 'hidden',
    },
    header: {},
    timeText: {
        color: Colors.gray,
        // marginTop: 5,
        fontSize: 12,
        fontFamily: 'eina01',
    },
    highlightText: {
        color: Colors.green,
        fontSize: 12,
        fontFamily: 'eina01-bold',
    },
    nameText: {
        color: Colors.charcoal,
        fontWeight: 'bold',
        fontFamily: 'eina01-bold',
        fontSize: 16,
    },
    eventText: {
        color: Colors.dark_gray,
        fontSize: 16,
    },
});
