import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import { PolicyWidget } from '../../components/PolicyWidget';
import SettingsButton from '../../components/SettingsButton/SettingsButton';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { RootStackParamList, RootTabParamList } from '../../types';
import { styles } from './styles';

function SettingsScreen() {
    const auth = useAuth();
    const navigation =
        useNavigation<NativeStackNavigationProp<RootTabParamList & RootStackParamList>>();
    const handleProfile = () => {
        navigation.navigate('EditProfile');
    };
    const handlePassword = () => {
        navigation.navigate('PasswordReset', {});
    };

    const handlePreference = () => {
        console.log('click preference');
    };
    const handleFeedback = () => {
        console.log('click feedback');
    };

    const handleLogOut = async () => {
        await auth.logout();
        navigation.navigate('SignIn');
    };

    return (
        <View style={styles.screenContainer}>
            <View style={styles.buttonGroupContainer}>
                <SettingsButton
                    leftIconName="user"
                    text="Update profile"
                    rightIconName="chevron-right"
                    onPress={handleProfile}
                />
                <View style={styles.divider} />
                <SettingsButton
                    leftIconName="lock"
                    text="Change password"
                    rightIconName="chevron-right"
                    onPress={handlePassword}
                />
                <View style={styles.divider} />
                {/*<SettingsButton*/}
                {/*    leftIconName="sliders"*/}
                {/*    text="Preference"*/}
                {/*    rightIconName="chevron-right"*/}
                {/*    onPress={handlePreference}*/}
                {/*/>*/}
                {/*<View style={styles.divider} />*/}
                {/*<SettingsButton*/}
                {/*    leftIconName="message-square"*/}
                {/*    text="Send Feedback"*/}
                {/*    rightIconName="chevron-right"*/}
                {/*    onPress={handleFeedback}*/}
                {/*/>*/}
                <View style={styles.divider} />
                <SettingsButton
                    leftIconName="log-out"
                    leftIconColor={Colors.primary}
                    textColor={Colors.primary}
                    text="Log Out"
                    onPress={handleLogOut}
                />
            </View>
            <View style={{ marginTop: 100 }}>
                <PolicyWidget />
            </View>
        </View>
    );
}

export default SettingsScreen;
