import { LocationType, User, UserService } from 'gc-common';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Logger } from '../../utils/logger';
import { SecureStore } from '../../webLibs/Storage/Storage';
import { authService } from './authService';

const saveToken = async (token: string, expire: string) => {
    await Promise.all([
        SecureStore.setItemAsync('auth_access_token', token),
        SecureStore.setItemAsync('auth_expire', expire),
    ]);

    return true;
};

const saveLoginInfo = async (username: string, password: string) => {
    await Promise.all([
        SecureStore.setItemAsync('auth_username', username),
        SecureStore.setItemAsync('auth_password', password),
    ]);

    return true;
};

const getToken = async () => {
    const [token, expire] = await Promise.all([
        SecureStore.getItemAsync('auth_access_token'),
        SecureStore.getItemAsync('auth_expire'),
    ]);

    return {
        token,
        expire: expire ? new Date(parseFloat(expire) * 1000) : null,
    };
};

const getLoginInfo = async () => {
    const [username, password] = await Promise.all([
        SecureStore.getItemAsync('auth_username'),
        SecureStore.getItemAsync('auth_password'),
    ]);

    return {
        username,
        password,
    };
};

const clearAuth = async () => {
    await Promise.all([
        SecureStore.deleteItemAsync('auth_access_token'),
        SecureStore.deleteItemAsync('auth_expire'),
        SecureStore.deleteItemAsync('auth_username'),
        SecureStore.deleteItemAsync('auth_password'),
    ]);

    return true;
};

const _blockUser = async (user: User) => {
    const list = JSON.parse((await SecureStore.getItemAsync('blocked_users')) || '{}');
    list[user.user_id] = user;
    await SecureStore.setItemAsync('blocked_users', JSON.stringify(list));

    return list;
};

const _unblockUser = async (user: User) => {
    const list = JSON.parse((await SecureStore.getItemAsync('blocked_users')) || '{}');
    delete list[user.user_id];
    await SecureStore.setItemAsync('blocked_users', JSON.stringify(list));

    return list;
};

const _getBlockedUsers = async (): Promise<{ [user_id: string]: User }> => {
    const list = JSON.parse((await SecureStore.getItemAsync('blocked_users')) || '{}');
    return list;
};

export const Auth = {
    getToken,
};

export const useAuthHook = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isGeoPermitted, setIsGeoPermitted] = useState(false);
    const [user, setUser] = useState<User>();
    const [location, setLocation] = useState<LocationType>();
    const [notificationToken, setNotificationToken] = useState<string>();
    const [blockedUsers, setBlockedUsers] = useState<{ [user_id: string]: User }>({});

    useEffect(() => {
        _getBlockedUsers().then((data) => setBlockedUsers(data));
    }, []);

    useEffect(() => {
        Logger.user = user;
    }, [user]);

    useEffect(() => {
        Logger.log('Register Device Token : ' + notificationToken);
        if (user && notificationToken) {
            authService.registerNotificationToken(notificationToken, Platform.OS);
        }
    }, [user, notificationToken]);

    const isUserBlocked = (user_id: string) => {
        return !!blockedUsers[user_id];
    };

    const blockUser = async (user: User) => {
        setBlockedUsers(await _blockUser(user));
    };

    const unblockUser = async (user: User) => {
        setBlockedUsers(await _unblockUser(user));
    };

    const checkIsLoggedIn = async () => {
        const { token, expire } = await getToken();
        const now = new Date();

        if (!token) {
            setIsLoggedIn(false);
            return false;
        }

        if (expire && expire < now) {
            setIsLoggedIn(false);
            return false;
        }
        setIsLoggedIn(true);
        fetchUserInfo();
        return true;
    };

    const fetchUserInfo = async () => {
        const rsp = await UserService.getMeInfo();
        console.log('user data', rsp.data);
        setUser(rsp.data);
        return rsp.data;
    };

    const logout = async () => {
        await clearAuth();
        setIsLoggedIn(false);
        setUser(undefined);
    };

    useEffect(() => {
        checkIsLoggedIn();
    }, []);

    return {
        saveToken,
        saveLoginInfo,
        getToken,
        getLoginInfo,
        isLoggedIn,
        checkIsLoggedIn,
        logout,
        fetchUserInfo,
        user,
        location,
        setLocation,
        isGeoPermitted,
        setIsGeoPermitted,
        notificationToken,
        setNotificationToken,

        blockUser,
        unblockUser,
        getBlockedUsers: blockedUsers,
        isUserBlocked,
    };
};
