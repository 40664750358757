import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    wrapper: {
        borderRadius: 10,
        width: '100%',
        backgroundColor: Colors.white,
        elevation: 15,

        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        flexDirection: 'column',
    },
    contentWrapper: {
        paddingHorizontal: 14,
    },
    pic: {
        width: '100%',
        height: 240,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    detailWrapper: {
        marginTop: 12,
        flexDirection: 'row',
    },

    detailContentWrapper: {
        flexDirection: 'column',
        flex: 1,
        // borderWidth:1,
    },
    title: {
        fontFamily: 'eina01-semibold',
        fontSize: 18,
    },
    subText: {
        marginBottom: 5,
    },
    avatar: {
        width: 60,
        height: 60,
        borderRadius: 60,
        overflow: 'hidden',
        marginRight: 12,
    },

    description: {
        marginTop: 12,
    },
    extraWrapper: {
        marginVertical: 12,
        flexDirection: 'row',
    },
    spanner: {
        flex: 1,
    },
    likes: {
        color: Colors.primary,
    },
});
