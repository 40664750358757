import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, PressableProps, Text, TouchableOpacity, View } from 'react-native';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { Card } from '../Card/Card';
import { Ratings } from '../Ratings/Ratings';
import { styles } from './styles';

export interface CommentCardProps extends PressableProps {
    id: string;
    avatar: string;
    avatarId: string;
    author: string;
    city: string;
    content: string;
    createdTime: string;
    images: string[];
    likes: number;
    comments: number;
    rating: number;
    skeleton?: boolean;
}
export const CommentCard = (props: CommentCardProps) => {
    const navigation = useNavigation();

    if (!props.skeleton)
        return (
            <Card key={props.id} onPress={props.onPress}>
                <View style={[styles.cardWrapper]}>
                    <TouchableOpacity
                        style={styles.avatarWrapper}
                        onPress={() => navigation.navigate('User', { id: props.avatarId })}
                    >
                        <Image
                            style={styles.avatarImage}
                            source={{
                                uri: props.avatar,
                            }}
                        />
                    </TouchableOpacity>
                    <View style={styles.contentWrapper}>
                        <Text style={styles.title}>{props.author}</Text>
                        <View style={styles.infoWrapper}>
                            <Text style={[styles.infoText, { flex: 1 }]}>{props.city}</Text>
                            <Text style={styles.infoText}>{props.createdTime}</Text>
                        </View>
                        <Ratings rating={props.rating} />
                        <Text style={styles.content}>{props.content}</Text>
                        <View style={styles.imagesWrapper}>
                            {props.images.map((image) => (
                                <View key={image} style={{ width: '33%', padding: 2 }}>
                                    <Image
                                        style={styles.contentImage}
                                        source={{
                                            uri: image,
                                        }}
                                    />
                                </View>
                            ))}
                        </View>
                        {/*<View style={styles.infoWrapper}>*/}
                        {/*    <Text style={[styles.extraInfoText, { color: Colors.primary }]}>*/}
                        {/*        <FontAwesome name={'heart'} size={15} /> {props.likes}*/}
                        {/*    </Text>*/}
                        {/*    {props.comments > 0 && (*/}
                        {/*        <Text style={styles.extraInfoText}>*/}
                        {/*            <FontAwesome name={'edit'} size={15} /> {props.comments}*/}
                        {/*        </Text>*/}
                        {/*    )}*/}
                        {/*</View>*/}
                        {props.comments > 0 && (
                            <Text style={styles.footerText}>
                                View all {props.comments} comments
                            </Text>
                        )}
                    </View>
                </View>
            </Card>
        );
    else
        return (
            <Card>
                <SkeletonPlaceholder>
                    <View style={[styles.cardWrapper]}>
                        <View style={styles.avatarWrapper}>
                            <SkeletonPlaceholder.Item height={40} width={40} borderRadius={40} />
                        </View>
                        <View style={styles.contentWrapper}>
                            <SkeletonPlaceholder.Item
                                borderRadius={5}
                                marginBottom={5}
                                height={14}
                                width={140}
                            />

                            <View style={styles.infoWrapper}>
                                <SkeletonPlaceholder.Item
                                    borderRadius={5}
                                    marginBottom={5}
                                    height={14}
                                    width={50}
                                />
                            </View>
                            <SkeletonPlaceholder.Item
                                borderRadius={5}
                                marginBottom={5}
                                height={14}
                                width={40}
                            />
                            <SkeletonPlaceholder.Item
                                borderRadius={5}
                                marginBottom={5}
                                height={100}
                                width={'100%'}
                            />
                            <SkeletonPlaceholder.Item
                                borderRadius={5}
                                marginBottom={5}
                                height={14}
                                width={140}
                            />
                            <SkeletonPlaceholder.Item
                                borderRadius={5}
                                marginBottom={5}
                                height={14}
                                width={70}
                            />
                        </View>
                    </View>
                </SkeletonPlaceholder>
            </Card>
        );
};
