import { ReactNode, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { styles } from './styles';

export interface SelectCardProps {
    text: string | ReactNode;
    width?: string | number;
    size?: 'small' | 'regular';

    /**
     * No data binding
     */
    initSelected?: boolean;

    /**
     * With data binding
     */
    selected?: boolean;
    onChange?: (isSelected: boolean) => void;
}
export const SelectCard = (props: SelectCardProps) => {
    const [isSelected, setIsSelected] = useState(props.initSelected ?? false);
    const width = props.width || 'auto';
    const onTouch = () => {
        const value = !isSelected;
        setIsSelected(value);
        props.onChange?.call(this, value);
    };

    return (
        <TouchableOpacity onPress={onTouch}>
            <View
                style={[
                    styles.cardWrapper,
                    (props.selected ?? isSelected) && styles.selectedWrapper,
                    { width },
                    styles[props.size ?? 'regular'],
                ]}
            >
                <Text
                    style={[
                        styles.text,
                        (props.selected ?? isSelected) && styles.selectedText,
                        styles[(props.size ?? 'regular') + 'Text'],
                    ]}
                >
                    {props.text}
                </Text>
            </View>
        </TouchableOpacity>
    );
};
