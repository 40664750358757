import { LocationType } from 'gc-common';

export const LOCATIONS = [
    {
        name: 'Bay Area',
        location: [37.374199005952406, -121.98115832169046].reverse() as LocationType,
    },
    {
        name: 'Los Angeles',
        location: [34.053114422453746, -118.25004851862822].reverse() as LocationType,
    },
    {
        name: 'Seattle',
        location: [47.6058021607631, -122.32902917976305].reverse() as LocationType,
    },
    {
        name: 'New York',
        location: [40.7691820179726, -73.97585949251001].reverse() as LocationType,
    },
    {
        name: 'Boston',
        location: [42.36042112879496, -71.05739768260307].reverse() as LocationType,
    },
];

export const DEFAULT_USER_AVATAR = 'https://webapp.gourmetcarte.com/images/default_avatar.webp';
export const DEFAULT_RESTAURANT_IMAGE =
    'https://webapp.sfo3.cdn.digitaloceanspaces.com/images/default_store.jpeg';
export const DEFAULT_DISH_IMAGE =
    'https://webapp.sfo3.cdn.digitaloceanspaces.com/images/default_dish.jpeg';

export const LOTTIE_JSONS = {
    SUCCESS: 'https://assets6.lottiefiles.com/packages/lf20_pqnfmone.json',
    FAIL: 'https://assets2.lottiefiles.com/packages/lf20_tl52xzvn.json',
    WARNING: 'https://assets10.lottiefiles.com/packages/lf20_dVJMow.json',
    LOADER: 'https://assets6.lottiefiles.com/packages/lf20_p8bfn5to.json',
};

export const GOOGLE_PLACE_API_KEY = 'AIzaSyALpKC--bvMHnplpx1dWX_6AO7VpIOWyCI';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ['lottie-player']: any;
        }
    }
}
