import { atom, useAtom } from 'jotai';
import { loadable } from 'jotai/utils';

const fetchFeatures = async (): Promise<{ [key: string]: boolean }> => {
    return { f_notification: true };
    // try {
    //     return (await ConfigService.getFeatureToggles(Env.APP_ENV)).data || {};
    // } catch (e) {
    //     Logger.error('Failed to get feature toggles', {
    //         env: Env.APP_ENV,
    //     });
    //     return await fetchFeatures();
    // }
};
const featuresAtom = atom(async () => {
    return await fetchFeatures();
});
const loadableFeatures = loadable(featuresAtom);

export const useFeatureToggle = () => {
    const [data] = useAtom(loadableFeatures);

    const isEnabled = (featureName: string) => {
        const value = !!data.data?.['f_' + featureName];
        // console.log(`Getting feature "${featureName}" for env "${Env.APP_ENV}" => ${value}`);
        return value;
    };

    return {
        isEnabled,
        data,
    };
};
