import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../constants/Colors';

export const ExploreScreen = ({ navigation }) => {
    // const bottomSheetRef = useRef<BottomSheet>(null);

    return (
        <View style={styles.container}>
            <Image
                resizeMethod={'scale'}
                resizeMode={'contain'}
                style={styles.icon}
                source={require('../../../assets/images/logo-no-margin.png')}
            />
            <Text style={styles.title}>Sorry!</Text>
            <Text style={styles.subTitle}>This feature is not supported in web browser.</Text>
            <Text style={styles.subTitle}>Please download our App...</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        // padding: 20,
        paddingHorizontal: 50,
        paddingBottom: 100,
        backgroundColor: Colors.white,
    },
    title: {
        fontSize: 36,
        marginVertical: 20,
        color: Colors.charcoal,
        fontFamily: 'eina01-bold',
    },
    subTitle: {
        fontSize: 20,
        marginBottom: 5,
        color: Colors.dark_gray,
    },
    icon: {
        width: '100%',
        // aspectRatio:1,
        // borderWidth:1,
        height: 200,
    },
});
