import { Feather } from '@expo/vector-icons';
import { formatAddress, Restaurant, RestaurantService } from 'gc-common';
import { SearchService } from 'gc-common/services/search/searchService';
import React, { useEffect, useState } from 'react';
import {
    DeviceEventEmitter,
    FlatList,
    ListRenderItem,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { useDebounce } from 'use-debounce';
import { GCButton } from '../../components/Button/GCButton';
import CustomInputText from '../../components/CustomInputText/CustomInputText';
import { RowItem } from '../../components/RowItem/RowItem';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { usePagination } from '../../hooks/usePagination';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { styles } from './styles';

export const RestaurantSelectPage = ({
    route,
    navigation,
}: RootStackScreenProps<'RestaurantSelect'>) => {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchKeywordDebounced] = useDebounce(searchKeyword, 300);
    const modal = useModal();
    const auth = useAuth();

    const {
        data: dataList,
        page,
        fetchData,
    } = usePagination<Restaurant>(async (page, size) => {
        const data = searchKeyword
            ? await SearchService.search(
                  searchKeyword,
                  'restaurant',
                  auth.location || [0, 0],
                  99999999,
                  page,
                  size
              )
            : await RestaurantService.getByGeo(auth.location || [0, 0], 99999999, page, size);
        return data.data;
    }, 0);

    useEffect(() => {
        fetchData(1, 20);
    }, [searchKeywordDebounced]);

    const onSelection = (value: Restaurant) => {
        DeviceEventEmitter.emit('restaurant-selected', value);
        navigation.pop();
    };

    const renderSearchBar = () => {
        return (
            <View style={styles.searchBarContainer}>
                <View style={{ flex: 1 }}>
                    <CustomInputText
                        isPassword={false}
                        leftIcon={'search'}
                        placeholder={'Search restaurant or create a new one.'}
                        onChangeText={setSearchKeyword}
                    />
                </View>
                <TouchableOpacity
                    style={{ padding: 10 }}
                    onPress={() => {
                        navigation.push('RestaurantCreation');
                    }}
                >
                    <Feather name={'plus-circle'} size={25} color={Colors.primary} />
                </TouchableOpacity>
            </View>
        );
    };
    const renderList: ListRenderItem<Restaurant> = ({ item }) => {
        return (
            <View style={styles.rowItemWrapper}>
                <RowItem
                    image={item.avatar_image_url || DEFAULT_RESTAURANT_IMAGE}
                    title={item.name}
                    subTitle={formatAddress(item.address)}
                    onPress={() => {
                        onSelection(item);
                    }}
                />
            </View>
        );
    };

    const renderFooter = () => {
        return (
            <View style={styles.creationButtonContainer}>
                <Text style={styles.creationButtonHelperText}>
                    Can't find what you are looking for?
                </Text>
                <GCButton
                    text={'Create a new one'}
                    onClick={() => navigation.push('RestaurantCreation')}
                />
            </View>
        );
    };
    return (
        <View>
            <FlatList
                data={dataList}
                style={styles.selectionList}
                renderItem={renderList}
                ListHeaderComponent={renderSearchBar()}
                stickyHeaderIndices={[0]}
                ListFooterComponent={renderFooter}
                onEndReached={() => fetchData(page + 1, 20)}
            />
        </View>
    );
};
