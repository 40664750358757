import { FontAwesome } from '@expo/vector-icons';
import BottomSheeta,{
    BottomSheetBackdrop,
    useBottomSheetSpringConfigs,
} from '@gorhom/bottom-sheet';
import { BottomSheet } from '../../webLibs/BottomSheet/BottomSheet';
import { useNavigation } from '@react-navigation/native';
import { formatAddress, LocationUtils, Restaurant, RestaurantService } from 'gc-common';
import React, { useEffect, useRef, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { RowItem } from '../../components/RowItem/RowItem';
import Spinner from '../../components/Spinner/Spinner';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { useCheckIn } from '../../hooks/useCheckIn';
import { DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { styles } from './styles';

export const useLocationDetector = () => {
    const bottomSheetRef = useRef<BottomSheeta>(null);
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const navigation = useNavigation();
    const auth = useAuth();
    const [checkInStatus, setCheckInStatus] = useState<string[]>([]);
    const [checkingInStatus, setCheckingInStatus] = useState<string[]>([]);
    const checkIn = useCheckIn();
    useEffect(() => {
        if (!auth.location) return;
        (async () => {
            const stores = await RestaurantService.getByGeo(auth.location!, 999999999);

            const first = stores.data?.[0];
            setRestaurants(stores.data.slice(0, 3));
            if (LocationUtils.isWithin(first.address.location, auth.location!, 100)) {
                bottomSheetRef.current?.snapToIndex(1);
            } else {
                bottomSheetRef.current?.close();
            }
        })();
    }, [auth.location]);

    const render = () => {
        async function handleCheckIn(restaurantId: string) {
            setCheckingInStatus([...checkingInStatus, restaurantId]);
            console.log('restaurantId', restaurantId);
            if (checkInStatus.includes(restaurantId)) {
                let res = await checkIn.onCheckSingle(restaurantId, false);
                if (res) {
                    setCheckingInStatus(checkingInStatus.filter((item) => item != restaurantId));
                    setCheckInStatus(checkInStatus.filter((item) => item != restaurantId));
                }
            } else {
                let res = await checkIn.onCheckSingle(restaurantId, true);
                if (res) {
                    setCheckingInStatus(checkingInStatus.filter((item) => item != restaurantId));
                    setCheckInStatus([...checkInStatus, restaurantId]);
                }
            }
        }

        return (
            <BottomSheet
                ref={bottomSheetRef}
                index={-1}
                snapPoints={[20, 180, 500].map((i) => (Env.isWeb ? i + 60 : i))}
                backdropComponent={(props) => (
                    <BottomSheetBackdrop
                        {...props}
                        appearsOnIndex={1}
                        disappearsOnIndex={0}
                        pressBehavior={0}
                    />
                )}
            >
                {restaurants.length > 0 && (
                    <View style={styles.wrapper}>
                        <Text style={styles.title}>Are you currently here?</Text>
                        {restaurants.map((res) => {
                            return (
                                <View style={styles.storeCardWrapper} key={res.restaurant_id}>
                                    <RowItem
                                        image={res.avatar_image_url || DEFAULT_RESTAURANT_IMAGE}
                                        title={res.name}
                                        titleNumLines={1}
                                        subTitle={formatAddress(res.address)}
                                        onPress={() => {
                                            navigation.navigate('RestaurantDetail', {
                                                id: res.restaurant_id,
                                            });
                                        }}
                                        sideButton={
                                            <>
                                                {checkingInStatus.includes(res.restaurant_id) ? (
                                                    <View>
                                                        <Spinner style={styles.checkInButton} />
                                                    </View>
                                                ) : (
                                                    <TouchableOpacity
                                                        onPress={() =>
                                                            handleCheckIn(res.restaurant_id)
                                                        }
                                                    >
                                                        <View
                                                            style={[
                                                                styles.checkInButton,
                                                                checkInStatus.includes(
                                                                    res.restaurant_id
                                                                ) && styles.checkInButtonActive,
                                                            ]}
                                                        >
                                                            <FontAwesome
                                                                name={'check'}
                                                                size={19}
                                                                color={'white'}
                                                            />
                                                        </View>
                                                    </TouchableOpacity>
                                                )}
                                            </>
                                        }
                                    />
                                </View>
                            );
                        })}
                    </View>
                )}
            </BottomSheet>
        );
    };

    return {
        render,
        bottomSheet: bottomSheetRef,
    };
};
