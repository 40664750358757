import { ReactNode } from 'react';
import { GestureResponderEvent, Text, TouchableOpacity, View } from 'react-native';
import { CommonStyles } from '../Styles/CommonStyles';
import { styles } from './styles';

export interface GCButtonProps {
    children?: ReactNode;
    text?: string;
    onClick?: (e: GestureResponderEvent) => void;
    shadow?: boolean;
    disabled?: boolean;
    type?: 'primary' | 'secondary';
    size?: 'large' | 'small' | 'tiny';
}
export const GCButton = (props: GCButtonProps) => {
    const { type, shadow, size, disabled } = props;
    return (
        <TouchableOpacity onPress={props.onClick}>
            <View
                style={[
                    styles.button,
                    size && (styles as any)[size + 'Button'],
                    type === 'secondary' && styles.secondary,
                    shadow && CommonStyles.shadow,
                    disabled && styles.disabled,
                ]}
            >
                {props.text ? (
                    <Text
                        style={[
                            styles.text,
                            size && (styles as any)[size + 'Text'],
                            type === 'secondary' && styles.secondaryText,
                        ]}
                    >
                        {props.text}
                    </Text>
                ) : (
                    props.children
                )}
            </View>
        </TouchableOpacity>
    );
};

GCButton.defaultProps = {
    type: 'primary',
};
