import { useRef, useState } from 'react';
import { Logger } from '../utils/logger';

export type PaginationServiceCall<K> = (page: number, size: number) => Promise<K[] | undefined>;
export type PaginationDataWithLoader<K> = K & { $isLoader?: boolean; $loaderKey?: string };

export const usePagination = <T>(
    service: PaginationServiceCall<T>,
    numOfLoader = 2,
    onError?: () => void
) => {
    const [data, setData] = useState<PaginationDataWithLoader<T>[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(true);
    const isFetchingSync = useRef(false);

    const fetchData = async (page = 1, size = 6) => {
        if (isFetchingSync.current || (!hasNext && page !== 1)) return;

        isFetchingSync.current = true;
        setIsFetching(true);
        setData([
            ...(page > 1 ? data : []),
            ...[...new Array(numOfLoader).keys()].map(() => generateLoaderObject<T>()),
        ]);
        try {
            const _data = await service(page, size);
            setPage(page);
            _data &&
                setData([...(page > 1 ? data : []), ...(_data as PaginationDataWithLoader<T>[])]);

            if (_data !== undefined && _data.length === 0) setHasNext(false);
        } catch (e) {
            Logger.error('[AJAX][PAGINATION] Pagination fetch error. | ' + (e as any)?.message);
            setData([...(page > 1 ? data : [])]);
            onError?.();
        }

        setIsFetching(false);
        isFetchingSync.current = false;
    };

    const reset = (withSkeletonLoader = false) => {
        data.slice(0);
        if (withSkeletonLoader) {
            setData([...new Array(numOfLoader).keys()].map(() => generateLoaderObject<T>()));
        } else {
            setData([]);
        }
        setHasNext(true);
    };

    const refresh = async () => {
        reset();
        setIsRefreshing(true);
        await fetchData();
        setIsRefreshing(false);
    };

    return {
        data,
        page,
        setPage,
        isFetching,
        isRefreshing,
        fetchData,
        reset,
        refresh,
    };
};

const generateLoaderObject = <K>() => {
    const key = (Math.random() + 1).toString(36).substring(7);

    return {
        $isLoader: true,
        $loaderKey: key,
    } as PaginationDataWithLoader<K>;
};
