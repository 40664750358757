import * as React from 'react';
import { useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import { styles } from './styles';

export interface RatingsProps {
    rating: number;
    editable?: boolean;
    large?: boolean;
    onRatingPressed?: (n: number) => void;
}
export const Ratings = ({ editable, large, ...props }: RatingsProps) => {
    const [rating, setRating] = useState(props.rating);

    useEffect(() => {
        setRating(props.rating);
    }, [props.rating]);

    const onRatingPressed = (n: number) => {
        if (!editable) return;
        setRating(n);
        props.onRatingPressed?.(n);
    };

    return (
        <View style={styles.ratingWrapper}>
            {Array.from(Array(5).keys()).map((n) => {
                if (n + 1 <= rating) {
                    return (
                        <Pressable onPress={() => onRatingPressed(n + 1)} key={n}>
                            <View
                                style={[
                                    styles.ratingStar,
                                    (editable || large) && styles.editableStar,
                                ]}
                                key={n}
                            >
                                <View
                                    style={[
                                        styles.ratingStarFull,
                                        (editable || large) && styles.editableStarInner,
                                    ]}
                                />
                            </View>
                        </Pressable>
                    );
                } else if (n + 0.5 == rating) {
                    return (
                        <Pressable onPress={() => onRatingPressed(n + 1)} key={n}>
                            <View
                                style={[
                                    styles.ratingStar,
                                    (editable || large) && styles.editableStar,
                                ]}
                                key={n}
                            >
                                <View
                                    style={[
                                        styles.ratingStarHalf,
                                        (editable || large) && styles.editableStarInner,
                                    ]}
                                />
                            </View>
                        </Pressable>
                    );
                } else {
                    return (
                        <Pressable onPress={() => onRatingPressed(n + 1)} key={n}>
                            <View
                                style={[
                                    styles.ratingStar,
                                    (editable || large) && styles.editableStar,
                                ]}
                                key={n}
                            >
                                <View
                                    style={[
                                        styles.ratingStarEmpty,
                                        (editable || large) && styles.editableStarInner,
                                    ]}
                                />
                            </View>
                        </Pressable>
                    );
                }
            })}
            {/*<Text>{props.rating}</Text>*/}
        </View>
    );
};
