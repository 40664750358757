import { Ionicons } from '@expo/vector-icons';
import { Overlay } from '@rneui/themed';
import LottieView from 'lottie-react-native';
import React, { FC, ReactNode, useContext, useRef, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { LOTTIE_JSONS } from '../types/Constants';

const ModalContext = React.createContext<ReturnType<typeof useModalHook> | undefined>(undefined);

export type ModalType = 'loader' | 'success' | 'fail' | 'warning' | 'none';

export const ModalContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const modal = useModalHook();
    return (
        <ModalContext.Provider value={modal}>
            {modal.render()}
            {children}
        </ModalContext.Provider>
    );
};

const useModalHook = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState<ReactNode | string>();
    const [type, setType] = useState<ModalType>();
    const afterBackdropPress = useRef<() => void>();

    const onBackdropPress = () => {
        if (type === 'loader') return;
        setIsOpen(false);
        afterBackdropPress?.current?.call(this);
    };

    const render = () => {
        return (
            <Overlay
                isVisible={isOpen}
                onBackdropPress={onBackdropPress}
                overlayStyle={{ borderRadius: 15 }}
            >
                <View style={styles.overlay}>
                    {type === 'success' && (
                        <Ionicons
                            name={'close'}
                            style={styles.closeIcon}
                            onPress={onBackdropPress}
                        />
                    )}
                    {type !== 'none' && (
                        <View style={styles.iconContainer}>
                            {type === 'success' &&
                                (Platform.OS !== 'web' ? (
                                    <LottieView
                                        source={require('../../assets/images/97240-success.json')}
                                        autoPlay
                                        loop={false}
                                    />
                                ) : (
                                    <lottie-player src={LOTTIE_JSONS.SUCCESS} autoplay />
                                ))}
                            {type === 'fail' &&
                                (Platform.OS !== 'web' ? (
                                    <LottieView
                                        source={require('../../assets/images/56947-icon-failed.json')}
                                        autoPlay
                                        loop={false}
                                    />
                                ) : (
                                    <lottie-player src={LOTTIE_JSONS.FAIL} autoplay />
                                ))}
                            {type === 'warning' &&
                                (Platform.OS !== 'web' ? (
                                    <LottieView
                                        source={require('../../assets/images/117330-warning.json')}
                                        autoPlay
                                        loop
                                    />
                                ) : (
                                    <lottie-player src={LOTTIE_JSONS.WARNING} loop autoplay />
                                ))}
                            {type === 'loader' &&
                                (Platform.OS !== 'web' ? (
                                    <LottieView
                                        source={require('../../assets/images/loader.json')}
                                        autoPlay
                                        loop
                                    />
                                ) : (
                                    <lottie-player src={LOTTIE_JSONS.LOADER} autoplay />
                                ))}
                        </View>
                    )}

                    {typeof content === 'string' ? <Text>{content}</Text> : content}
                </View>
            </Overlay>
        );
    };

    const open = (
        type: ModalType,
        content?: ReactNode | string,
        afterBackdropPressCallback?: () => void
    ) => {
        setType(type);
        setContent(content);
        afterBackdropPress.current = afterBackdropPressCallback;
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    return {
        render,
        open,
        close,
    };
};

export const useModal = () => {
    return useContext(ModalContext)!;
};

export const _useModalHook = useModalHook;

const styles = StyleSheet.create({
    overlay: {
        padding: 5,
        alignItems: 'center',
        minWidth: 150,
    },

    iconContainer: {
        width: 100,
        height: 100,
    },

    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 5,
        fontSize: 18,
    },
});
