import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    passwordInputContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 45,
        alignItems: 'center',
        backgroundColor: Colors.light_gray_2,
        borderRadius: 30,
        // position:"absolute",
        overflow: 'visible',
        //zIndex: 11000,
    },
    textInput: {
        flex: 1,
        fontFamily: 'eina01',
        zIndex: 12,
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        outline: 'none',
    },
    leftIcon: {
        left: '2%',
        marginHorizontal: '2%',
        zIndex: 12,
    },
    rightIcon: {
        right: '5%',
        marginHorizontal: '2%',
    },
    multiline: {
        minHeight: 45,
        // paddingTop: 10,
        // @ts-ignore
        height: null,
        // alignItems: 'flex-start',
    },

    suggestionWrapper: {
        position: 'absolute',
        width: '100%',
        // height: 200,
        backgroundColor: Colors.light_gray_2,
        top: 23,
        paddingTop: 23,
        paddingBottom: 5,
        zIndex: 10,
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
    },

    suggestionItem: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderTopWidth: 1,
        borderColor: Colors.white,
    },
});
