import { registerRootComponent } from 'expo';
import 'react-native-gesture-handler';

import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Platform } from 'react-native';
import App from './App';
import { AuthContextProvider } from './context/authContext';

if (Platform.OS === 'web') import('../assets/scripts/lottiePlayer');

// Once SDK is initialized you need to setup view tracking to be able to see data in the RUM Dashboard.

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

const Application = () => {
    return (
        <AuthContextProvider>
            <ActionSheetProvider>
                <App />
            </ActionSheetProvider>
        </AuthContextProvider>
    );
};
registerRootComponent(Application);
