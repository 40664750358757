import moment from 'moment/moment';

const formatDateTime = (timestamp: number) => {
    const now = Date.now();

    if (now - timestamp * 1000 > 24 * 3600000) {
        return moment(timestamp * 1000).format('l LT');
    } else {
        return moment(timestamp * 1000).fromNow();
    }
};

export const DateTimeUtils = {
    formatDateTime,
};
