import { Feather } from '@expo/vector-icons';
import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { DebugWidget } from '../DebugWidget/DebugWidget';

export interface AddPostButtonProps {
    navigation: any;
}
export const AddPostButton = ({ navigation }: AddPostButtonProps) => {
    const auth = useAuth();
    const modal = useModal();

    const onPress = () => {
        if (auth.isLoggedIn) {
            navigation.navigate('PostCreation', { dish: null });
        } else {
            navigation.navigate('SignIn');
        }
    };

    const onLongPress = () => {
        modal.open('none', <DebugWidget />);
    };
    return (
        <TouchableOpacity style={styles.wrapper} onPress={onPress} onLongPress={onLongPress}>
            <View style={styles.container}>
                <Feather name={'plus'} size={30} color={Colors.white} />
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: 40,
    },
    container: {
        backgroundColor: Colors.primary,
        height: 40,
        width: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 7,
        color: Colors.white,
    },
});
