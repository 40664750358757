import { Image, Text, TouchableOpacity, View } from 'react-native';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { Card, CardProps } from '../Card/Card';
import { CommonStyles } from '../Styles/CommonStyles';
import { styles } from './styles';

export interface FoodPrintCardProps extends CardProps {
    name: string;
    time: string;
    avatar: string;
    eventTxt: string;
    highlightText?: string;
    useIndentation?: boolean;
    skeleton?: boolean;
    onCardPressed?: () => void;
    onAvatarPressed?: () => void;
}

export default function FoodPrintCard({ useIndentation, skeleton, ...props }: FoodPrintCardProps) {
    if (!skeleton)
        return (
            <View style={{ marginLeft: 10, marginRight: 10 }}>
                <Card onPress={props.onCardPressed}>
                    <View style={styles.foodPrintCardWrapper}>
                        <TouchableOpacity
                            style={styles.avatarWrapper}
                            onPress={props.onAvatarPressed}
                        >
                            <Image style={styles.avatar} source={{ uri: props?.avatar }} />
                        </TouchableOpacity>
                        <View style={styles.contentWrapper}>
                            <View style={styles.header}>
                                <Text style={{ lineHeight: 20 }}>
                                    <Text style={styles.nameText}>{props.name}</Text>{' '}
                                    <Text style={styles.eventText}>{props.eventTxt}</Text>
                                </Text>
                                <View style={[CommonStyles.flexRowCenter, { marginTop: 3 }]}>
                                    <Text style={styles.timeText}>{props.time}</Text>
                                    {props.highlightText && (
                                        <Text style={styles.highlightText}>
                                            {' '}
                                            • {props.highlightText}
                                        </Text>
                                    )}
                                </View>
                            </View>
                            {useIndentation && props.children}
                        </View>
                    </View>
                    {!useIndentation && <View>{props?.children}</View>}
                </Card>
            </View>
        );
    else
        return (
            <View style={{ marginLeft: 10, marginRight: 10 }}>
                <Card>
                    <SkeletonPlaceholder>
                        <View style={styles.foodPrintCardWrapper}>
                            <View style={styles.avatarWrapper}>
                                <Image style={styles.avatar} source={{ uri: props?.avatar }} />
                            </View>
                            <View style={styles.contentWrapper}>
                                <View style={styles.header}>
                                    <SkeletonPlaceholder.Item
                                        height={15}
                                        borderRadius={5}
                                        margin={5}
                                    />
                                    <SkeletonPlaceholder.Item
                                        height={15}
                                        width={100}
                                        borderRadius={5}
                                        margin={5}
                                    />
                                    <SkeletonPlaceholder.Item
                                        height={160}
                                        borderRadius={5}
                                        margin={5}
                                    />
                                </View>
                            </View>
                        </View>
                    </SkeletonPlaceholder>
                </Card>
            </View>
        );
}
