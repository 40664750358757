import { Platform, StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

const androidHandler =
    Platform.OS === 'android'
        ? {
              borderColor: Colors.light_gray,
              borderTopWidth: 1,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
          }
        : {};

export const BottomSheetStyles = StyleSheet.create({
    style: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    handleStyle: {
        backgroundColor: Colors.light_gray_2,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        ...androidHandler,
    },
    contentStyle: {
        flex: 1,
        backgroundColor: Colors.light_gray_2,
    },

    actionItem: {
        paddingVertical: 15,
        borderBottomWidth: 1,
        borderColor: Colors.light_gray,
        width: '100%',
    },
    actionItemText: {
        fontSize: 18,
        textAlign: 'center',
    },
});
