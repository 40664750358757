import * as ImagePicker from 'expo-image-picker';
import React, { ReactNode } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import CustomInputText, { InputTextProps } from './CustomInputText';

export interface InputRowProps extends InputTextProps {
    type?: 'input' | 'image' | 'custom';
    title?: string;
    helperText?: string;
    children?: ReactNode;
}
export const InputRow = (props: InputRowProps) => {
    const IMAGE_PLACEHOLDER = 'https://webapp.gourmetcarte.com/images%2Fadd_placeholder.png';
    const {
        title,
        helperText,
        isPassword,
        placeholder,
        multiline,
        onChangeText,
        leftIcon,
        initValue,
        type,
        textContentType,
        keyboardType,
    } = props;

    const selectImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
        });

        if (!result.canceled) {
            onChangeText?.(result.assets[0].uri);
        }
    };

    return (
        <>
            {type === 'input' && (
                <View style={styles.inputGroup}>
                    <Text style={[styles.inputTitle]}>{title}</Text>
                    <View style={[]}>
                        <CustomInputText
                            {...props}
                            isPassword={isPassword || false}
                            leftIcon={leftIcon}
                            placeholder={placeholder}
                            initValue={initValue}
                            multiline={multiline}
                            onChangeText={onChangeText}
                            textContentType={textContentType}
                            keyboardType={keyboardType}
                            height={multiline ? 150 : undefined}
                        />
                    </View>
                    {helperText && <Text style={[styles.helperText]}>{helperText}</Text>}
                </View>
            )}
            {type === 'custom' && (
                <View style={styles.inputGroup}>
                    <Text style={[styles.inputTitle]}>{title}</Text>
                    <View style={[]}>{props.children}</View>
                    {helperText && <Text style={[styles.helperText]}>{helperText}</Text>}
                </View>
            )}
            {type === 'image' && (
                <View style={[styles.inputGroup, styles.imageRow]}>
                    <TouchableOpacity onPress={selectImage}>
                        <Image
                            source={{ uri: initValue || IMAGE_PLACEHOLDER }}
                            style={styles.imageWrapper}
                        />
                    </TouchableOpacity>

                    <Text style={[styles.inputTitle, styles.imageTitle]}>{title}</Text>
                </View>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    // Edit Input Section
    inputGroup: {
        marginVertical: 10,
    },
    inputTitle: {
        marginBottom: 5,
    },
    helperText: {
        color: Colors.dark_gray,
    },

    imageRow: {
        alignItems: 'center',
    },
    imageTitle: {
        marginTop: 5,
        textAlign: 'center',
    },
    imageWrapper: {
        height: 100,
        width: 100,
        borderRadius: 100,
    },
});

InputRow.defaultProps = {
    type: 'input',
    isPassword: false,
};
