import { ReactNode } from 'react';
import { PressableProps, TouchableOpacity, View } from 'react-native';
import { styles } from './styles';

export interface CardProps extends PressableProps {
    children?: ReactNode;
    showShadow?: boolean;
}
export const Card = ({ showShadow, onPress, ...props }: CardProps) => {
    if (onPress)
        return (
            <TouchableOpacity
                activeOpacity={0.7}
                onPress={onPress}
                style={[styles.cardWrapper, showShadow && styles.boxShadow]}
            >
                <View>{props.children}</View>
            </TouchableOpacity>
        );
    else
        return (
            <View style={[styles.cardWrapper, showShadow && styles.boxShadow]}>
                {props.children}
            </View>
        );
};
