import AsyncStorage from '@react-native-async-storage/async-storage';

import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const MAX_HISTORY = 10;

const storage = createJSONStorage<string[]>(() => AsyncStorage);
const searchHistoryAtom = atomWithStorage<string[]>('searchHistoryAtom', [], storage);

export const useSearchHistory = () => {
    const [searchHistoryData, setSearchHistoryData] = useAtom(searchHistoryAtom);

    const addHistory = (keyword: string) => {
        setSearchHistoryData(async (promiseOrValue) => {
            let newHistoryData = [...(await promiseOrValue)];
            const index = newHistoryData.indexOf(keyword);
            if (index != -1) {
                newHistoryData = [
                    keyword,
                    ...newHistoryData.slice(0, index),
                    ...newHistoryData.slice(index + 1, newHistoryData.length),
                ];
            } else {
                newHistoryData = [keyword, ...newHistoryData].slice(0, MAX_HISTORY);
            }
            return newHistoryData;
        });
    };

    const clearHistory = () => {
        setSearchHistoryData([]);
    };

    return {
        searchHistoryData,
        addHistory,
        clearHistory,
    };
};
