import { ReactNode } from 'react';
import { GestureResponderEvent, Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';
import { styles } from './styles';

export interface FilterButtonProps {
    active?: boolean;
    grouped?: boolean;
    onPress?: (event: GestureResponderEvent) => void;
    style?: StyleProp<ViewStyle>;
    children: ReactNode;
}

export const FilterButton = ({ active, grouped, children, style, onPress }: FilterButtonProps) => {
    const wrapperStyles = [
        styles.buttonWrapper,
        active ? styles.activeButtonWrapper : {},
        grouped ? styles.groupedButtonWrapper : {},
    ];
    return (
        <Pressable
            style={({ pressed }) => [wrapperStyles, style, { opacity: pressed ? 0.5 : 1 }]}
            onPress={onPress}
        >
            <Text style={[styles.text, active ? styles.activeText : {}]}>{children}</Text>
        </Pressable>
    );
};

export interface ButtonGroupProps {
    children: ReactNode;
}
export const ButtonGroup = ({ children }: ButtonGroupProps) => {
    return <View style={styles.buttonGroup}>{children}</View>;
};
