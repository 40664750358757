import { Entypo, Feather, Fontisto } from '@expo/vector-icons';
import BottomSheeta,{
    BottomSheetBackdrop,
    useBottomSheetSpringConfigs,
} from '@gorhom/bottom-sheet';
import { BottomSheet } from '../../webLibs/BottomSheet/BottomSheet';
import { PostFilterParam } from 'gc-common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DeviceEventEmitter, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { ButtonGroup, FilterButton } from '../../components/FilterButton/FilterButton';
import { Ratings } from '../../components/Ratings/Ratings';
import { SelectCard } from '../../components/SelectCard/SelectCard';
import { BottomSheetStyles } from '../../components/Styles/BottomSheetStyles';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { useFilterData } from '../../hooks/useFilterData';
import { BottomSheetScrollView } from '../../webLibs/BottomSheetScrollView/BottomSheetScrollView';
export const useFilterSection = (
    type: 'restaurant' | 'dish',
    setCurrentView: (i: number) => void,
    onFilterApplied?: (f: PostFilterParam) => void
) => {
    const bottomSheetRef = useRef<any>(null);
    const sortingBoxRef = useRef<any>(null);
    const auth = useAuth();

    const [isSortSheetVisible, setIsSortSheetVisible] = useState(false);
    const [sort, setSort] = useState(
        auth.isLoggedIn && type === 'dish' ? 'recommendation' : 'distance'
    );
    const priceFilter = useFilterData<string>(['$', '$$', '$$$', '$$$$']);
    const ratingFilter = useFilterData<number>([4, 3, 2]);
    const cuisineFilter = useFilterData<string>([
        'American',
        'Mexican',
        'Vietnamese',
        'Korean',
        'Japanese',
        'Chinese',
        'French',
        'India',
        'Cajun',
    ]);
    const typeFilter = useFilterData<string>([
        'Breakfast',
        'Sandwiches',
        'Pizza',
        'Steakhouses',
        'Burgers',
        'Seafood',
    ]);

    const isFiltering = useMemo(() => {
        return (
            priceFilter.isFiltering ||
            typeFilter.isFiltering ||
            cuisineFilter.isFiltering ||
            ratingFilter.isFiltering
        );
    }, [priceFilter, typeFilter, cuisineFilter, ratingFilter]);

    useEffect(() => {
        if (!auth.isLoggedIn && sort === 'recommendation') setSort('distance');
        else if (auth.isLoggedIn && type === 'dish') setSort('recommendation');
    }, [auth.isLoggedIn]);

    const clearFilters = () => {
        priceFilter.reset();
        typeFilter.reset();
        cuisineFilter.reset();
        ratingFilter.reset();
        // bottomSheetRef.current?.close();
    };

    const animationConfigs = useBottomSheetSpringConfigs({
        damping: 8000,
        overshootClamping: true,
        restDisplacementThreshold: 0.1,
        restSpeedThreshold: 0.1,
        stiffness: 800,
    });

    // const animationConfigs = useBottomSheetTimingConfigs({
    //     duration: 250,
    //     // easing: Easing.linear,
    // });

    const switchView = (i: number) => {
        setCurrentView(i);
        DeviceEventEmitter.emit('home-switch-view', i);
    };

    const render = () => {
        return (
            <View style={styles.wrapper}>
                <ButtonGroup>
                    <FilterButton
                        grouped={true}
                        active={type === 'dish'}
                        onPress={() => switchView(0)}
                    >
                        <Feather name={'coffee'} size={15} />
                    </FilterButton>
                    <FilterButton
                        grouped={true}
                        active={type === 'restaurant'}
                        onPress={() => switchView(1)}
                    >
                        <Feather name={'home'} size={15} />
                    </FilterButton>
                </ButtonGroup>
                <View style={{ flex: 1 }} />
                {Env.isNative && type === 'dish' && (
                    <FilterButton
                        style={styles.button}
                        onPress={() => {

                            bottomSheetRef?.current?.snapToIndex(0);
                        }}
                        active={isFiltering}
                    >
                        <Feather name={'filter'} size={15} />
                    </FilterButton>
                )}
                {type === 'dish' && (
                    <FilterButton
                        style={styles.button}
                        onPress={() => {
                            setIsSortSheetVisible(true);
                            sortingBoxRef?.current?.expand()
                        }}
                    >
                        Sort by {sort} <Feather name={'chevron-down'} size={15} />
                    </FilterButton>
                )}
            </View>
        );
    };

    const renderSortingBox = () => {
        return (
            <BottomSheet
                isVisible={isSortSheetVisible}
                ref={sortingBoxRef}
                index={-1}
                snapPoints={[200]}
                enablePanDownToClose
                //style={BottomSheetStyles.style}
                handleStyle={BottomSheetStyles.handleStyle}
                backdropComponent={(props) => (
                    <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />
                )}
                onBackdropPress={() => {setIsSortSheetVisible(false);}}
            >
                <View
                    style={[
                        BottomSheetStyles.contentStyle,
                        {
                            alignItems: 'center',
                        },
                    ]}
                >
                    {auth.isLoggedIn && type === 'dish' && (
                        <TouchableOpacity
                            style={BottomSheetStyles.actionItem}
                            onPress={() => {
                                setSort('recommendation');
                                sortingBoxRef?.current?.close();
                            }}
                        >
                            <Text style={BottomSheetStyles.actionItemText}>
                                {sort === 'recommendation' && <Feather name={'check'} size={18} />}
                                Sort by recommendation
                            </Text>
                        </TouchableOpacity>
                    )}

                    <TouchableOpacity
                        style={BottomSheetStyles.actionItem}
                        onPress={() => {
                            setSort('distance');
                            sortingBoxRef?.current?.close();
                        }}
                    >
                        <Text style={BottomSheetStyles.actionItemText}>
                            {sort === 'distance' && <Feather name={'check'} size={18} />}
                            Sort by distance
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={BottomSheetStyles.actionItem}
                        onPress={() => {
                            setSort('rating');
                            sortingBoxRef?.current?.close();
                        }}
                    >
                        <Text style={BottomSheetStyles.actionItemText}>
                            {sort === 'rating' && <Feather name={'check'} size={18} />}
                            Sort by rating
                        </Text>
                    </TouchableOpacity>
                </View>
            </BottomSheet>
        );
    };

    const getFilterParams = (): PostFilterParam => {
        const prices = priceFilter.selections
            .filter((i) => i.isSelected)
            .map((i) => i.key.toString().length);
        const rating = ratingFilter.selections.filter((i) => i.isSelected).map((i) => i.key);
        const types = typeFilter.selections.filter((i) => i.isSelected).map((i) => i.key);
        const cuisines = cuisineFilter.selections.filter((i) => i.isSelected).map((i) => i.key);

        const filters: PostFilterParam = {
            prices,
            types,
            cuisines,
        };

        if (rating.length > 0) filters.rating = rating[0];
        return filters;
    };

    const renderBottomSheet = () => {
        return (
            <BottomSheet

                ref={bottomSheetRef}
                index={-1}
                snapPoints={[200, '90%']}
                enablePanDownToClose
                animationConfigs={animationConfigs}
                backdropComponent={(props) => (
                    <BottomSheetBackdrop {...props} appearsOnIndex={1} disappearsOnIndex={-1} />
                )}
                onChange={(i) => i === -1 && onFilterApplied?.(getFilterParams())}
            >
                <BottomSheetScrollView>
                    <View style={CommonStyles.section}>
                        {isFiltering && (
                            <View style={{ marginBottom: 10 }}>
                                <GCButton
                                    text={'Clear all filters'}
                                    type={'secondary'}
                                    onClick={clearFilters}
                                />
                            </View>
                        )}
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'credit'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Price</Text>
                            <TouchableOpacity
                                onPress={() => priceFilter.reset()}
                                style={CommonStyles.flexRowCenter}
                            >
                                <Entypo name={'cross'} size={18} />
                                <Text>Reset</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterItemContainer}>
                            {priceFilter.selections.map((i) => {
                                return (
                                    <SelectCard
                                        key={i.key}
                                        text={'' + i.key}
                                        width={70}
                                        selected={i.isSelected}
                                        onChange={(s) => priceFilter.set(i.key, s)}
                                    />
                                );
                            })}
                        </View>
                    </View>
                    <View style={CommonStyles.section}>
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'star'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Rating</Text>
                            <TouchableOpacity
                                onPress={() => ratingFilter.reset()}
                                style={CommonStyles.flexRowCenter}
                            >
                                <Entypo name={'cross'} size={18} />
                                <Text>Reset</Text>
                            </TouchableOpacity>
                        </View>
                        {ratingFilter.selections.map((i) => {
                            return (
                                <TouchableOpacity
                                    style={styles.ratingItem}
                                    key={i.key}
                                    onPress={() => ratingFilter.singleSelect(i.key)}
                                >
                                    <Ratings rating={i.key as number} large />
                                    <Text style={styles.ratingText}>& up</Text>
                                    <View style={{ flex: 1 }}></View>
                                    {!i.isSelected && (
                                        <Fontisto name={'radio-btn-passive'} size={18} />
                                    )}
                                    {i.isSelected && (
                                        <Fontisto name={'radio-btn-active'} size={18} />
                                    )}
                                </TouchableOpacity>
                            );
                        })}
                    </View>
                    <View style={CommonStyles.section}>
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'bowl'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Cuisine</Text>
                            <TouchableOpacity
                                onPress={() => cuisineFilter.reset()}
                                style={CommonStyles.flexRowCenter}
                            >
                                <Entypo name={'cross'} size={18} />
                                <Text>Reset</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterItemContainer}>
                            {cuisineFilter.selections.map((i) => {
                                return (
                                    <SelectCard
                                        key={i.key}
                                        text={'' + i.key}
                                        selected={i.isSelected}
                                        onChange={(s) => cuisineFilter.set(i.key, s)}
                                    />
                                );
                            })}
                        </View>
                    </View>
                    <View style={CommonStyles.section}>
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'grid'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Type</Text>
                            <TouchableOpacity
                                onPress={() => typeFilter.reset()}
                                style={CommonStyles.flexRowCenter}
                            >
                                <Entypo name={'cross'} size={18} />
                                <Text>Reset</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.filterItemContainer}>
                            {typeFilter.selections.map((i) => {
                                return (
                                    <SelectCard
                                        key={i.key}
                                        text={'' + i.key}
                                        selected={i.isSelected}
                                        onChange={(s) => typeFilter.set(i.key, s)}
                                    />
                                );
                            })}
                        </View>
                    </View>
                </BottomSheetScrollView>
            </BottomSheet>
        );
    };

    return {
        render,
        renderBottomSheet,
        renderSortingBox,
        sort,
        getFilterParams,
        isFiltering,
    };
};

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 10,
        backgroundColor: Colors.white,
    },
    button: {
        marginLeft: 10,
    },
    filterItemContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    filterItem: {
        width: '33.33%',
    },
    ratingText: {
        fontSize: 17,
    },
    ratingItem: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: 5,
    },
});
