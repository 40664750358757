import { LinkingUtil } from '../../navigation/LinkingConfiguration';
import { RootStackParamList, RootStackScreenProps } from '../../types';

export const RedirectPage = ({ route, navigation }: RootStackScreenProps<'RedirectPage'>) => {
    const targetPath = route.params.path as keyof RootStackParamList;
    const targetData = route.params.data as any;

    const page = LinkingUtil.getPageName(targetPath);
    // @ts-ignore
    navigation.navigate(page, JSON.parse(targetData || '{}'));

    return <></>;
};
