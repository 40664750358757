import { ActionSheetOptions, useActionSheet } from '@expo/react-native-action-sheet';
import { Entypo } from '@expo/vector-icons';
import React, { ReactNode } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Colors } from '../../constants/Colors';

export interface ActionSheetProps {
    options: string[];
    destructiveButtonIndex?: number;
    cancelButtonIndex?: number;
    onSelection: (index: number | undefined) => void;
    title?: string;
    message?: string;
    children?: ReactNode;
}

export const ActionSheet: React.FC<ActionSheetProps> = (props) => {
    const { options, destructiveButtonIndex, cancelButtonIndex, onSelection, title, message } =
        props;

    const { showActionSheetWithOptions } = useActionSheet();

    const handlePress = () => {
        const actionSheetOptions: ActionSheetOptions = {
            options,
            destructiveButtonIndex,
            cancelButtonIndex,
            title,
            message,
        };
        showActionSheetWithOptions(actionSheetOptions, (buttonIndex) => {
            onSelection(buttonIndex);
        });
    };

    return (
        <View>
            <TouchableOpacity onPress={handlePress}>
                <Entypo name="dots-three-horizontal" size={24} color={Colors.dark_gray} />
            </TouchableOpacity>
        </View>
    );
};
