import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { Keyboard, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import CustomInputText from '../../components/CustomInputText/CustomInputText';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { authService } from '../../services/auth/authService';
import { RootStackScreenProps, RootTabParamList } from '../../types';
import { styles } from './styles';
//todo: add apis

export default function PasswordScreen({ route }: RootStackScreenProps<'PasswordReset'>) {
    const navigation = useNavigation<NativeStackNavigationProp<RootTabParamList>>();
    const modal = useModal();
    const auth = useAuth();
    const token = route.params?.token;
    const [signUpValues, setSignUpValues] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        token: '',
    });
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const updateWarnMessage = (text: string) => {
        setMessage(text);
        modal.open('warning', text);
    };
    const handleChange = (props: any, value: any) => {
        setSignUpValues({ ...signUpValues, [props]: value.trim() });
    };

    const validateEmail = () => {
        let emailRegex = /^[a-zA-Z0-9\.\+_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        let email = signUpValues.email.toString();
        if (email.length === 0) {
            updateWarnMessage('Email is required');
            setAlert(true);
            return false;
        } else if (!emailRegex.test(email)) {
            updateWarnMessage('Invalid email');
            setAlert(true);
            return false;
        } else {
            updateWarnMessage('');
            setAlert(false);
            return true;
        }
    };
    const validatePassword = () => {
        const passwordText = signUpValues.password;
        let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        let password = passwordText.toString();

        if (password.length < 6) {
            updateWarnMessage('Password must be at least 6 characters');
            setAlert(true);
            return false;
        } else if (password !== signUpValues.confirmPassword) {
            updateWarnMessage('Password does not match');
            setAlert(true);
            return false;
        } else {
            updateWarnMessage('');
            setAlert(false);
            return true;
        }
    };

    const handleBack = () => {
        navigation.goBack();
    };

    const passwordResetRequest = async () => {
        if (validateEmail()) {
            modal.open('loader');
            const rsp = await authService.passwordResetRequest(signUpValues.email);
            if (rsp.errorMessage) {
                updateWarnMessage(rsp.errorMessage);
                return;
            }

            const successView = (
                <View style={CommonStyles.flexColumnCenter}>
                    <Text style={{ textAlign: 'center', width: 230, marginVertical: 15 }}>
                        We have sent you an Email to reset your password.
                    </Text>
                    <Text style={{ textAlign: 'center', width: 230, marginVertical: 15 }}>
                        Please check your inbox or spam.
                    </Text>
                </View>
            );
            modal.open('success', successView);
        }
    };

    const handleSubmitPassword = async () => {
        Keyboard.dismiss();

        if (validatePassword() && token) {
            modal.open('loader');
            const result = await authService.resetPassword(signUpValues.password, token);
            modal.close();
            if (!result.errorMessage) {
                modal.open('success', undefined, () => {
                    navigation.navigate('Root' as any);
                });
            } else {
                modal.open('fail', result.errorMessage);
            }
        } else {
            // modal.open('warning', message);
        }
    };

    return (
        <ScrollView style={styles.pageWrapper}>
            <View style={styles.screenContainer}>
                <View style={styles.topBarContainer}>
                    <TouchableOpacity onPress={handleBack}>
                        <AntDesign name="arrowleft" size={24} />
                    </TouchableOpacity>
                </View>

                {!token && (
                    <>
                        <View>
                            <Text style={styles.title}>Change your password</Text>
                            <Text style={styles.subTitle}>Please enter your email</Text>
                        </View>
                        <View style={styles.inputContainer}>
                            <Text style={styles.inputTitle}>Email</Text>
                            <View style={styles.inputView}>
                                <CustomInputText
                                    isPassword={false}
                                    leftIcon={'user'}
                                    placeholder={'example@email.com'}
                                    onChangeText={(text) => handleChange('email', text)}
                                    suggestions={[]}
                                />
                            </View>
                        </View>

                        {message && (
                            <Text style={{ marginBottom: 15, color: 'red' }}>* {message}</Text>
                        )}

                        <View style={{ width: '100%', zIndex: 0 }}>
                            <GCButton
                                size={'large'}
                                text={'Request Password Reset'}
                                onClick={passwordResetRequest}
                            />
                        </View>
                    </>
                )}
                {token && (
                    <>
                        <View>
                            <Text style={styles.title}>Change your password</Text>
                            <Text style={styles.subTitle}>Please enter the new password</Text>
                        </View>
                        <View style={styles.inputContainer}>
                            <Text style={styles.inputTitle}>Password</Text>
                            <View style={[styles.inputView, { marginBottom: 10 }]}>
                                <CustomInputText
                                    isPassword={true}
                                    leftIcon={'lock'}
                                    placeholder={'Enter your password'}
                                    onChangeText={(text) => handleChange('password', text)}
                                />
                            </View>
                            <Text
                                style={{
                                    fontFamily: 'eina01',
                                    fontSize: 12,
                                    marginBottom: 20,
                                    lineHeight: 15,
                                }}
                            >
                                Your password must at least be 8 characters & contain a mix of upper
                                & lower case letters, numbers & symbols.
                            </Text>
                            <Text style={styles.inputTitle}>Confirm Password</Text>
                            <View style={styles.inputView}>
                                <CustomInputText
                                    isPassword={true}
                                    leftIcon={'lock'}
                                    placeholder={'Enter your password again'}
                                    onChangeText={(text) => handleChange('confirmPassword', text)}
                                />
                            </View>
                        </View>

                        {message && (
                            <Text style={{ marginBottom: 15, color: 'red' }}>* {message}</Text>
                        )}
                        <View style={{ width: '100%' }}>
                            <GCButton
                                size={'large'}
                                text={'Submit'}
                                onClick={handleSubmitPassword}
                            />
                        </View>
                    </>
                )}
            </View>
            {modal.render()}
        </ScrollView>
    );
}
