import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    wrapper: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        paddingHorizontal: 18,
        paddingTop: 12,
        paddingBottom: 14,
        marginBottom: 20,
    },

    button: {
        backgroundColor: Colors.primary,
        color: Colors.white,
        borderRadius: 50,
        paddingVertical: 13,
        display: 'flex',
        alignItems: 'center',
    },

    text: {
        color: Colors.white,
        fontSize: 14,
    },
});
