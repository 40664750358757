import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    titleContainer: {
        padding: 15,
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 3,
        backgroundColor: Colors.white,
        paddingBottom: 20,
    },
    titleRow: {
        flexDirection: 'row',
        marginTop: 15,
    },
    storeDetailWrapper: {
        flexDirection: 'row',
    },
    storeDetailRow: {
        flexDirection: 'column',
        flex: 1,
    },
    storeActionsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    storeActionButton: {
        backgroundColor: Colors.primary,
        margin: 5,
        height: 35,
        width: 35,
        borderRadius: 35,
        alignItems: 'center',
        justifyContent: 'center',
    },
    storeActionButtonIcon: {
        fontSize: 20,
        color: Colors.white,
    },
    title: {
        fontSize: 20,
        fontFamily: 'eina01-bold',
        flex: 1,
    },
    price: {
        color: Colors.primary,
        fontSize: 20,
    },
    description: {
        color: Colors.dark_gray,
        lineHeight: 18,
    },

    extraActionContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    extraButtonWrapper: {
        flex: 1,
    },
    extraButton: {
        borderRadius: 5,
        borderColor: Colors.gray,
        borderWidth: 1,
        paddingVertical: 3,
        paddingHorizontal: 5,
        color: Colors.gray,
        marginHorizontal: 5,
        // textAlignVertical: "center",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    extraActivationButton: {
        borderColor: Colors.primary,
        color: Colors.primary,
    },

    ///////////////////     Detail Container
    detailsContainer: {
        paddingHorizontal: 15,
        paddingTop: 30,
    },
    sectionTitleContainer: {
        flexDirection: 'row',
        alignItems: 'baseline',
        marginVertical: 15,
    },
    sectionTitle: {
        paddingHorizontal: 10,
        fontSize: 18,
        fontFamily: 'eina01-bold',
    },
    sectionRightTitle: {
        paddingHorizontal: 10,
    },
    sectionTitleHint: {
        fontSize: 10,
        color: 'gray',
    },
    sectionTitleFiller: {
        flex: 1,
    },
    cardWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cardImage: {
        height: 60,
        width: 60,
        borderRadius: 8,
        overflow: 'hidden',
        marginRight: 10,
    },
    cardInfoWrapper: {
        flex: 1,
    },
    storeName: {
        fontSize: 15,
        fontFamily: 'eina01-bold',
        marginVertical: 5,
        color: Colors.primary,
        flex: 1,
    },
    storeAddr: {
        fontSize: 14,
        color: 'gray',
        lineHeight: 17,
    },

    postImageWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    postImage: {
        width: '30%',
        height: 60,
        marginRight: 5,
        marginBottom: 5,
    },
    postContent: {
        marginBottom: 10,
    },
});
