import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { User, UserService } from 'gc-common';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, DeviceEventEmitter, FlatListProps, Image, Text, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { CollapsibleTabView, TabRoute } from '../../components/TabViewFlatList/CollapsibleTabView';
import { useAuth } from '../../context/authContext';
import { DEFAULT_USER_AVATAR } from '../../types/Constants';
import { styles as CStyle } from './styles';
import { useActions } from './useActions';
import { useFoodPrint } from './useFoodPrint';
import { useHeader } from './useHeader';
import { useNotify } from './useNotify';
import { usePosts } from './usePosts';
import { useStats } from './useStats';
import AnimatedProps = Animated.AnimatedProps;

export const MePage = ({ route: NRoute }: any) => {
    const navigation = useNavigation();

    const userId = NRoute.params?.id; // This is always other user's id, not ME.
    const [user, setUser] = useState<User | undefined>(); // This can be either ME or other user.
    const [refreshing, setRefreshing] = useState(false);

    const auth = useAuth();

    const isSelf = useMemo(() => {
        return !userId || userId == auth.user?.user_id;
    }, [userId]);

    const header = useHeader(user, isSelf, () => refreshPage());
    const notify = useNotify();
    const stats = useStats(user);
    const followActions = useActions(userId ? user : undefined); // Only fetch following stats for other users.
    const posts = usePosts(user);
    const foodPrint = useFoodPrint(user, isSelf);
    const scrollY = useRef(new Animated.Value(0));

    useEffect(() => {
        console.log(userId);
        getMeInfo();
        //posts.fetchData(1);

        DeviceEventEmitter.addListener('refresh-profile', async () => {
            setRefreshing(true);
            await getMeInfo();
            setRefreshing(false);
        });

        return () => {
            DeviceEventEmitter.removeAllListeners('refresh-profile');
        };
    }, []);

    useFocusEffect(
        useCallback(() => {
            notify.getUnreadCount();
        }, [])
    );

    const getMeInfo = async () => {
        notify.getUnreadCount();
        const info = userId ? await UserService.getUserInfo(userId) : await UserService.getMeInfo();
        setUser(info.data);
        console.log(info.data);
    };

    const [r, setR] = useState<TabRoute[]>([
        { key: 'foodPrint', title: 'Foodprint' },
        { key: 'posts', title: 'My Posts' },
    ]);

    const refreshPage = async () => {
        setRefreshing(true);
        await Promise.all([
            stats.fetchData(user?.user_id),
            posts.refresh(),
            foodPrint.refresh(),
            notify.getUnreadCount(),
        ]);
        setRefreshing(false);
    };

    const listProsProvider = (key: string): AnimatedProps<FlatListProps<any>> | undefined => {
        if (key == 'posts') {
            return {
                data: posts.posts,
                numColumns: 3,
                renderItem: posts.renderListItem,
                onEndReached: () => {
                    posts.fetchData(posts.page + 1);
                },
                refreshing: refreshing,
                onRefresh: refreshPage,
            };
        }

        if (key == 'foodPrint') {
            return {
                data: foodPrint.data.filter((i) => !auth.isUserBlocked(i.details?.user_id)),
                renderItem: foodPrint.renderListItem,
                overScrollMode: 'always',
                onEndReachedThreshold: 0.5,
                onEndReached: () => {
                    foodPrint.fetchData(foodPrint.page + 1);
                },
                refreshing: refreshing,
                onRefresh: refreshPage,
            };
        }
    };

    const renderHeader = () => {
        return (
            <View>
                {header.render(scrollY.current)}
                <View style={CStyle.whiteContainer}>
                    <Image
                        style={[CStyle.userAvatar, CStyle.absAvatar]}
                        source={{ uri: user?.profile_photo || DEFAULT_USER_AVATAR }}
                    />

                    {stats.render()}

                    <Text style={CStyle.description} selectable={true}>
                        {user?.bio}
                    </Text>
                    {auth.isLoggedIn && auth.user?.user_id != userId && followActions.render()}

                    {!userId && auth.isLoggedIn && (
                        <>
                            <View style={CStyle.actionButtonWrapper}>
                                <GCButton
                                    text={'Edit Profile'}
                                    type={'secondary'}
                                    onClick={() => navigation.navigate('EditProfile' as any)}
                                />
                            </View>
                        </>
                    )}
                </View>
            </View>
        );
    };

    return (
        <CollapsibleTabView
            routes={r}
            // header={<View style={{height:100}}><Text>asdf</Text></View>}
            header={renderHeader()}
            listProsProvider={listProsProvider}
            scrollY={(y) => {
                scrollY.current = y;
            }}
        />
    );
};
