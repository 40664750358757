import { ajax } from 'gc-common';
import { Env } from '../Env';
import { Auth, useAuthHook } from '../services/auth/auth';
import { Logger } from './logger';

export const setupAjax = (auth: ReturnType<typeof useAuthHook>) => {
    ajax.API_URL = Env.API_URL;
    ajax.getToken = async () => {
        const { token } = await Auth.getToken();
        return token;
    };
    ajax.axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            Logger.error(
                `[AJAX ERROR] [${error.config?.method.toUpperCase()}] [${error.response?.status}] ${
                    error.config?.url
                } | ${error.message}`
            );
            if (error.response?.status === 401) {
                auth.logout();
            }
            return Promise.reject(error);
        }
    );
};
