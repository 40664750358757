import AsyncStorage from '@react-native-async-storage/async-storage';
import { AxiosError } from 'axios';
import * as SecureStore from 'expo-secure-store';
import { ajax, GCJSONResponse, GeneralResponse } from 'gc-common';
import { Platform } from 'react-native';
import { Logger } from '../../utils/logger';

export interface JWTResponse {
    access_token: string;
    token_type: string;
    expire: number;
    expire_type: string;
}

export interface LoginResult {
    success: boolean;
    errorMessage?: string;
}

const loginProcess = async (
    data: GeneralResponse<JWTResponse>,
    username: string,
    password: string
): Promise<LoginResult> => {
    if (data.errorMessage) {
        return {
            success: false,
            errorMessage: data.errorMessage,
        };
    }

    if (Platform.OS === 'web') {
        AsyncStorage.setItem('auth_access_token', data.data.access_token);
        AsyncStorage.setItem('auth_expire', '' + data.data.expire);
        AsyncStorage.setItem('auth_username', username);
        AsyncStorage.setItem('auth_password', password);
        console.log('Login Token: ' + data.data.access_token);
        return {
            success: true,
        };
    }

    await Promise.all([
        SecureStore.setItemAsync('auth_access_token', data.data.access_token),
        SecureStore.setItemAsync('auth_expire', '' + data.data.expire),
        SecureStore.setItemAsync('auth_username', username),
        SecureStore.setItemAsync('auth_password', password),
    ]);

    console.log('Login Token: ' + data.data.access_token);
    return {
        success: true,
    };
};

const login = async (username: string, password: string): Promise<LoginResult> => {
    try {
        const rsp = await ajax.post<GeneralResponse<JWTResponse>>('/api/v2/sign_in', {
            email: username,
            password: password,
        });
        console.log(rsp.data);

        return await loginProcess(rsp.data, username, password);
    } catch (e) {
        const r = {
            success: false,
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                'Sorry, request can not be processed.',
        };
        Logger.error('[Login] Login failed', r);
        return r;
    }
};

const signUp = async (username: string, password: string) => {
    try {
        const rsp = await ajax.post<GeneralResponse<JWTResponse>>('/api/v2/sign_up', {
            email: username,
            password: password,
        });

        return await loginProcess(rsp.data, username, password);
    } catch (e) {
        const r = {
            success: false,
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                'Sorry, request can not be processed.',
        };
        Logger.error('[Signup] Signup failed', r);
        return r;
    }
};

const signInWithGoogle = async (token: string) => {
    try {
        const rsp = await ajax.post<GeneralResponse<JWTResponse>>(
            '/api/v2/sign_in_with_google?access_token=' + token
        );

        return await loginProcess(rsp.data, '__google__', rsp.data.data.access_token);
    } catch (e) {
        const r = {
            success: false,
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                'Sorry, request can not be processed.',
        };
        Logger.error('[Signup] Google Signup failed', r);
        return r;
    }
};

const signInWithApple = async (token: string, username?: string) => {
    try {
        const rsp = await ajax.post<GeneralResponse<JWTResponse>>(
            '/api/v2/sign_in_with_apple?apple_user_token=' +
                token +
                (username ? '&user_display_name=' + username : '')
        );

        return await loginProcess(rsp.data, username || '__apple__', rsp.data.data.access_token);
    } catch (e) {
        const r = {
            success: false,
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                'Sorry, request can not be processed.',
        };
        Logger.error('[Signup] Apple Signup failed', r);
        return r;
    }
};

const codeVerify = async (
    username: string,
    password: string,
    code: string
): Promise<LoginResult> => {
    try {
        const rsp = await ajax.post<GeneralResponse<JWTResponse>>(
            '/api/v2/finish_sign_up_with_code',
            {
                email: username,
                password: password,
                verify_code: code,
            }
        );

        return await loginProcess(rsp.data, username, password);
    } catch (e) {
        const r = {
            success: false,
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                'Sorry, request can not be processed.',
        };
        Logger.error('[Signup] Signup failed', r);
        return r;
    }
};

const passwordResetRequest = async (email: string) => {
    try {
        const rsp = await ajax.post<GeneralResponse<string>>(
            `/api/v2/forgot_password?email=${email}`
        );

        return rsp.data;
    } catch (e) {
        const r = {
            data: '',
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                "We can't process this request now.",
        } as GeneralResponse<string>;
        Logger.error('[PWDReset] PWDReset request failed', r);
        return r;
    }
};

const resetPassword = async (password: string, token: string) => {
    try {
        const rsp = await ajax.post<GeneralResponse<string>>(`/api/v2/reset_password`, {
            password,
            token,
        });

        return rsp.data;
    } catch (e) {
        const r = {
            data: '',
            errorMessage:
                (e as AxiosError<GeneralResponse<any>>).response?.data.errorMessage ||
                "We can't process this request now.",
        } as GeneralResponse<string>;
        Logger.error('[PWDReset] PWDReset failed', r);
        return r;
    }
};

const registerNotificationToken = async (token: string, deviceType: string) => {
    const rsp = await ajax.post<GCJSONResponse<string>>(`/api/notify/devices/register`, {
        deviceType,
        token,
    });

    return rsp.data;
};

export const authService = {
    login,
    signInWithGoogle,
    signInWithApple,
    signUp,
    codeVerify,
    passwordResetRequest,
    resetPassword,
    registerNotificationToken,
};
