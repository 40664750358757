import { useNavigation } from '@react-navigation/native';
import {
    AddressUtils,
    FoodPrint,
    FoodPrintService,
    FoodPrintType,
    formatAddress,
    User,
} from 'gc-common';
import React, { useEffect, useMemo } from 'react';
import { Image, ListRenderItem, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { styles as commentStyles } from '../../components/CommentCard/styles';
import FoodPrintCard from '../../components/FoodPrintCard/FoodPrintCard';
import { Ratings } from '../../components/Ratings/Ratings';
import { RowItem } from '../../components/RowItem/RowItem';
import { Colors } from '../../constants/Colors';
import { PaginationDataWithLoader, usePagination } from '../../hooks/usePagination';
import { RootTabNavigationProps } from '../../types';
import { DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { DateTimeUtils } from '../../utils/dateUtils';

export const useFoodPrint = (user?: User, isSelf = false) => {
    const DEFAULT_SIZE = 10;
    const navigation = useNavigation<RootTabNavigationProps<'Me'>>();

    const useIndentation = useMemo<boolean>(() => {
        return true;
        // return Dimensions.get('window').width > 400;
    }, []);

    useEffect(() => {
        user && fetchData(1, DEFAULT_SIZE);
    }, [user]);

    const { data, page, isFetching, fetchData, refresh, ...pagination } = usePagination<FoodPrint>(
        async (page, size) => {
            if (!user) return undefined;
            let rsp;
            if (!isSelf) rsp = await FoodPrintService.fetchData(user.user_id, page, DEFAULT_SIZE);
            else rsp = await FoodPrintService.fetchAllFollowing(page, DEFAULT_SIZE);
            return rsp.data;
        }
    );

    const onCardPressed = (item: FoodPrint) => {
        if (item.foodprint_type === FoodPrintType.create_dish)
            navigation.push('DishDetail', { id: item.details.dish_id });
        if (item.foodprint_type === FoodPrintType.create_restaurant)
            navigation.push('RestaurantDetail', { id: item.details.restaurant_id });
        if (item.foodprint_type === FoodPrintType.create_dish_post)
            navigation.push('PostDetail', { id: item.details.dish_post_id });
        if (item.foodprint_type === FoodPrintType.check_in_restaurant)
            navigation.push('RestaurantDetail', { id: item.details.restaurant_id });
    };

    const onAvatarPressed = (item: FoodPrint) => {
        navigation.push('User', { id: item.details.user_id });
    };

    const renderListItem: ListRenderItem<PaginationDataWithLoader<FoodPrint>> = ({ item }) => {
        if (item.$isLoader)
            return (
                <FoodPrintCard
                    skeleton
                    name={''}
                    time={''}
                    eventTxt={''}
                    avatar={''}
                    useIndentation={useIndentation}
                />
            );

        if (item.foodprint_type === FoodPrintType.check_in_restaurant)
            return (
                <FoodPrintCard
                    name={item.details.user_display_name}
                    time={DateTimeUtils.formatDateTime(item.details.last_updated)}
                    eventTxt={'checked in at '}
                    highlightText={AddressUtils.formatCityNState(
                        item.details.historical_restaurant_address
                    )}
                    avatar={item.details.user_profile_photo_url}
                    useIndentation={useIndentation}
                    onCardPressed={() => onCardPressed(item)}
                    onAvatarPressed={() => onAvatarPressed(item)}
                >
                    <RowItem
                        disabled
                        noMarginHorizontal={useIndentation}
                        image={
                            item.details.historical_restaurant_avatar_image_url ||
                            DEFAULT_RESTAURANT_IMAGE
                        }
                        title={item.details.historical_restaurant_name}
                        subTitle={formatAddress(item.details.historical_restaurant_address)}
                    />
                </FoodPrintCard>
            );
        else if (item.foodprint_type === FoodPrintType.create_dish)
            return (
                <FoodPrintCard
                    name={item.details.user_display_name}
                    time={DateTimeUtils.formatDateTime(item.details.time_created)}
                    eventTxt={'uploaded a new Dish'}
                    highlightText={AddressUtils.formatCityNState(item.details.address)}
                    avatar={item.details.user_profile_photo_url}
                    useIndentation={useIndentation}
                    onCardPressed={() => onCardPressed(item)}
                    onAvatarPressed={() => onAvatarPressed(item)}
                >
                    <RowItem
                        disabled
                        noMarginHorizontal={useIndentation}
                        image={item.details.avatar_image_url || DEFAULT_RESTAURANT_IMAGE}
                        title={item.details.name}
                        subTitle={item.details.restaurant_name}
                    />
                </FoodPrintCard>
            );
        else if (item.foodprint_type === FoodPrintType.create_restaurant)
            return (
                <FoodPrintCard
                    name={item.details.user_display_name}
                    time={DateTimeUtils.formatDateTime(item.details.time_created)}
                    eventTxt={'uploaded a new Restaurant'}
                    highlightText={AddressUtils.formatCityNState(item.details.address)}
                    avatar={item.details.user_profile_photo_url}
                    useIndentation={useIndentation}
                    onCardPressed={() => onCardPressed(item)}
                    onAvatarPressed={() => onAvatarPressed(item)}
                >
                    <RowItem
                        disabled
                        noMarginHorizontal={useIndentation}
                        image={item.details.avatar_image_url || DEFAULT_RESTAURANT_IMAGE}
                        title={item.details.name}
                        subTitle={formatAddress(item.details.address)}
                    />
                </FoodPrintCard>
            );
        else if (item.foodprint_type === FoodPrintType.create_dish_post)
            return (
                <FoodPrintCard
                    name={item.details.user_display_name}
                    time={DateTimeUtils.formatDateTime(item.details.last_updated)}
                    eventTxt={'published a Post'}
                    highlightText={AddressUtils.formatCityNState(item.details.address)}
                    avatar={item.details.user_profile_photo_url}
                    useIndentation={useIndentation}
                    onCardPressed={() => onCardPressed(item)}
                    onAvatarPressed={() => onAvatarPressed(item)}
                >
                    <View>
                        {item.details.image_urls.length > 0 && (
                            <View style={[commentStyles.imagesWrapper, ss.imageWrapper]}>
                                {item.details.image_urls?.map((image, index) => (
                                    <View key={index} style={{ width: '33.333333%', padding: 2 }}>
                                        <TouchableOpacity>
                                            <Image
                                                style={[
                                                    commentStyles.contentImage,
                                                    { borderRadius: 10 },
                                                ]}
                                                source={{
                                                    uri: image,
                                                }}
                                            />
                                        </TouchableOpacity>
                                    </View>
                                ))}
                            </View>
                        )}
                        <Ratings rating={item.details.rating} />
                        <Text style={ss.cardTitle}>{item.details.dish_name}</Text>
                        <Text style={ss.cardSubText}>{item.details.restaurant_name}</Text>
                        {item.details.content && (
                            <Text style={ss.reviewContent} numberOfLines={5}>
                                {item.details.content}
                            </Text>
                        )}
                    </View>
                </FoodPrintCard>
            );

        return <></>;
    };

    return {
        data,
        page,
        refresh,
        fetchData,
        isFetching,
        renderListItem,
    };
};

const ss = StyleSheet.create({
    imageWrapper: {},
    cardTitle: {
        fontFamily: 'eina01-semibold',
        fontSize: 16,
        marginVertical: 5,
        color: Colors.charcoal,
    },
    cardSubText: {
        fontFamily: 'eina01',
        fontSize: 12,
        color: Colors.dark_gray,
    },
    reviewContent: {
        color: Colors.dark_gray,
        marginTop: 5,
        marginBottom: 10,
        lineHeight: 18,
    },
});
