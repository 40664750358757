import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Colors } from '../constants/Colors';
import { useAuth } from '../context/authContext';
import { useModal } from '../context/modalContext';

export const PolicyWidget = () => {
    const navigation = useNavigation();
    const auth = useAuth();
    const modal = useModal();

    return (
        <View style={styles.wrapper}>
            <View style={styles.row}>
                <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
                    <Text style={styles.text}>Privacy Policy</Text>
                </TouchableOpacity>
                <Text style={styles.sep}>|</Text>
                <TouchableOpacity onPress={() => navigation.navigate('TermsOfService')}>
                    <Text style={styles.text}>Term of Service</Text>
                </TouchableOpacity>
                <Text style={styles.sep}>|</Text>
                <TouchableOpacity onPress={() => navigation.navigate('ContactUs')}>
                    <Text style={styles.text}>Contact Us</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.row}>
                <TouchableOpacity onPress={() => navigation.navigate('EULA')}>
                    <Text style={styles.text}>End-User License Agreement (EULA)</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.row}>
                <TouchableOpacity
                    onPress={() => {
                        if (!auth.isLoggedIn) modal.open('warning', 'Please login first.');
                        else navigation.navigate('DeleteAccount');
                    }}
                >
                    <Text style={styles.text}>Delete My Account</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
    },
    text: {
        color: Colors.gray,
        fontSize: 13,
    },
    sep: {
        paddingHorizontal: 5,
        color: Colors.gray,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 10,
    },
});
