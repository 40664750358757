import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { User, UserService, UserStats } from 'gc-common';
import React, { useEffect, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { RootStackParamList } from '../../types';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { styles } from './styles';

export const useStats = (user?: User) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const [stats, setStats] = useState<UserStats>();

    const fetchData = async (id?: string) => {
        if (!id) return;
        setStats(undefined);
        const stats = await UserService.getStats(id);
        setStats(stats.data);
    };

    useEffect(() => {
        user && fetchData(user.user_id);
    }, [user]);

    const render = () => {
        if (stats)
            return (
                <View style={styles.counterWrapper}>
                    <View style={styles.counterCard}>
                        <Text style={styles.counterCardNumber}>{stats.dish_posts}</Text>
                        <Text style={styles.counterCardText}>Posts</Text>
                    </View>

                    <Pressable
                        onPress={() =>
                            navigation.push('MeFollow', { type: 'follower', userId: user!.user_id })
                        }
                    >
                        <View style={styles.counterCard}>
                            <Text style={styles.counterCardNumber}>{stats.followers}</Text>
                            <Text style={styles.counterCardText}>Followers</Text>
                        </View>
                    </Pressable>

                    <Pressable
                        onPress={() =>
                            navigation.push('MeFollow', {
                                type: 'following',
                                userId: user!.user_id,
                            })
                        }
                    >
                        <View style={styles.counterCard}>
                            <Text style={styles.counterCardNumber}>{stats.followings}</Text>
                            <Text style={styles.counterCardText}>Following</Text>
                        </View>
                    </Pressable>
                </View>
            );
        else
            return (
                <SkeletonPlaceholder>
                    <View style={styles.counterWrapper}>
                        <View style={styles.counterCard}>
                            <SkeletonPlaceholder.Item width={40} height={40} borderRadius={10} />
                        </View>
                        <View style={styles.counterCard}>
                            <SkeletonPlaceholder.Item width={40} height={40} borderRadius={10} />
                        </View>
                        <View style={styles.counterCard}>
                            <SkeletonPlaceholder.Item width={40} height={40} borderRadius={10} />
                        </View>
                    </View>
                </SkeletonPlaceholder>
            );
    };

    return {
        render,
        fetchData,
        stats,
    };
};
