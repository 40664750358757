import React, { FC, ReactNode, useContext } from 'react';
import { useAuthHook } from '../services/auth/auth';

const AuthContext = React.createContext<ReturnType<typeof useAuthHook> | undefined>(undefined);

export const AuthContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const auth = useAuthHook();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext)!;
};
