import { Entypo, Feather } from '@expo/vector-icons';
import React from 'react';
import { GestureResponderEvent, Text, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { styles } from './styles';

/**
 * The leftIcon only supports Feather icons
 * The rightIcon only supports Entypo icons
 */
export interface SettingsButtonProps {
    leftIconName: keyof typeof Feather.glyphMap;
    leftIconColor?: string;
    rightIconName?: keyof typeof Entypo.glyphMap;
    rightIconColor?: string;
    text: string;
    textColor?: string;
    onPress?: (event: GestureResponderEvent) => void;
}

function SettingsButton({
    leftIconName,
    leftIconColor = Colors.charcoal,
    rightIconName,
    rightIconColor = Colors.charcoal,
    text,
    textColor = Colors.charcoal,
    onPress,
}: SettingsButtonProps) {
    return (
        <View style={styles.buttonContainer}>
            <Feather name={leftIconName} size={18} color={leftIconColor} />
            <View style={styles.Button}>
                <TouchableOpacity onPress={onPress}>
                    <View style={styles.iconAndTextContainer}>
                        <Text style={[styles.buttonText, { color: textColor }]}>{text}</Text>
                        {rightIconName && (
                            <Entypo name={rightIconName} size={20} color={rightIconColor} />
                        )}
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
}

export default SettingsButton;
