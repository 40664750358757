import { useMemo } from 'react';
import { ScreenUtils } from '../utils/screenUtils';

export const useDynamicColumns = (itemWidth: number, minCols = 1) => {
    const cols = useMemo(() => {
        return ScreenUtils.calcGridCols(itemWidth, minCols);
    }, []);

    const cardWidth = useMemo(() => ScreenUtils.colsToWidthPercentage(cols || 1), [cols]);

    return {
        cols,
        cardWidth,
    };
};
