// import { DdLogs } from '@datadog/mobile-react-native';
import { Feather } from '@expo/vector-icons';
import BottomSheet, { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import Geolocation from '@react-native-community/geolocation';
import { LocationType, LocationUtils } from 'gc-common';
import React, { useMemo, useRef } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { BottomSheetStyles } from '../components/Styles/BottomSheetStyles';
import { useAuth } from '../context/authContext';
import { LOCATIONS } from '../types/Constants';

export const useLocationSelector = () => {
    const { location, setLocation } = useAuth();
    const bottomSheetRef = useRef<BottomSheet>(null);

    const onLocationSelect = async (loc?: LocationType) => {
        bottomSheetRef.current?.close();
        if (!loc) {
            Geolocation.getCurrentPosition(
                (position) => {
                    console.log(position.coords);
                    const { longitude, latitude } = position.coords;
                    console.log('GEO:', longitude, latitude);
                    setLocation([longitude, latitude]);
                },
                () => {
                    // Env.isNative && DdLogs.warn('User denies location permission.');
                    setLocation(LOCATIONS[0].location);
                }
            );
            return;
        }
        setLocation(loc!);
    };

    const isUsingGeo = useMemo<boolean>(() => {
        if (!location) return false;
        for (let loc of LOCATIONS) {
            if (LocationUtils.isEqual(loc.location, location)) return false;
        }
        return true;
    }, [location]);

    const render = () => {
        return (
            <BottomSheet
                ref={bottomSheetRef}
                index={-1}
                snapPoints={[400]}
                enablePanDownToClose
                style={BottomSheetStyles.style}
                handleStyle={BottomSheetStyles.handleStyle}
                backdropComponent={(props) => (
                    <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />
                )}
            >
                <View
                    style={[
                        BottomSheetStyles.contentStyle,
                        {
                            alignItems: 'center',
                        },
                    ]}
                >
                    <TouchableOpacity
                        style={BottomSheetStyles.actionItem}
                        onPress={() => {
                            onLocationSelect();
                        }}
                    >
                        <Text style={BottomSheetStyles.actionItemText}>
                            {isUsingGeo && <Feather name={'check'} size={18} />}
                            Use your current location
                        </Text>
                    </TouchableOpacity>

                    {LOCATIONS.map((loc) => {
                        return (
                            <TouchableOpacity
                                key={loc.name}
                                style={BottomSheetStyles.actionItem}
                                onPress={() => {
                                    onLocationSelect(loc.location as LocationType);
                                }}
                            >
                                <Text style={BottomSheetStyles.actionItemText}>
                                    {loc.location &&
                                        location &&
                                        LocationUtils.isEqual(
                                            loc.location as LocationType,
                                            location
                                        ) && <Feather name={'check'} size={18} />}
                                    {loc.name}
                                </Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
            </BottomSheet>
        );
    };

    return {
        render,
        bottomSheetRef,
    };
};
