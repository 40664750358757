import { Image, StyleSheet, Text, View } from 'react-native';

import { useEffect } from 'react';
import { Colors } from '../constants/Colors';

export default function ComingSoonPage() {
    useEffect(() => {}, []);

    return (
        <View style={styles.container}>
            <Image
                resizeMethod={'scale'}
                resizeMode={'contain'}
                style={styles.icon}
                source={require('../../assets/images/logo-no-margin.png')}
            />
            <Text style={styles.title}>Coming Soon...</Text>
            <Text style={styles.subTitle}>Amazingness in progress.</Text>
            <Text style={styles.subTitle}>Stay tuned with GourmetCarte!</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        // padding: 20,
        paddingHorizontal: 40,
        paddingBottom: 100,
        backgroundColor: Colors.white,
    },
    title: {
        fontSize: 36,
        marginVertical: 20,
        color: Colors.charcoal,
        fontFamily: 'eina01-bold',
    },
    subTitle: {
        fontSize: 20,
        marginBottom: 5,
        color: Colors.dark_gray,
    },
    icon: {
        width: '100%',
        // aspectRatio:1,
        // borderWidth:1,
        height: 200,
    },
});
