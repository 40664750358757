import { Feather } from '@expo/vector-icons';
import { CompositeNavigationProp } from '@react-navigation/native';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { DeviceEventEmitter, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDebounce } from 'use-debounce';
import styles from '../navigation/styles';
import CustomInputText from './CustomInputText/CustomInputText';

export const HomeScreenTopHeader = ({
    navigation,
}: {
    navigation: CompositeNavigationProp<any, any>;
}) => {
    const [active, setActive] = useState('forYou');

    const onMapClicked = () => {
        DeviceEventEmitter.emit('open-location-selector');
    };

    const onSearchClicked = () => {
        navigation.push('Search');
    };

    return (
        <SafeAreaView edges={['top']} style={styles.wrapper}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {/*<View>*/}
                {/*    <Feather name={'map-pin'} size={25} onPress={onMapClicked} />*/}
                {/*</View>*/}
                {/*<Pressable*/}
                {/*    style={[styles.homeTopTag, active == 'forYou' ? styles.homeTopTagActive : {}]}*/}
                {/*    onPress={() => {*/}
                {/*        setActive('forYou');*/}
                {/*        navigation.setParams({*/}
                {/*            page: 'For You',*/}
                {/*        });*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={[styles.text, active == 'forYou' ? styles.activeText : {}]}>For You</Text>*/}
                {/*</Pressable>*/}
                {/*<Pressable*/}
                {/*    style={[styles.homeTopTag, active == 'following' ? styles.homeTopTagActive : {}]}*/}
                {/*    onPress={() => {*/}
                {/*        setActive('following');*/}
                {/*        navigation.setParams({*/}
                {/*            page: 'Following',*/}
                {/*        });*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={[styles.text, active == 'following' ? styles.activeText : {}]}>Following</Text>*/}
                {/*</Pressable>*/}
                {/*<View>*/}
                {/*    <Feather name={'search'} size={25} onPress={onSearchClicked} />*/}
                {/*</View>*/}
                <TouchableOpacity
                    style={{ padding: 10, marginHorizontal: 10, flex: 1 }}
                    onPressIn={onSearchClicked}
                >
                    <CustomInputText
                        onPressIn={onSearchClicked}
                        editable={false}
                        isPassword={false}
                        leftIcon={'search'}
                        height={35}
                        placeholder={'Search dishes, restaurants or users'}
                    />
                </TouchableOpacity>
                <View>
                    <Feather name={'map-pin'} size={25} onPress={onMapClicked} />
                </View>
            </View>
        </SafeAreaView>
    );
};

export const SearchScreenTopHeader = React.forwardRef<TextInput, any>(
    (
        {
            navigation,
        }: {
            navigation: CompositeNavigationProp<any, any>;
        },
        ref
    ) => {
        const onSearchClicked = () => {};
        const inputRef = useRef<TextInput>(null);
        const [keyword, setKeyword] = useState<string>();
        const [keywordDebounced] = useDebounce(keyword, 200);

        useEffect(() => {
            if (keywordDebounced !== undefined) {
                DeviceEventEmitter.emit('search-typing', keywordDebounced);
            }
        }, [keywordDebounced]);

        const onSearch = () => {
            console.log(`Searching Keyword: ${keyword}`);
            DeviceEventEmitter.emit('search-submit', keyword);
        };

        return (
            <SafeAreaView edges={['top']} style={styles.wrapperNoShadow}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View>
                        <Feather name={'chevron-left'} size={25} onPress={() => navigation.pop()} />
                    </View>
                    <View style={{ padding: 10, marginHorizontal: 10, flex: 1 }}>
                        <CustomInputText
                            clearButtonMode={'while-editing'}
                            ref={ref}
                            isPassword={false}
                            leftIcon={'search'}
                            height={35}
                            placeholder={'Search dishes, restaurants or users'}
                            onSubmitEditing={onSearch}
                            value={keyword}
                            onChangeText={setKeyword}
                        />
                    </View>
                    <View>
                        <Text onPress={onSearch}>Search</Text>
                    </View>
                </View>
            </SafeAreaView>
        );
    }
);
