import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    cardWrapper: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        alignItems: 'flex-start',
    },
    avatarWrapper: {
        paddingRight: 15,
    },
    avatarImage: {
        height: 40,
        width: 40,
        borderRadius: 60,
    },
    nameWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentWrapper: {
        flex: 1,
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 15,
    },

    title: {
        color: Colors.charcoal,
        fontSize: 18,
        fontFamily: 'eina01-bold',
    },

    infoText: {
        color: Colors.dark_gray,
        fontSize: 12,
        marginTop: 5,
    },

    extraInfoText: {
        fontSize: 15,
        marginRight: 15,
    },

    content: {
        color: Colors.dark_gray,
        marginTop: 15,
        lineHeight: 16,
        fontSize: 12,
    },

    imagesWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 15,
    },
    contentImage: {
        // width: '30%',
        // height: 190,
        aspectRatio: 1,
        // margin: 2,
    },
    footerText: {
        color: Colors.gray,
        fontWeight: 'bold',
    },
});
