import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { DishService, Post, PostService } from 'gc-common';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CommentCard } from '../../components/CommentCard/CommentCard';
import { PaginationDataWithLoader, usePagination } from '../../hooks/usePagination';
import { RootStackParamList } from '../../types';
import { DEFAULT_USER_AVATAR } from '../../types/Constants';

export const useReviews = (dishId?: string, selectedPostId?: string) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const {
        data: reviews,
        page,
        fetchData,
        isFetching,
    } = usePagination<Post>(async (page, size) => {
        if (!dishId) return [];
        const data = await DishService.getAllPosts(dishId);

        // if (!selectedPost)
        return data.data;
        // if (selectedPost && page === 1) {
        //     return [selectedPost, ...data.data];
        // } else {
        //     return data.data;
        // }
    }, 1);

    useEffect(() => {
        dishId && fetchData();
    }, [dishId]);
    useEffect(() => {
        selectedPostId && fetchSelectedPost();
    }, [selectedPostId]);

    const [selectedPost, setSelectedPost] = useState<Post>();
    const fetchSelectedPost = async () => {
        if (!selectedPostId) return;
        const rsp = await PostService.get(selectedPostId);

        if (!rsp.errorMessage) setSelectedPost(rsp.data);
    };

    if (!dishId) return undefined;

    const renderCard = (post: PaginationDataWithLoader<Post>, renderSelected = false) => {
        if (!renderSelected && post.dish_post_id == selectedPostId) return <></>;
        return (
            <CommentCard
                id={post.dish_post_id}
                avatar={post.user_profile_photo_url || DEFAULT_USER_AVATAR}
                avatarId={post.user_id}
                author={post.user_display_name!}
                city={post.address?.city || ''}
                content={post.content}
                createdTime={moment(post.last_updated * 1000).fromNow()}
                images={post.image_urls}
                likes={post.num_of_likes}
                comments={post.num_of_comments}
                key={post.dish_post_id}
                rating={post.rating}
                skeleton={post.$isLoader}
                onPress={() => {
                    navigation.push('PostDetail', { id: post.dish_post_id });
                }}
            />
        );
    };

    const ReviewsSection = () => {
        return reviews?.map((i, index) => renderCard(i));
    };

    const renderSelectedPost = () => {
        if (!selectedPost) return <></>;
        // console.log('render dish post');
        return <View style={{ marginHorizontal: 10 }}>{renderCard(selectedPost, true)}</View>;
    };

    return {
        renderCard,
        reviews,
        ReviewsSection,
        loading: isFetching,
        renderSelectedPost,
    };
};
