import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    itemCard: {
        borderRadius: 10,
        width: '100%',
        backgroundColor: 'white',
        elevation: 15,

        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
    },
    itemCardWrapper: {
        width: '100%',
        padding: 5,
    },
    picWrapper: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        overflow: 'hidden',
    },
    pic: {
        width: '100%',
        height: 250,
    },
    infoWrapper: {
        padding: 10,
    },
    ratingWrapper: {
        marginBottom: 5,
    },
    deleteButton: {
        fontSize: 25,
        position: 'absolute',
        top: 10,
        right: 15,
        color: 'white',
        opacity: 0.9,
    },
    titleWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    title: {
        color: Colors.charcoal,
        fontWeight: 'bold',
    },
    titleIcon: {
        paddingRight: 5,
    },
    authorWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    authorName: {
        flex: 1,
    },
    authorAvatar: {
        height: 20,
        width: 20,
        borderRadius: 20,
        marginRight: 5,
    },
});
