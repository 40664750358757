import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { CollectionEnum, CollectionService } from 'gc-common';
import { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext';
import { RootStackParamList } from '../types';
import { EventLogger } from '../utils/EventLogger';

export const useCollection = (
    type: CollectionEnum,
    restaurantId?: string,
    dishId?: string,
    autoStop: boolean = true
) => {
    const [isCollect, setIsCollect] = useState<boolean>(false);
    const [isCollecting, setIsCollecting] = useState<boolean>(true);
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const auth = useAuth();

    useEffect(() => {
        fetchHasCollected();
    }, [restaurantId]);

    const fetchHasCollected = async () => {
        if (!restaurantId || !auth.isLoggedIn) return setIsCollecting(false);
        let res = await CollectionService.getHasCollected(type, restaurantId, dishId);

        setIsCollect(res.data);
        setIsCollecting(false);
    };

    const onCollect = async () => {
        if (!restaurantId || !auth.isLoggedIn) {
            EventLogger.redirectToAuth('dish_collect');
            navigation.push('SignInStack');
            return;
        }
        if (autoStop) setIsCollecting(true);

        if (dishId) EventLogger.clickDishCollect(dishId);
        else EventLogger.clickRestaurantCollect(restaurantId);
        const res = await CollectionService.actionCollect(type, !isCollect, restaurantId, dishId);

        if (!res?.errorMessage) setIsCollect(!isCollect);
        if (autoStop) setIsCollecting(false);
    };

    return {
        onCollect,
        isCollect,
        isCollecting,
        setIsCollecting,
    };
};
