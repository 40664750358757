import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    cardWrapper: {
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 17,
        // elevation: 15,
        marginVertical: 5,
    },

    boxShadow: {
        elevation: 1.5,
        shadowColor: Colors.charcoal,
        shadowOffset: { width: 1, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 5,
    },
});
