// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyALpKC--bvMHnplpx1dWX_6AO7VpIOWyCI",
    authDomain: "fluted-arch-373220.firebaseapp.com",
    projectId: "fluted-arch-373220",
    storageBucket: "fluted-arch-373220.appspot.com",
    messagingSenderId: "815291162954",
    appId: "1:815291162954:web:6e0d2b7133e2a9dcfb61b3",
    measurementId: "G-YHHEHF977Z"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics;




