import { Ionicons } from '@expo/vector-icons';
import { Dish } from 'gc-common';
import React, { useRef } from 'react';
import { Menu, MenuRef } from '../../components/Menu/Menu';
import { StyledText, StyledTouchable, StyledView } from '../../components/StyledComponents';
import { RootStackScreenProps } from '../../types';
import {View} from "react-native";

export const MenuPage = ({ route, navigation }: RootStackScreenProps<'Menu'>) => {
    const restaurantId = route.params.restaurantId;
    const restaurantName = route.params.restaurantName;
    const menu = useRef<MenuRef>(null);

    const onSelection = (dish: Dish, isNew?: boolean) => {
        if (isNew) menu.current?.refresh();
        navigation.push('DishDetail', { id: dish.dish_id });
    };

    const goRestaurantDetail = () => {
        navigation.push('RestaurantDetail', { id: restaurantId });
    };

    return (
        <View style={{flex:1}}>
            {restaurantName && (
                <StyledView tw="flex-row items-center bg-white pb-2 px-5">
                    <StyledText tw="flex-1 font-bold text-lg">{restaurantName}</StyledText>
                    <StyledTouchable
                        tw="w-9 h-9 rounded-full bg-primary items-center justify-center"
                        onPress={goRestaurantDetail}
                    >
                        <Ionicons name={'home'} size={18} color="white" />
                    </StyledTouchable>
                </StyledView>
            )}
            <View style={{flex:1}}>
                <Menu ref={menu} onSelection={onSelection} restaurantId={restaurantId} />
            </View>
        </View>
    );
};
