import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Dish, Restaurant, RestaurantService } from 'gc-common';
import React, { useEffect, useState } from 'react';
import { FlatList, Image, ListRenderItem, Text, TouchableOpacity, View } from 'react-native';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { DEFAULT_DISH_IMAGE } from '../../types/Constants';
import { styles } from './styles';

export const usePopDish = (restaurant?: Restaurant) => {
    const [dishes, setDishes] = useState<Dish[]>([]);
    const navigation = useNavigation<NativeStackNavigationProp<any>>();

    useEffect(() => {
        restaurant && fetchData(restaurant?.restaurant_id);
    }, [restaurant]);

    const fetchData = async (restaurant_id: string) => {
        const data = await RestaurantService.getPopularDishes(restaurant_id);
        setDishes(data);
    };

    const openMenu = () => {
        navigation.push('Menu', { restaurantId: restaurant?.restaurant_id });
    };

    const render = () => {
        if (!restaurant) return <></>;

        const renderItem: ListRenderItem<Dish> = ({ item }) => {
            const onPress = () => {
                navigation.push('DishDetail', { id: item.dish_id });
            };
            return (
                <TouchableOpacity onPress={onPress} accessibilityLabel={'Popular Dish'}>
                    <Image
                        style={styles.dishImage}
                        source={{ uri: item.image_urls?.[0] || DEFAULT_DISH_IMAGE }}
                    />
                    <Text numberOfLines={2} style={styles.dishText}>
                        {item.name}
                    </Text>
                </TouchableOpacity>
            );
        };
        return (
            <View style={styles.section}>
                <View style={styles.titleRow}>
                    <Entypo name={'bowl'} style={styles.titleIcon} />
                    <Text style={styles.title}>Popular Dishes</Text>

                    <TouchableOpacity onPress={openMenu} style={CommonStyles.flexRowCenter}>
                        <Text>Full Menu </Text>
                        <Entypo name={'chevron-right'} size={18} />
                    </TouchableOpacity>
                </View>
                <FlatList
                    style={styles.popDishWrapper}
                    data={dishes}
                    renderItem={renderItem}
                    horizontal={true}
                />
            </View>
        );
    };

    return {
        render,
        dishes,
    };
};
