import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    numberListContainer: {
        flexDirection: 'row',
        marginTop: 20,
    },
    numberListNumberStyle: {
        fontSize: 16,
        fontFamily: 'eina01-semibold',
        lineHeight: 20,
    },
    numberListContentStyle: {
        paddingLeft: 10,
        fontSize: 16,
        fontFamily: 'eina01-semibold',
        lineHeight: 20,
    },
    bulletListContainer: {
        flexDirection: 'row',
        marginLeft: 10,
        marginTop: 10,
    },
    bulletStyle: {
        fontFamily: 'eina01',
    },
    bulletListContentStyle: {
        paddingLeft: 10,
        fontSize: 12,
        fontFamily: 'eina01',
        lineHeight: 15,
    },
    columnStyle: {
        borderLeftWidth: 0.5,
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingVertical: 5,
        paddingHorizontal: 5,
    },
    rowsStyle: {
        borderTopWidth: 0.5,
        borderColor: 'black',
    },
    gridContent: {
        fontSize: 12,
        fontFamily: 'eina01',
        lineHeight: 15,
    },
    hyperlinkStyle: {
        color: 'blue',
    },
    contentBold: {
        fontFamily: 'eina01-semibold',
        fontSize: 12,
        lineHeight: 15,
    },
    content: {
        fontFamily: 'eina01',
        fontSize: 12,
        // lineHeight: 15,
        marginTop: 10,
    },
    customH2Style: {
        fontSize: 16,
        fontFamily: 'eina01-semibold',
        alignItems: 'center',
        marginTop: 10,
        lineHeight: 20,
    },
    titleContainer: {
        marginVertical: 20,
    },
    topBarContainer: {
        height: 80,
        flexDirection: 'column',
        paddingTop: 50,
        borderBottomWidth: 1,
        borderColor: Colors.light_gray,
        width: '100%',
        paddingHorizontal: 20,
    },
    screenContainer: {
        paddingHorizontal: 20,
        flex: 1,
        flexDirection: 'column',
    },
    title: {
        fontSize: 24,
        fontFamily: 'eina01-bold',
    },
    subTitle: {
        fontSize: 12,
        marginTop: 10,
        fontFamily: 'eina01',
    },
});
