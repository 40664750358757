import React, { useState } from 'react';
import { Animated, Dimensions } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { Image } from '../../webLibs/FastImage/FastImage';
import { styles } from './styles';

export interface PicCarouselProps {
    pics: string[];
    imageHeight?: Animated.Value;
}

export const PicCarousel = ({ pics, imageHeight }: PicCarouselProps) => {
    const [activePicIndex, setActivePicIndex] = useState(0);
    const pagination = () => {
        return (
            <Pagination
                containerStyle={styles.picPagination}
                dotsLength={pics.length}
                activeDotIndex={activePicIndex}
                dotStyle={{
                    width: 8,
                    height: 8,
                    borderRadius: 8,
                    // marginHorizontal: 8,
                    backgroundColor: 'rgba(255, 255, 255, 0.92)',
                }}
                inactiveDotOpacity={0.8}
                inactiveDotScale={0.6}
            />
        );
    };

    const renderPics = ({ item, index }: { index: number; dataIndex: number; item: string }) => {
        return <Image source={{ uri: item }} style={styles.pic} />;
    };

    return (
        <Animated.View
            style={[
                styles.picsContainer,
                imageHeight && {
                    transform: [
                        {
                            translateY: imageHeight.interpolate({
                                inputRange: [-1000, 0],
                                outputRange: [-100, 0],
                                extrapolate: 'clamp',
                            }),
                        },
                        {
                            scale: imageHeight.interpolate({
                                inputRange: [-3000, 0],
                                outputRange: [20, 1],
                                extrapolate: 'clamp',
                            }),
                        },
                    ],
                },
            ]}
        >
            <Carousel<string>
                vertical={false}
                keyExtractor={(item, index) => item}
                data={pics}
                renderItem={renderPics}
                sliderWidth={Dimensions.get('window').width}
                itemWidth={Dimensions.get('window').width}
                onSnapToItem={(index) => setActivePicIndex(index)}
            />
            {pagination()}
        </Animated.View>
    );
};
