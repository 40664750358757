import { Feather } from '@expo/vector-icons';
import { ParamListBase } from '@react-navigation/native';
import {
    NativeStackHeaderProps,
    NativeStackNavigationOptions,
    NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import * as React from 'react';
import { ReactNode, useEffect, useRef } from 'react';
import { Animated, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

type AnimatedOptionType = NativeStackNavigationOptions & { headerBack: any; headerTitle: any };
type AnimatedNativeStackHeaderProps = NativeStackHeaderProps & { options: AnimatedOptionType };

export const AnimatedTopHeader = ({
    options,
    route,
    navigation,
}: AnimatedNativeStackHeaderProps) => {
    const textColor = {
        color: options.headerTintColor,
    };
    const AnimatedIcon = Animated.createAnimatedComponent(Feather);
    return (
        <SafeAreaView edges={['top']} style={[styles.wrapper, styles.wrapperCommon]}>
            {options.headerBackground?.()}
            <View style={styles.container}>
                <TouchableOpacity
                    style={styles.backContainer}
                    onPress={() =>
                        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Root')
                    }
                >
                    {options.headerBack?.() || (
                        <Animated.Text style={{ color: options.headerTintColor }}>
                            <AnimatedIcon
                                name={'arrow-left'}
                                style={[styles.icon, {}]}
                                //color={options.headerTintColor}
                            />
                        </Animated.Text>
                    )}
                </TouchableOpacity>
                <View>
                    <Animated.Text style={[styles.title, options.headerTitleStyle]}>
                        {options.headerTitle}
                    </Animated.Text>
                    {/*{options.headerBack?.() || (*/}
                    {/*    <AnimatedIcon*/}
                    {/*        name={'arrow-left'}*/}
                    {/*        style={styles.back}*/}
                    {/*        color={options.headerTintColor}*/}
                    {/*    />*/}
                    {/*)}*/}
                </View>
                {/*<View style={styles.extraContainer}>*/}
                {/*    <TouchableOpacity>*/}
                {/*        <Animated.Text style={{ color: options.headerTintColor }}>*/}
                {/*            <AnimatedIcon*/}
                {/*                name={'share'}*/}
                {/*                style={[styles.icon, {}]}*/}
                {/*            />*/}
                {/*        </Animated.Text>*/}
                {/*    </TouchableOpacity>*/}
                {/*    <TouchableOpacity>*/}
                {/*        <Animated.Text style={{ color: options.headerTintColor }}>*/}
                {/*            <FontAwesome*/}
                {/*                name={'star'}*/}
                {/*                style={[styles.icon, {}]}*/}
                {/*            />*/}
                {/*        </Animated.Text>*/}
                {/*    </TouchableOpacity>*/}
                {/*</View>*/}
            </View>
        </SafeAreaView>
    ) as ReactNode;
};

export const useAnimatedTopHeader = (
    navigation: NativeStackNavigationProp<ParamListBase>,
    color = 'white'
) => {
    const yOffset = useRef(new Animated.Value(0)).current;
    const headerOpacity = yOffset.interpolate({
        inputRange: [0, 200],
        outputRange: [0, 1],
        extrapolate: 'clamp',
    });
    const textColor = yOffset.interpolate({
        inputRange: [0, 200],
        outputRange: ['rgb(255,255,255)', 'rgb(0,0,0)'],
        // outputRange: [10,50],
        //extrapolate: 'clamp',
    });

    useEffect(() => {
        navigation.setOptions({
            headerStyle: {},
            headerBackground: () => (
                <Animated.View
                    style={{
                        backgroundColor: color,
                        ...StyleSheet.absoluteFillObject,
                        opacity: headerOpacity,
                    }}
                />
            ),
            headerTransparent: false,
            headerTintColor: textColor as any,
            headerTitleStyle: {
                // @ts-ignore
                opacity: headerOpacity,
            },
        });
    }, [headerOpacity, navigation, textColor]);

    const onScrollEvent = Animated.event(
        [
            {
                nativeEvent: {
                    contentOffset: {
                        y: yOffset,
                    },
                },
            },
        ],
        { useNativeDriver: false }
    );

    return { yOffset, headerOpacity, onScrollEvent };
};

const styles = StyleSheet.create({
    wrapper: Platform.select({
        default: {
            shadowRadius: 3,
        },
        web: {
            shadowRadius: 0,
        },
    }),
    wrapperCommon: {
        width: '100%',
        position: 'absolute',
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
    },
    container: {
        height: 50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 18,
        fontFamily: 'eina01-semibold',
        // backgroundColor:'yellow',
    },
    backContainer: {
        position: 'absolute',
        height: '100%',
        justifyContent: 'center',
        left: 10,
        // backgroundColor:'green',
    },
    icon: {
        // backgroundColor:'yellow',
        fontSize: 20,
    },

    extraContainer: {
        position: 'absolute',
        height: '100%',
        justifyContent: 'center',
        right: 10,
    },
});
