import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { Card } from '../Card/Card';
import { Ratings } from '../Ratings/Ratings';
import { styles } from './styles';

export interface RowItemProps {
    image: string;
    title: string;
    price?: string;
    subTitle?: string;
    rating?: number;
    onPress?: () => void;
    titleNumLines?: number;
    subtitleNumLines?: number;
    children?: React.ReactNode;
    sideButton?: React.ReactNode;
    noMarginHorizontal?: boolean;
    disabled?: boolean;
}
export const RowItem = (props: RowItemProps) => {
    const { image, title, subTitle, price, rating, onPress } = props;
    const { titleNumLines, subtitleNumLines } = props;
    const { noMarginHorizontal } = props;

    return (
        <TouchableOpacity
            disabled={props.disabled}
            onPress={onPress}
            activeOpacity={0.8}
            style={[noMarginHorizontal && { marginHorizontal: -15 }]}
        >
            <Card>
                <View style={styles.wrapper}>
                    <Image style={styles.image} source={{ uri: image }} />

                    <View style={styles.contentWrapper}>
                        <View style={styles.flexRow}>
                            <Text style={styles.title} numberOfLines={titleNumLines || 2}>
                                {title}
                            </Text>
                            <Text style={styles.price}>{price}</Text>
                        </View>
                        {subTitle && (
                            <View>
                                <Text style={styles.subTitle} numberOfLines={subtitleNumLines || 2}>
                                    {subTitle}
                                </Text>
                            </View>
                        )}

                        {rating != undefined && (
                            <View style={styles.rating}>
                                <Ratings rating={rating} />
                            </View>
                        )}
                    </View>
                    {props.sideButton && (
                        <View style={{ justifyContent: 'center' }}>{props.sideButton}</View>
                    )}
                </View>
                {props.children}
            </Card>
        </TouchableOpacity>
    );
};
