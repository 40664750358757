import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    screenContainer: {
        paddingHorizontal: 5,
        flex: 1,
    },
    buttonGroupContainer: {
        borderColor: Colors.light_gray,
        borderTopWidth: 1,
        borderBottomWidth: 1,
    },
    divider: {
        height: 0.7,
        backgroundColor: Colors.light_gray,
    },
});
