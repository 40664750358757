import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    ratingWrapper: {
        flexDirection: 'row',
    },
    star: {
        color: 'white',
    },
    ratingStar: {
        fontSize: 13,
        borderRadius: 10,
        height: 10,
        width: 10,
        overflow: 'hidden',
        marginHorizontal: 1,
        borderColor: Colors.primary,
        backgroundColor: Colors.white,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    ratingStarFull: {
        backgroundColor: Colors.primary,
        height: 10,
        width: 10,
    },
    ratingStarHalf: {
        backgroundColor: Colors.primary,
        height: 10,
        width: 4,
    },
    ratingStarEmpty: {
        backgroundColor: Colors.white,
        height: 10,
        width: 10,
    },
    grayStar: {
        backgroundColor: 'gray',
    },

    editableStar: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
    editableStarInner: {
        height: 20,
        width: 20,
    },
});
