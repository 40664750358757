import 'expo-dev-client';

import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

import Geolocation from '@react-native-community/geolocation';
import * as WebBrowser from 'expo-web-browser';
import { UserService } from 'gc-common';
import * as React from 'react';
import { Suspense, useEffect, useRef } from 'react';
import { DeviceEventEmitter, LogBox, Platform, StyleSheet, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { setCustomText } from 'react-native-global-props';
import * as Sentry from 'sentry-expo';
import { AppDownloader, AppDownloaderBar } from './components/AppDownloader';
import { useAuth } from './context/authContext';
import { ModalContextProvider } from './context/modalContext';
import { Env } from './Env';
import { useLocationSelector } from './hooks/useLocationSelector';
import { useUpdate } from './hooks/useUpdate';
import { LOCATIONS } from './types/Constants';
import { setupAjax } from './utils/ajax';
import { DdLogs, DdSdkReactNative, DdSdkReactNativeConfiguration } from './webLibs/Datadog';
import './webLibs/Style';

LogBox.ignoreLogs([
    'new NativeEventEmitter',
    'Require cycle',
    'ios.model',
    'onInstallConversionDataLoaded',
    'VERSION_PREFIX',
]);

Sentry.init({
    dsn: 'https://63de003e68ad4f39b8d5f36a5e9b41c1@o1302678.ingest.sentry.io/4504821875539968',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    enableInExpoDevelopment: true,
    tracesSampleRate: 1.0,
    dist: Env.UPDATE_VERSION,
    environment: Env.APP_ENV,
    // release: 'DEVELOPMENT',
});

Sentry.init({
    dsn: 'https://63de003e68ad4f39b8d5f36a5e9b41c1@o1302678.ingest.sentry.io/4504821875539968',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    enableInExpoDevelopment: true,
    tracesSampleRate: 1.0,
    dist: Env.UPDATE_VERSION,
    environment: Env.APP_ENV,
    // release: 'DEVELOPMENT',
});

WebBrowser.maybeCompleteAuthSession();
export default function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();
    const auth = useAuth();
    const isWeb = useRef(Platform.OS === 'web');
    const locationSelector = useLocationSelector();

    let n: any;

    useUpdate();

    const updateLocation = () => {
        const geoMethod =
            Env.isWeb || Platform.OS === 'android' ? 'getCurrentPosition' : 'watchPosition';
        Geolocation[geoMethod](
            (position) => {
                console.log(position.coords);
                const { longitude, latitude } = position.coords;
                console.log('GEO:', longitude, latitude);
                auth.setLocation([longitude, latitude]);
                auth.setIsGeoPermitted(true);
            },
            () => {
                Env.isNative && DdLogs.warn('User denies location permission.');
                auth.setLocation(LOCATIONS[0].location);
                auth.setIsGeoPermitted(false);
            },
            {
                maximumAge: 0,
                enableHighAccuracy: true,
                interval: 10000,
                useSignificantChanges: true,
            }
        );
    };

    useEffect(() => {
        setupAjax(auth);
        if (Env.isNative) registerDataDog();
        if (Env.isNative)
            Geolocation.setRNConfiguration({
                skipPermissionRequests: false,
                authorizationLevel: 'whenInUse',
                locationProvider: 'auto',
            });

        updateLocation();

        // (async () => console.log('IDFV:::', await Application.getIosIdForVendorAsync()))();
    }, []);

    useEffect(() => {
        loginCheck();
        // UserService.userOpenApp();
    }, [auth.isLoggedIn]);

    useEffect(() => {
        UserService.userOpenApp();
    }, []);

    useEffect(() => {
        console.log('Reporting user to datadog....');
        const user = auth.user;
        if (Env.isNative) {
            if (user) {
                DdSdkReactNative.setUser({
                    id: user.user_id,
                    displayName: user.display_name,
                    // email:  user.email,
                });
                Sentry.Native.setUser({
                    id: user.user_id,
                    email: user.email,
                    username: user.display_name,
                });
            } else {
                DdSdkReactNative.setUser({});
                Sentry.Native.setUser(null);
            }
        }
    }, [auth.user]);

    useEffect(() => {
        DeviceEventEmitter.addListener('open-location-selector', () => {
            locationSelector.bottomSheetRef.current?.expand();
        });

        return () => {
            DeviceEventEmitter.removeAllListeners('open-location-selector');
        };
    }, []);

    const loginCheck = async () => {
        const isLoggedIn = auth.isLoggedIn;
        console.log('login:', isLoggedIn);
        if (isLoggedIn) {
            const { token, expire } = await auth.getToken();
            console.log('JWT token:', token, expire);
        }
    };

    const registerDataDog = async () => {
        console.log('Datadog logging to env:', Env.APP_ENV);
        const config = new DdSdkReactNativeConfiguration(
            'pubc3fdcc54c8799ddd11c0633628d0c398',
            Env.APP_ENV,
            '2d68cab8-48a2-4678-b3a3-2bb9a56b9eb9',
            true, // track User interactions (e.g.: Tap on buttons. You can use 'accessibilityLabel' element property to give tap action the name, otherwise element type will be reported)
            true, // track XHR Resources
            true // track Errors
        );
        // Optional: Select your Datadog website (one of "US", "EU" or "GOV")
        config.site = 'US';
        // Optional: enable or disable native crash reports
        config.nativeCrashReportEnabled = true;
        // Optional: sample RUM sessions (here, 80% of session will be sent to Datadog. Default = 100%)
        // config.sampleRate = 80;
        // config.verbosity = SdkVerbosity.DEBUG;

        await DdSdkReactNative.initialize(config);

        DdSdkReactNative.setAttributes({
            API_URL: Env.API_URL,
            APP_VERSION: Env.APP_VERSION,
            BUILD_VERSION: Env.BUILD_VERSION,
            UPDATE_VERSION: Env.UPDATE_VERSION,
        });
    };

    if (!isLoadingComplete) {
        return null;
    } else {
        setCustomText({
            style: {
                fontFamily: 'eina01',
            },
        });
        return (
            <GestureHandlerRootView style={[{ flex: 1 }, isWeb.current && webStyle.container]}>
                <SafeAreaProvider style={[]}>
                    <Suspense fallback="Loading...">
                        <ModalContextProvider>
                            {Env.isWeb && <AppDownloaderBar />}
                            <Navigation colorScheme={colorScheme} />

                            <StatusBar style="dark" />
                            {locationSelector.render()}
                            {Env.isWeb && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        bottom: 100,
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <AppDownloader />
                                </View>
                            )}
                        </ModalContextProvider>
                    </Suspense>
                </SafeAreaProvider>
            </GestureHandlerRootView>
        );
    }
}

const webStyle = StyleSheet.create({
    wrapper: {},
    container: {
        width: '100%',
        // maxWidth: 500,
        marginHorizontal: 'auto',
        left: 0,
        right: 0,
    },
    floating: {
        position: 'absolute',
        bottom: 100,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
