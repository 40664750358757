import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: Colors.white,
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.25,
        shadowRadius: 3,
        paddingHorizontal: 10,
        elevation: 5,
    },
    wrapperNoShadow: {
        backgroundColor: Colors.white,
        paddingHorizontal: 10,
    },
    homeTopTag: {
        flex: 1,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 20,
        marginRight: 20,
        flexDirection: 'row',
        justifyContent: 'center',
    },

    homeTopTagActive: {
        borderBottomColor: Colors.primary,
        borderBottomWidth: 3,
        borderStyle: 'solid',
    },

    text: {
        fontWeight: 'bold',
        fontSize: 18,
        color: Colors.light_gray,
    },
    activeText: {
        color: Colors.charcoal,
    },
    icon: {
        width: 30,
        height: 30,
    },
});

export default styles;
