import React, { useRef } from 'react';
import {
    Animated,
    Dimensions,
    FlatListProps,
    NativeScrollEvent,
    NativeSyntheticEvent,
} from 'react-native';
import { Env } from '../../Env';
import AnimatedProps = Animated.AnimatedProps;

export interface TabViewFlatListCompatible {
    headerHeight: number;
    tabBarHeight: number;
    // routes: RouteConfig;
    currentTabIndex: number;
    scrollY: any;
    listRefArr: React.MutableRefObject<any[]>;
    listOffset: React.MutableRefObject<any>;
    isListGliding: React.MutableRefObject<boolean>;
    syncScrollOffset: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
    addRef?: (key: string, ref: Animated.FlatList<any>) => void;
    onRef?: any;
}
export interface TabViewFlatListProps<T>
    extends AnimatedProps<FlatListProps<T>>,
        TabViewFlatListCompatible {
    viewKey: string;
}

export const TabViewFlatList = <T,>(props: TabViewFlatListProps<T>) => {
    const windowHeight = Dimensions.get('window').height;
    const { tabBarHeight, headerHeight, currentTabIndex, scrollY, ...fprops } = props;
    const { listRefArr, listOffset, isListGliding, syncScrollOffset, addRef, viewKey } = props;

    const r = useRef<Animated.FlatList<any>>(null);

    const onMomentumScrollBegin = () => {
        isListGliding.current = true;
    };

    const onMomentumScrollEnd = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        isListGliding.current = false;
        syncScrollOffset(e);
    };

    const onScrollEndDrag = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        syncScrollOffset(e);
    };

    return (
        <Animated.FlatList
            {...fprops}
            scrollToOverflowEnabled={true}
            bounces={true}
            scrollEventThrottle={Env.isWeb ? 1 : 16}
            onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
                useNativeDriver: true,
            })}
            onMomentumScrollBegin={onMomentumScrollBegin}
            onScrollEndDrag={onScrollEndDrag}
            onMomentumScrollEnd={onMomentumScrollEnd}
            // ItemSeparatorComponent={() => <View style={{height: 10}} />}
            // ListHeaderComponent={() => <View style={{height: 10}} />}
            contentContainerStyle={{
                paddingTop: headerHeight + tabBarHeight + 10,
                // paddingBottom:100,
                // paddingHorizontal: 10,
                minHeight: windowHeight + tabBarHeight + headerHeight,
            }}
            showsHorizontalScrollIndicator={false}
            // data={data}
            // renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index.toString()}
            ref={(ref) => {
                if (ref) {
                    const found = listRefArr.current.find((e) => e.key === viewKey);
                    if (!found) {
                        listRefArr.current.push({
                            key: viewKey,
                            value: ref,
                        });
                    }
                }
            }}
        />
    );
};
