import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    buttonContainer: {
        width: '100%',
        height: 50,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    Button: {
        marginHorizontal: 10,
        flex: 1,
    },
    iconAndTextContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonText: {
        fontSize: 18,
    },
});
