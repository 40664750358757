import { Feather } from '@expo/vector-icons';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { ButtonGroup, FilterButton } from '../../components/FilterButton/FilterButton';

export const useFilterSection = () => {
    const render = () => {
        return (
            <View style={styles.wrapper}>
                <ButtonGroup>
                    <FilterButton grouped={true}>
                        <Feather name={'coffee'} size={15} />
                    </FilterButton>
                    <FilterButton grouped={true} active={true}>
                        <Feather name={'home'} size={15} />
                    </FilterButton>
                </ButtonGroup>
                <ScrollView style={{ flex: 1, paddingLeft: 20 }} horizontal={true} />
                <FilterButton style={styles.button}>Chinese</FilterButton>
                <FilterButton style={styles.button}>Japanese</FilterButton>
                <FilterButton style={styles.button}>Korea</FilterButton>
                <FilterButton style={styles.button}>Korea</FilterButton>
                <ScrollView />
            </View>
        );
    };

    return {
        render,
    };
};

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'row',
        marginVertical: 10,
        marginHorizontal: 5,
    },
    button: {
        marginLeft: 10,
    },
});
