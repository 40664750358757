import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RestaurantService } from 'gc-common';
import { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext';
import { RootStackParamList } from '../types';
import {EventLogger} from "../utils/EventLogger";

export const useCheckIn = (restaurantId?: string) => {
    const [isCheckIn, setIsCheckIn] = useState<boolean>(false);
    const [isCheckingIn, setIsCheckingIn] = useState<boolean>(true);

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const auth = useAuth();

    useEffect(() => {
        fetchCheckIn();
    }, [restaurantId]);

    const fetchCheckIn = async () => {
        if (!restaurantId || !auth.isLoggedIn) return setIsCheckingIn(false);
        let res = await RestaurantService.hasRestaurantCheckedIn(restaurantId);
        setIsCheckIn(res.data);
        setIsCheckingIn(false);
    };

    const onCheckIn = async () => {
        if (!restaurantId || !auth.isLoggedIn) {
            EventLogger.redirectToAuth('restaurant_checkin');
            navigation.push('SignInStack');
            return;
        }

        setIsCheckingIn(true);
        let res;
        if (isCheckIn) {
            res = await RestaurantService.restaurantUncheckIn(restaurantId);
        } else {
            EventLogger.clickRestaurantCheckIn(restaurantId);
            res = await RestaurantService.restaurantCheckIn(restaurantId);
        }
        if (!res?.errorMessage) setIsCheckIn(!isCheckIn);
        setIsCheckingIn(false);
    };

    // 这个函数是用来单独checkin或者uncheckin的, 只用在are you in的弹窗里.
    // 这个按键是不会改变isCheckIn的值的, 不会记录状态, 只是用来去调用api的.
    const onCheckSingle = async (restaurantId: string, type: boolean) => {
        // 这里的type用来表明是checkin还是uncheckin
        //true代表 checkin, false代表 uncheckin
        if (!restaurantId || !auth.isLoggedIn) {
            navigation.push('SignInStack');
            return false;
        }
        let res;
        try {
            if (type == true) {
                res = await RestaurantService.restaurantCheckIn(restaurantId);
                return !res?.errorMessage;
            } else if (type == false) {
                res = await RestaurantService.restaurantUncheckIn(restaurantId);
                return !res?.errorMessage;
            }
        } catch (e) {
            return false;
        }
    };

    return {
        isCheckIn,
        isCheckingIn,
        setIsCheckingIn,
        fetchCheckIn,
        onCheckIn,
        onCheckSingle,
    };
};
