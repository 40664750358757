import { User } from 'gc-common';
import { Env } from '../Env';
import { DdLogs } from '../webLibs/Datadog';

const serializeUser = (user?: User) => {
    return user
        ? { id: user.user_id, display_name: user.display_name, email: user.email }
        : undefined;
};
export const Logger = {
    user: undefined as undefined | User,
    log(message: string, context?: any) {
        console.log(message, context || '');

        if (Env.isNative)
            DdLogs.info(message, { log_ctx: context, user: serializeUser(this.user) });
    },

    error(message: string, context?: any) {
        console.error(message, context || '');

        if (Env.isNative)
            DdLogs.error(message, { log_ctx: context, user: serializeUser(this.user) });
    },

    debug(message: string, context?: any) {
        console.debug(message, context || '');

        if (Env.isNative)
            DdLogs.debug(message, { log_ctx: context, user: serializeUser(this.user) });
    },
};


