import React, { useEffect } from 'react';
import { ListRenderItem, View } from 'react-native';

import { FollowUser, UserService } from 'gc-common';
import { PaginationDataWithLoader, usePagination } from '../../hooks/usePagination';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { MeFollowItem } from './MeFollowItem';
import { styles } from './styles';

export interface MeFollowType {
    type: 'follower' | 'following';
    userId: string;
}

export const useFollow = ({ userId, type }: MeFollowType) => {
    const { data, page, isFetching, fetchData, ...pagination } = usePagination<FollowUser>(
        async (page, size) => {
            if (!userId) return [];
            const rsp =
                type == 'follower'
                    ? await UserService.getFollower(userId, page)
                    : await UserService.getFollowing(userId, page);
            return rsp.data;
        }
    );

    useEffect(() => {
        if (!userId) return;
        fetchData();
    }, [userId]);

    const renderItem: ListRenderItem<PaginationDataWithLoader<FollowUser>> = ({ item }) => {
        return (
            <>
                {!item.$isLoader ? (
                    <MeFollowItem item={item} type={type} userId={userId} />
                ) : (
                    <SkeletonPlaceholder>
                        <View style={styles.followWrapper}>
                            <View style={styles.followItemContainer}>
                                <View style={styles.followImageContainer}>
                                    <SkeletonPlaceholder.Item
                                        width={55}
                                        height={55}
                                        borderRadius={50}
                                    />
                                </View>
                                <View style={styles.followInfo}>
                                    <SkeletonPlaceholder.Item
                                        width={'100%'}
                                        height={55}
                                        borderRadius={10}
                                    />
                                </View>
                                <View style={styles.followButton}>
                                    <SkeletonPlaceholder.Item
                                        width={'100%'}
                                        height={55}
                                        borderRadius={30}
                                    />
                                </View>
                            </View>
                        </View>
                    </SkeletonPlaceholder>
                )}
            </>
        );
    };

    return {
        data,
        page,
        fetchData,
        isFetching,
        renderItem,
    };
};
