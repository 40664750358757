import { useMemo, useState } from 'react';

const createSelectionMap = <T extends string | number>(data: T[]) => {
    const obj: Record<T, boolean> = {} as any;
    data.map((i) => (obj[i] = false));
    return obj;
};

export const useFilterData = <T extends string | number>(data: T[]) => {
    const [selections, setSelections] = useState(createSelectionMap(data));

    const select = (key: T) => setSelections({ ...selections, [key]: true });
    const singleSelect = (key: T) => setSelections({ ...createSelectionMap(data), [key]: true });
    const unselect = (key: T) => setSelections({ ...selections, [key]: false });
    const set = (key: T, selected: boolean) => setSelections({ ...selections, [key]: selected });
    const toggle = (key: T) => setSelections({ ...selections, [key]: !selections[key] });
    const reset = () => setSelections(createSelectionMap(data));

    const getSelections = useMemo(() => {
        return data.map((i) => {
            return { key: i, isSelected: selections[i] };
        });
    }, [selections]);

    const isFiltering = useMemo(() => {
        for (let i of Object.values(selections)) {
            if (i) return true;
        }

        return false;
    }, [selections]);

    return {
        data,
        _selections: selections,
        selections: getSelections,
        singleSelect,
        select,
        unselect,
        toggle,
        set,
        isFiltering,
        reset,
    };
};
