// EulaScreen.js

import React from 'react';
import { ScrollView, StyleSheet, Text } from 'react-native';

export const EulaScreen = () => {
    return (
        <ScrollView contentContainerStyle={styles.container}>
            <Text style={styles.title}>End-User License Agreement (EULA)</Text>
            <Text style={styles.subtitle}>Last Updated: September 13, 2023</Text>

            <Text style={styles.paragraph}>
                PLEASE READ THIS END-USER LICENSE AGREEMENT (EULA) CAREFULLY BEFORE USING THE
                GOURMETCARTE APPLICATION. BY DOWNLOADING, INSTALLING, OR USING THE GOURMETCARTE
                APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH HEREIN.
            </Text>

            <Text style={styles.sectionTitle}>1. Definitions</Text>
            <Text style={styles.paragraph}>
                1.1 "GourmetCarte" refers to the software application, including any updates or
                enhancements, provided by GourmetCarte Inc for the purpose of allowing users to
                create and share Dish Reviews, similar to Yelp.
            </Text>
            <Text style={styles.paragraph}>
                1.2 "User" refers to an individual or entity who has downloaded, installed, or is
                using the GourmetCarte application.
            </Text>
            <Text style={styles.paragraph}>
                1.3 "Dish Reviews" refer to user-generated content, such as text, images, and
                ratings, related to food dishes and restaurant experiences.
            </Text>

            <Text style={styles.sectionTitle}>2. License Grant</Text>
            <Text style={styles.paragraph}>
                2.1 GourmetCarte Inc grants the User a limited, non-exclusive, non-transferable,
                revocable license to use the GourmetCarte application solely for personal,
                non-commercial purposes.
            </Text>
            <Text style={styles.paragraph}>
                2.2 The User may not sublicense, distribute, rent, lease, or otherwise transfer the
                GourmetCarte application to any third party.
            </Text>

            <Text style={styles.sectionTitle}>3. User Content</Text>
            <Text style={styles.paragraph}>
                3.1 GourmetCarte allows Users to create and share Dish Reviews. Users are solely
                responsible for the content they post.
            </Text>
            <Text style={styles.paragraph}>
                3.2 By posting content on GourmetCarte, Users grant GourmetCarte Inc a worldwide,
                royalty-free, perpetual, irrevocable, non-exclusive license to use, modify,
                reproduce, distribute, display, and publish the content for the purposes of
                operating, promoting, and improving GourmetCarte.
            </Text>
            <Text style={styles.paragraph}>
                3.3 Users may not post objectionable, unlawful, defamatory, infringing on
                intellectual property rights, or otherwise abusive content. GourmetCarte Inc has a
                zero-tolerance policy for such content and reserves the right to remove or disable
                access to any content that violates these terms. Abusive users may also have their
                accounts terminated.
            </Text>

            <Text style={styles.sectionTitle}>4. Privacy and Data Collection</Text>
            <Text style={styles.paragraph}>
                4.1 GourmetCarte may collect and store User data, including but not limited to
                location information and usage data. GourmetCarte Inc will use this data in
                accordance with its Privacy Policy, which can be found at [Link to Privacy Policy].
            </Text>

            <Text style={styles.sectionTitle}>5. Updates and Maintenance</Text>
            <Text style={styles.paragraph}>
                5.1 GourmetCarte Inc may, from time to time, provide updates, bug fixes, or
                maintenance for the GourmetCarte application. Users are encouraged to keep their
                application up-to-date.
            </Text>

            <Text style={styles.sectionTitle}>6. Termination</Text>
            <Text style={styles.paragraph}>
                6.1 This EULA is effective until terminated by either the User or GourmetCarte Inc.
                The User may terminate this EULA by uninstalling the GourmetCarte application and
                ceasing all use.
            </Text>
            <Text style={styles.paragraph}>
                6.2 GourmetCarte Inc may terminate this EULA at any time, with or without cause. In
                the event of termination, the User must cease using the GourmetCarte application
                immediately.
            </Text>

            <Text style={styles.sectionTitle}>7. Warranty Disclaimer</Text>
            <Text style={styles.paragraph}>
                7.1 The GourmetCarte application is provided "as-is" and without warranty of any
                kind, either expressed or implied, including, but not limited to, the implied
                warranties of merchantability, fitness for a particular purpose, or
                non-infringement.
            </Text>
            <Text style={styles.paragraph}>
                7.2 GourmetCarte Inc does not warrant that the GourmetCarte application will meet
                the User's requirements, be error-free, or operate without interruption.
            </Text>

            <Text style={styles.sectionTitle}>8. Limitation of Liability</Text>
            <Text style={styles.paragraph}>
                8.1 GourmetCarte Inc shall not be liable for any direct, indirect, incidental,
                special, or consequential damages arising out of or in connection with the use or
                inability to use the GourmetCarte application.
            </Text>

            <Text style={styles.sectionTitle}>9. Governing Law</Text>
            <Text style={styles.paragraph}>
                9.1 This EULA is governed by and construed in accordance with the laws of [Your
                Jurisdiction].
            </Text>

            <Text style={styles.sectionTitle}>10. Contact Information</Text>
            <Text style={styles.paragraph}>
                10.1 For questions, concerns, or inquiries regarding this EULA, please contact
                GourmetCarte Inc at [Contact Email].
            </Text>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 10,
    },
    paragraph: {
        fontSize: 14,
        marginBottom: 15,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 10,
    },
    // Define styles for other sections as needed
});
