import { Env } from '../Env';
import { analytics } from '../webLibs/Firebase/firebase';

export const EventLogger = {
    async logEvent(event: string, context?: any) {
        if (Env.isNative) {
        }
        await analytics().logEvent(event, context);
    },
    async clickOpenInApp(pageName?: string) {
        await this.logEvent('click_open_in_app', { pageName });
    },
    async redirectToAuth(from?: string) {
        await this.logEvent('redirect_to_auth', { from });
    },
    async viewRestaurant(restaurantId: string) {
        await this.logEvent('view_restaurant', { id: restaurantId });
    },
    async clickRestaurantLike(restaurantId: string) {
        await this.logEvent('click_restaurant_like', { id: restaurantId });
    },
    async clickRestaurantCollect(restaurantId: string) {
        await this.logEvent('click_restaurant_collect', { id: restaurantId });
    },
    async clickRestaurantShare(restaurantId: string) {
        await this.logEvent('click_restaurant_share', { id: restaurantId });
    },
    async clickRestaurantCheckIn(restaurantId: string, isFromHome = false) {
        await this.logEvent('click_restaurant_check_in', { id: restaurantId, isFromHome });
    },
    async viewMenu(restaurantId: string) {
        await this.logEvent('view_menu', { id: restaurantId });
    },
    async clickMenuCategory(restaurantId: string, category: string) {
        await this.logEvent('view_menu_category', { id: restaurantId, category });
    },
    async viewDish(dishId: string) {
        await this.logEvent('view_dish', { id: dishId });
    },
    async clickDishLike(dishId: string) {
        await this.logEvent('click_dish_like', { id: dishId });
    },
    async clickDishCollect(dishId: string) {
        await this.logEvent('click_dish_collect', { id: dishId });
    },
    async clickDishShare(dishId: string) {
        await this.logEvent('click_dish_share', { id: dishId });
    },
};
