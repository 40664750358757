import React, { useEffect, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';
import { RootTabScreenProps } from '../../types';
import { HomePostView } from './HomePostView';
import { HomeRestaurantView } from './HomeRestaurantView';
import { useLocationDetector } from './useLocationDetector';

export const HomeScreen = ({ route, navigation }: RootTabScreenProps<'Home'>) => {
    const locationDetector = useLocationDetector();
    const [currentViewIndex, setCurrentViewIndex] = useState(0);

    useEffect(() => {
        DeviceEventEmitter.addListener('home-switch-view', (i) => setCurrentViewIndex(i));
        return () => {
            DeviceEventEmitter.removeAllListeners('home-switch-view');
        };
    });

    return (
        <>
            {currentViewIndex === 0 && <HomePostView setCurrentView={setCurrentViewIndex} />}
            {currentViewIndex === 1 && <HomeRestaurantView setCurrentView={setCurrentViewIndex} />}

            {locationDetector.render()}
        </>
    );
};
