import React from 'react';
import { Linking, ScrollView, Text, View } from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { styles } from './styles';

export function PrivacyPolicyScreen() {
    const handleBack = () => {
        console.log('click back');
    };
    return (
        <>
            {/*main screen*/}
            <ScrollView>
                <View style={styles.screenContainer}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>Privacy Policy</Text>
                        <Text style={styles.subTitle}>Last updated March 18, 2022</Text>
                    </View>
                    <Text style={styles.content}>
                        This privacy notice for Gourmetcarte ("Company." "we," "us," or "our"),
                        describes how and why we might collect, store, use, and/or share ("process")
                        your information when you use our services ("Services"), such as when you:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Visit our website {``}
                            <Text
                                style={styles.hyperlinkStyle}
                                onPress={() => {
                                    Linking.openURL('https://www.gourmetcarte.com');
                                }}
                            >
                                https://www.gourmetcarte.com
                            </Text>
                            , or any website of ours that links to this privacy notice.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Engage with us in other related ways - including any sales, marketing,
                            or events.
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> Questions or concerns? </Text>
                        Reading this privacy notice will help you understand your privacy rights and
                        choices. If you do not agree with our policies and practices, please do not
                        use our Services. If you still have any questions or concerns, please
                        contact us at {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                        .
                    </Text>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'1.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            WHAT INFORMATION DO WE COLLECT?
                        </Text>
                    </View>
                    <Text style={styles.customH2Style}>
                        Personal information you disclose to us.
                    </Text>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We collect personal information that you provide to us.
                    </Text>
                    <Text style={styles.content}>
                        We collect personal information that you voluntarily provide to us when you
                        register on the Services, express an interest in obtaining information about
                        us or our products and Services, when you participate in activities on the
                        Services, or otherwise when you contact us.
                    </Text>
                    <Text style={styles.customH2Style}>Personal Information Provided by You.</Text>
                    <Text style={styles.content}>
                        The personal information that we collect depends on the context of your
                        interactions with us and the Services, the choices you make, and the
                        products and features you use. The personal information we collect may
                        include the following:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>names</Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>email addresses</Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>usernames</Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>passwords</Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> Sensitive Information. </Text>
                        When necessary, with your consent or as otherwise permitted by applicable
                        law, we process the following categories of sensitive information:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            information revealing race or ethnic origin
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> Social Media Login Data. </Text>
                        We may provide you with the option to register with us using your existing
                        social media account details, like your Facebook, Twitter, or other social
                        media account. If you choose to register in this way, we will collect the
                        information described in the section called "HOW DO WE HANDLE YOUR SOCIAL
                        LOGINS?" below.
                    </Text>
                    <Text style={styles.content}>
                        All personal information that you provide to us must be true, complete, and
                        accurate, and you must notify us of any changes to such personal
                        information.
                    </Text>
                    <Text style={styles.customH2Style}>Information automatically collected</Text>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        Some information — such as your Internet Protocol (IP) address and/or
                        browser and device characteristics — is collected automatically when you
                        visit our Services.
                    </Text>
                    <Text style={styles.content}>
                        We automatically collect certain information when you visit, use, or
                        navigate the Services. This information does not reveal your specific
                        identity (like your name or contact information) but may include device and
                        usage information, such as your IP address, browser and device
                        characteristics, operating system, language preferences, referring URLs,
                        device name, country, location, information about how and when you use our
                        Services, and other technical information. This information is primarily
                        needed to maintain the security and operation of our Services, and for our
                        internal analytics and reporting purposes.
                    </Text>
                    <Text style={styles.content}>
                        Like many businesses, we also collect information through cookies and
                        similar technologies.
                    </Text>
                    <Text style={styles.content}>The information we collect includes:</Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Log and Usage Data. Log and usage data is service-related, diagnostic,
                            usage, and performance information our servers automatically collect
                            when you access or use our Services and which we record in log files.
                            Depending on how you interact with us, this log data may include your IP
                            address, device information, browser type, and settings and information
                            about your activity in the Services (such as the date/time stamps
                            associated with your usage, pages and files viewed, searches, and other
                            actions you take such as which features you use), device event
                            information (such as system activity, error reports (sometimes called
                            "crash dumps"), and hardware settings).
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Device Data. We collect device data such as information about your
                            computer, phone, tablet, or other device you use to access the Services.
                            Depending on the device used, this device data may include information
                            such as your IP address (or proxy server), device and application
                            identification numbers, location, browser type, hardware model, Internet
                            service provider and/or mobile carrier, operating system, and system
                            configuration information.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Location Data. We collect location data such as information about your
                            device's location, which can be either precise or imprecise. How much
                            information we collect depends on the type and settings of the device
                            you use to access the Services. For example, we may use GPS and other
                            technologies to collect geolocation data that tells us your current
                            location (based on your IP address). You can opt out of allowing us to
                            collect this information either by refusing access to the information or
                            by disabling your Location setting on your device. However, if you
                            choose to opt out, you may not be able to use certain aspects of the
                            Services.
                        </Text>
                    </View>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'2.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            HOW DO WE PROCESS YOUR INFORMATION?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and to
                        comply with law. We may also process your information for other purposes
                        with your consent.
                    </Text>
                    <Text style={styles.content}>
                        We process your personal information for a variety of reasons, depending on
                        how you interact with our Services, including:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            To facilitate account creation and authentication and otherwise manage
                            user accounts. We may process your information so you can create and log
                            in to your account, as well as keep your account in working order.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            To request feedback. We may process your information when necessary to
                            request feedback and to contact you about your use of our Services.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            To send you marketing and promotional communications. We may process the
                            personal information you send to us for our marketing purposes, if this
                            is in accordance with your marketing preferences. You can opt out of our
                            marketing emails at any time. For more information, see "WHAT ARE YOUR
                            PRIVACY RIGHTS?" below).
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            To identify usage trends. We may process information about how you use
                            our Services to better understand how they are being used so we can
                            improve them.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            To save or protect an individual's vital interest. We may process your
                            information when necessary to save or protect an individual’s vital
                            interest, such as to prevent harm.
                        </Text>
                    </View>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'3.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We only process your personal information when we believe it is necessary
                        and we have a valid legal reason (i.e., legal basis) to do so under
                        applicable law, like with your consent, to comply with laws, to provide you
                        with services to enter into or fulfill our contractual obligations, to
                        protect your rights, or to fulfill our legitimate business interests.
                    </Text>
                    <Text style={styles.customH2Style}>
                        If you are located in the EU or UK, this section applies to you.
                    </Text>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        The General Data Protection Regulation (GDPR) and UK GDPR require us to
                        explain the valid legal bases we rely on in order to process your personal
                        information. As such, we may rely on the following legal bases to process
                        your personal information:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Consent. We may process your information if you have given us permission
                            (i.e., consent) to use your personal information for a specific purpose.
                            You can withdraw your consent at any time. Click here to learn more.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Legitimate Interests. We may process your information when we believe it
                            is reasonably necessary to achieve our legitimate business interests and
                            those interests do not outweigh your interests and fundamental rights
                            and freedoms. For example, we may process your personal information for
                            some of the purposes described in order to:
                        </Text>
                    </View>
                    <View style={[styles.bulletListContainer, { marginLeft: 30 }]}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Send users information about special offers and discounts on our
                            products and services.
                        </Text>
                    </View>
                    <View style={[styles.bulletListContainer, { marginLeft: 30 }]}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Analyze how our services are used so we can improve them to engage and
                            retain users.
                        </Text>
                    </View>
                    <View style={[styles.bulletListContainer, { marginLeft: 30 }]}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Understand how our users use our products and services so we can improve
                            user experience.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Legal Obligations. We may process your information where we believe it
                            is necessary for compliance with our legal obligations, such as to
                            cooperate with a law enforcement body or regulatory agency, exercise or
                            defend our legal rights, or disclose your information as evidence in
                            litigation in which we are involved.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Vital Interests. We may process your information where we believe it is
                            necessary to protect your vital interests or the vital interests of a
                            third party, such as situations involving potential threats to the
                            safety of any person.
                        </Text>
                    </View>
                    <Text style={styles.customH2Style}>
                        If you are located in Canada, this section applies to you.
                    </Text>
                    <Text style={styles.content}>
                        We may process your information if you have given us specific permission
                        (i.e., express consent) to use your personal information for a specific
                        purpose, or in situations where your permission can be inferred (i.e.,
                        implied consent). You can withdraw your consent at any time.
                    </Text>

                    <Text style={styles.content}>
                        In some exceptional cases, we may be legally permitted under applicable law
                        to process your information without your consent, including, for example:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If collection is clearly in the interests of an individual and consent
                            cannot be obtained in a timely way
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            For investigations and fraud detection and prevention
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            For business transactions provided certain conditions are met
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If it is contained in a witness statement and the collection is
                            necessary to assess, process, or settle an insurance claim
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            For identifying injured, ill, or deceased persons and communicating with
                            next of kin
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If we have reasonable grounds to believe an individual has been, is, or
                            may be victim of financial abuse
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If it is reasonable to expect collection and use with consent would
                            compromise the availability or the accuracy of the information and the
                            collection is reasonable for purposes related to investigating a breach
                            of an agreement or a contravention of the laws of Canada or a province
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If disclosure is required to comply with a subpoena, warrant, court
                            order, or rules of the court relating to the production of records
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If it was produced by an individual in the course of their employment,
                            business, or profession and the collection is consistent with the
                            purposes for which the information was produced
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If the collection is solely for journalistic, artistic, or literary
                            purposes
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            If the information is publicly available and is specified by the
                            regulations
                        </Text>
                    </View>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'4.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{' '}
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We may share information in specific situations described in this section
                        and/or with the following third parties.
                    </Text>
                    <Text style={styles.content}>
                        We may need to share your personal information in the following situations:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Business Transfers. We may share or transfer your information in
                            connection with, or during negotiations of, any merger, sale of company
                            assets, financing, or acquisition of all or a portion of our business to
                            another company.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            When we use Google Maps Platform APIs. We may share your information
                            with certain Google Maps Platform APIs (e.g., Google Maps API, Places
                            API). To find out more about Google’s Privacy Policy, please refer to
                            this link. We obtain and store on your device ('cache') your location.
                            You may revoke your consent anytime by contacting us at the contact
                            details provided at the end of this document.
                        </Text>
                    </View>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'5.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{' '}
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We may use cookies and other tracking technologies to collect and store your
                        information.
                    </Text>
                    <Text style={styles.content}>
                        We may use cookies and similar tracking technologies (like web beacons and
                        pixels) to access or store information. Specific information about how we
                        use such technologies and how you can refuse certain cookies is set out in
                        our Cookie Notice.
                    </Text>

                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'6.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        If you choose to register or log in to our services using a social media
                        account, we may have access to certain information about you.
                    </Text>
                    <Text style={styles.content}>
                        Our Services offer you the ability to register and log in using your
                        third-party social media account details (like your Facebook or Twitter
                        logins). Where you choose to do this, we will receive certain profile
                        information about you from your social media provider. The profile
                        information we receive may vary depending on the social media provider
                        concerned, but will often include your name, email address, friends list,
                        and profile picture, as well as other information you choose to make public
                        on such a social media platform.
                    </Text>
                    <Text style={styles.content}>
                        We will use the information we receive only for the purposes that are
                        described in this privacy notice or that are otherwise made clear to you on
                        the relevant Services. Please note that we do not control, and are not
                        responsible for, other uses of your personal information by your third-party
                        social media provider. We recommend that you review their privacy notice to
                        understand how they collect, use and share your personal information, and
                        how you can set your privacy preferences on their sites and apps.
                    </Text>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'7.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We keep your information for as long as necessary to fulfill the purposes
                        outlined in this privacy notice unless otherwise required by law.
                    </Text>
                    <Text style={styles.content}>
                        We will only keep your personal information for as long as it is necessary
                        for the purposes set out in this privacy notice, unless a longer retention
                        period is required or permitted by law (such as tax, accounting, or other
                        legal requirements). No purpose in this notice will require us keeping your
                        personal information for longer than the period of time in which users have
                        an account with us.
                    </Text>
                    <Text style={styles.content}>
                        When we have no ongoing legitimate business need to process your personal
                        information, we will either delete or anonymize such information, or, if
                        this is not possible (for example, because your personal information has
                        been stored in backup archives), then we will securely store your personal
                        information and isolate it from any further processing until deletion is
                        possible.
                    </Text>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'8.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        We aim to protect your personal information through a system of
                        organizational and technical security measures.
                    </Text>
                    <Text style={styles.content}>
                        We have implemented appropriate and reasonable technical and organizational
                        security measures designed to protect the security of any personal
                        information we process. However, despite our safeguards and efforts to
                        secure your information, no electronic transmission over the Internet or
                        information storage technology can be guaranteed to be 100% secure, so we
                        cannot promise or guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat our security and
                        improperly collect, access, steal, or modify your information. Although we
                        will do our best to protect your personal information, transmission of
                        personal information to and from our Services is at your own risk. You
                        should only access the Services within a secure environment.
                    </Text>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'9.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            WHAT ARE YOUR PRIVACY RIGHTS?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        In some regions, such as the European Economic Area (EEA), United Kingdom
                        (UK), and Canada, you have rights that allow you greater access to and
                        control over your personal information. You may review, change, or terminate
                        your account at any time.
                    </Text>
                    <Text style={styles.content}>
                        In some regions (like the EEA, UK, and Canada), you have certain rights
                        under applicable data protection laws. These may include the right (i) to
                        request access and obtain a copy of your personal information, (ii) to
                        request rectification or erasure; (iii) to restrict the processing of your
                        personal information; and (iv) if applicable, to data portability. In
                        certain circumstances, you may also have the right to object to the
                        processing of your personal information. You can make such a request by
                        contacting us by using the contact details provided in the section “HOW CAN
                        YOU CONTACT US ABOUT THIS NOTICE?” below.
                    </Text>
                    <Text style={styles.content}>
                        We will consider and act upon any request in accordance with applicable data
                        protection laws.
                    </Text>
                    <Text style={styles.content}>
                        If you are located in the EEA or UK and you believe we are unlawfully
                        processing your personal information, you also have the right to complain to
                        your local data protection supervisory authority. You can find their contact
                        details here: {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL(
                                    'https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'
                                );
                            }}
                        >
                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                        </Text>
                        .
                    </Text>
                    <Text style={styles.content}>
                        If you are located in Switzerland, the contact details for the data
                        protection authorities are available here: {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('https://www.edoeb.admin.ch/edoeb/en/home.html');
                            }}
                        >
                            https://www.edoeb.admin.ch/edoeb/en/home.html
                        </Text>
                        .
                    </Text>
                    <Text style={styles.content}>
                        Withdrawing your consent: If we are relying on your consent to process your
                        personal information, which may be express and/or implied consent depending
                        on the applicable law, you have the right to withdraw your consent at any
                        time. You can withdraw your consent at any time by contacting us by using
                        the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT
                        THIS NOTICE?" below.
                    </Text>
                    <Text style={styles.content}>
                        However, please note that this will not affect the lawfulness of the
                        processing before its withdrawal, nor when applicable law allows, will it
                        affect the processing of your personal information conducted in reliance on
                        lawful processing grounds other than consent.
                    </Text>
                    <Text style={styles.content}>
                        Opting out of marketing and promotional communications: You can unsubscribe
                        from our marketing and promotional communications at any time by clicking on
                        the unsubscribe link in the emails that we send, or by contacting us using
                        the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
                        NOTICE?" below. You will then be removed from the marketing lists — however,
                        we may still communicate with you, for example to send you service-related
                        messages that are necessary for the administration and use of your account,
                        to respond to service requests, or for other non-marketing purposes.
                    </Text>
                    <Text style={styles.customH2Style}>Account Information</Text>
                    <Text style={styles.content}>
                        If you would at any time like to review or change the information in your
                        account or terminate your account, you can:
                    </Text>
                    <Text style={styles.content}>
                        Log in to your account settings and update your user account.
                    </Text>
                    <Text style={styles.content}>
                        Upon your request to terminate your account, we will deactivate or delete
                        your account and information from our active databases. However, we may
                        retain some information in our files to prevent fraud, troubleshoot
                        problems, assist with any investigations, enforce our legal terms and/or
                        comply with applicable legal requirements.
                    </Text>
                    <Text style={styles.content}>
                        Cookies and similar technologies: Most Web browsers are set to accept
                        cookies by default. If you prefer, you can usually choose to set your
                        browser to remove cookies and to reject cookies. If you choose to remove
                        cookies or reject cookies, this could affect certain features or services of
                        our Services. To opt out of interest-based advertising by advertisers on our
                        Services visit {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('http://www.aboutads.info/choices/');
                            }}
                        >
                            http://www.aboutads.info/choices/
                        </Text>
                    </Text>
                    <Text style={styles.content}>
                        If you have questions or comments about your privacy rights, you may email
                        us at {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                        .
                    </Text>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'10.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            CONTROLS FOR DO-NOT-TRACK FEATURES
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        Most web browsers and some mobile operating systems and mobile applications
                        include a Do-Not-Track ("DNT") feature or setting you can activate to signal
                        your privacy preference not to have data about your online browsing
                        activities monitored and collected. At this stage no uniform technology
                        standard for recognizing and implementing DNT signals has been finalized. As
                        such, we do not currently respond to DNT browser signals or any other
                        mechanism that automatically communicates your choice not to be tracked
                        online. If a standard for online tracking is adopted that we must follow in
                        the future, we will inform you about that practice in a revised version of
                        this privacy notice.
                    </Text>
                    <View style={styles.numberListContainer}>
                        <Text style={styles.numberListNumberStyle}>{'11.'}</Text>
                        <Text style={styles.numberListContentStyle}>
                            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        Yes, if you are a resident of California, you are granted specific rights
                        regarding access to your personal information.
                    </Text>
                    <Text style={styles.content}>
                        California Civil Code Section 1798.83, also known as the "Shine The Light"
                        law, permits our users who are California residents to request and obtain
                        from us, once a year and free of charge, information about categories of
                        personal information (if any) we disclosed to third parties for direct
                        marketing purposes and the names and addresses of all third parties with
                        which we shared personal information in the immediately preceding calendar
                        year. If you are a California resident and would like to make such a
                        request, please submit your request in writing to us using the contact
                        information provided below.
                    </Text>
                    <Text style={styles.content}>
                        If you are under 18 years of age, reside in California, and have a
                        registered account with Services, you have the right to request removal of
                        unwanted data that you publicly post on the Services. To request removal of
                        such data, please contact us using the contact information provided below
                        and include the email address associated with your account and a statement
                        that you reside in California. We will make sure the data is not publicly
                        displayed on the Services, but please be aware that the data may not be
                        completely or comprehensively removed from all our systems (e.g., backups,
                        etc.).
                    </Text>
                    <Text style={styles.customH2Style}>CCPA Privacy Notice</Text>
                    <Text style={styles.content}>
                        The California Code of Regulations defines a "resident" as:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={{ fontFamily: 'eina01', lineHeight: 15, fontSize: 12 }}>
                            (1)
                        </Text>
                        <Text style={styles.bulletListContentStyle}>
                            every individual who is in the State of California for other than a
                            temporary or transitory purpose and
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={{ fontFamily: 'eina01', lineHeight: 15, fontSize: 12 }}>
                            (2)
                        </Text>
                        <Text
                            style={{
                                paddingLeft: 10,
                                fontSize: 12,
                                fontFamily: 'eina01',
                                lineHeight: 15,
                            }}
                        >
                            every individual who is domiciled in the State of California who is
                            outside the State of California for a temporary or transitory purpose
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        All other individuals are defined as "non-residents." If this definition of
                        "resident" applies to you, we must adhere to certain rights and obligations
                        regarding your personal information.
                    </Text>
                    <Text style={styles.content}>
                        What categories of personal information do we collect? We have collected the
                        following categories of personal information in the past twelve (12) months:
                    </Text>
                    <Grid>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>Category</Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 1 }]}>
                                <Text style={styles.gridContent}>Examples</Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>Collected</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>A. Identifiers</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Contact details, auch as real name, alias, postal address,
                                    telephone ormobile contact number, unique personal identifier,
                                    online identifier, Internet Protocol address, email address, and
                                    account name
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>A. Identifiers</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Contact details, auch as real name, alias, postal address,
                                    telephone ormobile contact number, unique personal identifier,
                                    online identifier, Internet Protocol address, email address, and
                                    account name
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>A. Identifiers</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Contact details, auch as real name, alias, postal address,
                                    telephone ormobile contact number, unique personal identifier,
                                    online identifier, Internet Protocol address, email address, and
                                    account name
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>
                                    B. Personal information categories listed in the California
                                    Customer Records statute
                                </Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Name, contact information, eduction, employment, employment
                                    history, and financial information
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>
                                    C. Protected classification characteristics under California or
                                    federal law
                                </Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>Gender and date of birth</Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>D. Commercial Information</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Transaction information, purchase history, financial details,
                                    and payment information
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>NO</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>E. Biometric information</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>Fingerprints and voiceprints</Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>NO</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>
                                    F. Internet or other similar network activity
                                </Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Browsing history, search history, online behavior, interest
                                    data, and interactions with our and other websites,
                                    applicalions, systems, and advertisements
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>G. Geolocation data</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>Device location</Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>
                                    H. Audio, electronic, visual, thermal, olfactory or similar
                                    information
                                </Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Images and audio, video or call recordings created in connection
                                    with our business activities
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>NO</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>
                                    I. Professions or employment-related information
                                </Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Business contact details in order to provide you our services at
                                    a business level or job title, work history, and professional
                                    qualifications if you apply for a job with us
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>NO</Text>
                            </Col>
                        </Row>
                        <Row style={styles.rowsStyle}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>J. Education Information</Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Student records and directory information
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>NO</Text>
                            </Col>
                        </Row>
                        <Row style={[styles.rowsStyle, { borderBottomWidth: 0.5 }]}>
                            <Col style={[styles.columnStyle, { flex: 0.5 }]}>
                                <Text style={styles.gridContent}>
                                    K. Inference drawn from other personal information
                                </Text>
                            </Col>
                            <Col style={styles.columnStyle}>
                                <Text style={styles.gridContent}>
                                    Inference drawn from any of the collected personal information
                                    listed above to create a profile or summary about, for example,
                                    an individual’s preferences and characteristics
                                </Text>
                            </Col>
                            <Col style={[styles.columnStyle, { flex: 0.5, borderRightWidth: 0.5 }]}>
                                <Text style={styles.gridContent}>YES</Text>
                            </Col>
                        </Row>
                    </Grid>
                    <Text style={styles.content}>
                        We may also collect other personal information outside of these categories
                        instances where you interact with us in person, online, or by phone or mail
                        in the context of:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Receiving help through our customer support channels;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Participation in customer surveys or contests; and
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            Facilitation in the delivery of our Services and to respond to your
                            inquiries.
                        </Text>
                    </View>
                    <Text style={styles.customH2Style}>
                        How do we use and share your personal information?
                    </Text>
                    <Text style={styles.content}>
                        More information about our data collection and sharing practices can be
                        found in this privacy notice.
                    </Text>
                    <Text style={styles.content}>
                        You may contact us by email at {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                        , or by referring to the contact details at the bottom of this document.
                    </Text>
                    <Text style={styles.content}>
                        If you are using an authorized agent to exercise your right to opt out we
                        may deny a request if the authorized agent does not submit proof that they
                        have been validly authorized to act on your behalf.
                    </Text>
                    <Text style={styles.customH2Style}>
                        Will your information be shared with anyone else?
                    </Text>
                    <Text style={styles.content}>
                        We may disclose your personal information with our service providers
                        pursuant to a written contract between us and each service provider. Each
                        service provider is a for-profit entity that processes the information on
                        our behalf.
                    </Text>
                    <Text style={styles.content}>
                        We may use your personal information for our own business purposes, such as
                        for undertaking internal research for technological development and
                        demonstration. This is not considered to be "selling" your personal
                        information.
                    </Text>
                    <Text style={styles.content}>
                        Gourmetcarte has not disclosed or sold any personal information to third
                        parties for a business or commercial purpose in the preceding twelve (12)
                        months. Gourmetcarte will not sell personal information in the future
                        belonging to website visitors, users, and other consumers.
                    </Text>
                    <Text style={styles.customH2Style}>
                        Your rights with respect to your personal data
                    </Text>
                    <Text style={styles.content}>
                        Right to request deletion of the data — Request to delete
                    </Text>
                    <Text style={styles.content}>
                        You can ask for the deletion of your personal information. If you ask us to
                        delete your personal information, we will respect your request and delete
                        your personal information, subject to certain exceptions provided by law,
                        such as (but not limited to) the exercise by another consumer of his or her
                        right to free speech, our compliance requirements resulting from a legal
                        obligation, or any processing that may be required to protect against
                        illegal activities.
                    </Text>
                    <Text style={styles.content}>Right to be informed — Request to know</Text>
                    <Text style={styles.content}>
                        Depending on the circumstances, you have a right to know:
                    </Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            whether we collect and use your personal information;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            the categories of personal information that we collect;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            the purposes for which the collected personal information is used;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            whether we sell your personal information to third parties;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            the categories of personal information that we sold or disclosed for a
                            business purpose;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            the categories of third parties to whom the personal information was
                            sold or disclosed for a business purpose;
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            the business or commercial purpose for collecting or selling personal
                            information.
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        In accordance with applicable law, we are not obligated to provide or delete
                        consumer information that is de-identified in response to a consumer request
                        or to re-identify individual data to verify a consumer request.
                    </Text>
                    <Text style={styles.content}>
                        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                    </Text>
                    <Text style={styles.content}>
                        We will not discriminate against you if you exercise your privacy rights.
                    </Text>
                    <Text style={styles.content}>Verification process</Text>
                    <Text style={styles.content}>
                        Upon receiving your request, we will need to verify your identity to
                        determine you are the same person about whom we have the information in our
                        system. These verification efforts require us to ask you to provide
                        information so that we can match it with information you have previously
                        provided us. For instance, depending on the type of request you submit, we
                        may ask you to provide certain information so that we can match the
                        information you provide with the information we already have on file, or we
                        may contact you through a communication method (e.g., phone or email) that
                        you have previously provided to us. We may also use other verification
                        methods as the circumstances dictate.
                    </Text>
                    <Text style={styles.content}>
                        We will only use personal information provided in your request to verify
                        your identity or authority to make the request. To the extent possible, we
                        will avoid requesting additional information from you for the purposes of
                        verification. However, if we cannot verify your identity from the
                        information already maintained by us, we may request that you provide
                        additional information for the purposes of verifying your identity and for
                        security or fraud-prevention purposes. We will delete such additionally
                        provided information as soon as we finish verifying you.
                    </Text>
                    <Text style={styles.content}>Other privacy rights</Text>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            You may object to the processing of your personal information.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            You may request correction of your personal data if it is incorrect or
                            no longer relevant, or ask to restrict the processing of the
                            information.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            You can designate an authorized agent to make a request under the CCPA
                            on your behalf. We may deny a request from an authorized agent that does
                            not submit proof that they have been validly authorized to act on your
                            behalf in accordance with the CCPA.
                        </Text>
                    </View>
                    <View style={styles.bulletListContainer}>
                        <Text style={styles.bulletStyle}>{'\u2022'}</Text>
                        <Text style={styles.bulletListContentStyle}>
                            You may request to opt out from future selling of your personal
                            information to third parties. Upon receiving an opt-out request, we will
                            act upon the request as soon as feasibly possible, but no later than
                            fifteen (15) days from the date of the request submission.
                        </Text>
                    </View>
                    <Text style={styles.content}>
                        To exercise these rights, you can contact us by email at {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                        , or by referring to the contact details at the bottom of this document. If
                        you have a complaint about how we handle your data, we would like to hear
                        from you.
                    </Text>
                    <Text style={styles.customH2Style}>12. DO WE MAKE UPDATES TO THIS NOTICE?</Text>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        Yes, we will update this notice as necessary to stay compliant with relevant
                        laws.
                    </Text>
                    <Text style={styles.content}>
                        We may update this privacy notice from time to time. The updated version
                        will be indicated by an updated "Revised" date and the updated version will
                        be effective as soon as it is accessible. If we make material changes to
                        this privacy notice, we may notify you either by prominently posting a
                        notice of such changes or by directly sending you a notification. We
                        encourage you to review this privacy notice frequently to be informed of how
                        we are protecting your information.
                    </Text>

                    <Text style={styles.customH2Style}>
                        13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </Text>
                    <Text style={styles.content}>
                        <Text style={styles.contentBold}> In Short: </Text>
                        If you have questions or comments about this notice, you may email us at{' '}
                        {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                        .
                    </Text>
                    <Text style={styles.customH2Style}>
                        14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </Text>
                    <Text style={styles.content}>
                        Based on the applicable laws of your country, you may have the right to
                        request access to the personal information we collect from you, change that
                        information, or delete it in some circumstances. To request to review,
                        update, or delete your personal information, please submit a request by
                        emailing us at {``}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                        .{`\n\n\n\n`}
                    </Text>
                </View>
            </ScrollView>
        </>
    );
}
