import * as ImagePicker from 'expo-image-picker';
import { User, UserService } from 'gc-common';
import React, { useEffect, useState } from 'react';
import {
    DeviceEventEmitter,
    Image,
    Keyboard,
    KeyboardAvoidingView,
    ScrollView,
    Text,
    View,
} from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { InputRow } from '../../components/CustomInputText/InputRow';
import { useModal } from '../../context/modalContext';
import { ImageService } from '../../services/image/imageService';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_USER_AVATAR } from '../../types/Constants';
import { Logger } from '../../utils/logger';
import { styles } from './styles';

export const EditProfilePage = ({ route, navigation }: RootStackScreenProps<'EditProfile'>) => {
    const modal = useModal();
    const popN = route.params?.popN || 1;
    const [user, setUser] = useState<User | undefined>();
    const [avatar, setAvatar] = useState(user?.profile_photo);
    useEffect(() => {
        getMeInfo();
        // console.log('user',user);
    }, []);

    useEffect(() => {
        setAvatar(user?.profile_photo);
    }, [user]);

    const getMeInfo = async () => {
        const info = await UserService.getMeInfo();
        setUser(info.data);
    };

    const updateUserField = (field: string, value: any) => {
        setUser({
            ...user,
            [field]: value,
        } as User);
    };

    const onSubmit = async () => {
        await UserService.updateProfile({
            display_name: user?.display_name,
            bio: user?.bio,
        });
        DeviceEventEmitter.emit('refresh-profile');
        navigation.pop(popN);
    };

    const selectAvatar = async () => {
        Keyboard.dismiss();
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: false,
        });

        if (!result.canceled) {
            setAvatar(result.assets[0].uri);
            modal.open('loader');

            try {
                const uploadResult = await ImageService.uploadImages([result.assets[0].uri]);
                console.log(uploadResult[0]);

                const newUser = await UserService.updateProfile({
                    profile_photo: uploadResult[0].image_id,
                });

                console.log('newUser', newUser);
                DeviceEventEmitter.emit('refresh-profile');
                updateUserField('profile_photo', result.assets[0].uri);
                modal.close();
            } catch (e) {
                modal.open('warning', 'Sorry, Photo upload failed. Please try again.');
                Logger.error('[ME][PHOTO] Profile photo edit failed.');
            }
        }
    };

    return (
        <>
            {user && (
                <KeyboardAvoidingView behavior={'padding'} key={'tmp'} keyboardVerticalOffset={100}>
                    <ScrollView style={{ padding: 15 }}>
                        <View style={styles.avatarContainer}>
                            <Image
                                style={styles.userAvatar}
                                source={{ uri: avatar || DEFAULT_USER_AVATAR }}
                            />
                            <Text style={styles.changeAvatarLink} onPress={selectAvatar}>
                                Change profile photo
                            </Text>
                        </View>
                        <InputRow
                            title={'Display Name'}
                            isPassword={false}
                            leftIcon={'user'}
                            placeholder={'Please enter your preferred display name'}
                            initValue={user?.display_name}
                            onChangeText={(text) => updateUserField('display_name', text)}
                        />

                        <InputRow
                            title={'Biography'}
                            isPassword={false}
                            leftIcon={'edit'}
                            placeholder={'Please enter your Biography here...'}
                            initValue={user?.bio}
                            multiline={true}
                            onChangeText={(text) => updateUserField('bio', text)}
                        />

                        <View>
                            <GCButton text={'Save Profile'} onClick={onSubmit} />
                        </View>
                    </ScrollView>
                </KeyboardAvoidingView>
            )}
        </>
    );
};
