import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    dishContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    dishWrapper: {},

    noResultWrapper: {
        margin: 10,
    },
    noResultText: {
        marginVertical: 10,
        textAlign: 'center',
    },
    autocompleteWrapper: {
        backgroundColor: Colors.white,
    },
    autocompleteItem: {
        fontSize: 18,
        paddingHorizontal: 20,
        paddingVertical: 15,
        borderBottomWidth: 1,
        borderColor: Colors.light_gray_2,
    },
    historySection: {
        margin: 15,
    },
    historyHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    historyTitle: {
        marginBottom: 10,
        color: Colors.dark_gray,
    },
    historyWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    historyItem: {
        fontSize: 12,
        borderRadius: 10,
        backgroundColor: Colors.light_gray,
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: 10,
        marginBottom: 10,
    },
    historyText: {
        color: Colors.dark_gray,
    },

    userSection: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        backgroundColor: Colors.white,
    },
    userItem: {
        alignItems: 'center',
        width: 70,
        margin: 10,
    },

    userAvatar: {
        width: 45,
        height: 45,
        borderRadius: 60,
    },

    userName: {
        marginVertical: 2,
    },
});
