import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';
import { styles as RestaurantDetailStyles } from '../../screens/RestaurantDetail/styles';
export const styles = StyleSheet.create({
    dishImage: RestaurantDetailStyles.dishImage,
    dishItemWrapper: {
        marginHorizontal: 5,
    },
    dishName: {
        maxWidth: 125,
        fontSize: 10,
    },
    listFooterComponent: {
        height: 100,
    },
    rowItemWrapper: {
        paddingHorizontal: 5,
    },
    inviteButtonWrapper: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    inviteButton: {
        borderRadius: 5,
        backgroundColor: Colors.primary,
        margin: 5,
        height: 30,
        width: 90,
        alignItems: 'center',
        justifyContent: 'center',
    },
    inviteText: {
        color: Colors.white,
    },
    additionalItemWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    additionalItem: {
        width: 200,
        height: 200,
    },
});
