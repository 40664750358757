import AsyncStorage from '@react-native-async-storage/async-storage';

export const SecureStore = {
    setItemAsync: async (key: string, value: string) => {
        AsyncStorage.setItem(key, value);
        return true;
    },
    getItemAsync: async (key: string) => {
        return AsyncStorage.getItem(key);
    },
    // getItemSync: AsyncStorage.getItem,
    deleteItemAsync: async (key: string) => {
        return AsyncStorage.removeItem(key);
    },
};
