import { Dimensions, StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

const tabHeight = 50;

export const HeaderDimension = {
    tabHeight,
};

export const styles = StyleSheet.create({
    topSectionContainer: {
        height: 160,
        backgroundColor: Colors.primary,
        paddingHorizontal: 15,
        paddingVertical: 5,
    },
    headerBG: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: Dimensions.get('screen').width,
        height: 160,
    },

    absAvatar: {
        position: 'absolute',
        top: -50,
        left: 40,
    },
    userAvatar: {
        height: 100,
        width: 100,
        borderRadius: 100,
        // zIndex: 3,
        // elevation: 5,
    },
    toolbar: {
        zIndex: 100,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    toolBarIcon: {
        padding: 10,
    },
    detailsContainer: {
        position: 'absolute',
        left: 165,
        bottom: 10,
    },
    displayName: {
        fontSize: 20,
        fontFamily: 'eina01-semibold',
        color: Colors.white,
    },

    // Status Counters
    whiteContainer: {
        backgroundColor: Colors.white,
    },
    counterWrapper: {
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    counterCard: {
        alignItems: 'center',
        margin: 10,
    },
    counterCardNumber: {
        fontSize: 16,
        color: Colors.charcoal,
        fontFamily: 'eina01-semibold',
    },
    counterCardText: {
        fontSize: 12,
        color: Colors.dark_gray,
        fontFamily: 'eina01-semibold',
    },

    description: {
        fontSize: 14,
        lineHeight: 21,
        margin: 15,
        color: Colors.charcoal,
    },

    actionButtonWrapper: {
        marginHorizontal: 15,
        marginVertical: 15,
    },

    // Edit Input Section
    avatarContainer: {
        alignItems: 'center',
        flexDirection: 'column',
    },
    changeAvatarLink: {
        marginVertical: 10,
        color: Colors.primary,
    },

    // Posts
    postImageWrapper: {
        width: '33.33%',
        margin: 2,
        backgroundColor: Colors.white,
    },
    postImage: {
        // flex:1,
        aspectRatio: 1,
    },

    // follow
    screenContainer: {
        width: '100%',
        // paddingHorizontal: 5,
        flex: 2,
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: Colors.white,
    },
    followWrapper: {
        paddingVertical: 5,
        paddingHorizontal: 20,
    },
    followItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    followImageContainer: {
        marginRight: 10,
    },
    followImage: {
        width: 40,
        height: 40,
        borderRadius: 50,
    },
    followInfo: {
        flex: 1,
        paddingHorizontal: 5,
        justifyContent: 'center',
    },
    followName: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.charcoal,
        marginVertical: 5,
    },
    followDesc: {
        fontSize: 14,
        color: Colors.dark_gray,
        width: '100%',
        overflow: 'hidden',
    },
    followButton: {
        width: 100,
        justifyContent: 'center',
    },

    // MeFollowFilter
    wrapper: {
        flex: 1,
    },
    filterGroup: {
        flexDirection: 'row',
        backgroundColor: Colors.white,
        borderColor: Colors.gray,
        justifyContent: 'center',
    },
    filterItem: {
        height: 35,
        paddingLeft: 10,
        paddingRight: 10,
        fontWeight: 'bold',
        justifyContent: 'center',
    },
    active: {
        borderBottomColor: Colors.primary,
        borderBottomWidth: 2,
    },
    ratingText: {
        fontSize: 17,
    },
    ratingItem: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: 5,
    },
});
