import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { FollowUser } from 'gc-common';
import React, { useState } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { useAuth } from '../../context/authContext';
import { RootStackParamList } from '../../types';
import { DEFAULT_USER_AVATAR } from '../../types/Constants';
import { styles } from './styles';
import { useActions } from './useActions';

interface FollowItemProps {
    item: FollowUser;
    type: string;
    userId: string;
}

export enum MeFollowItemState {
    NONE = -1,
    SELF,
    FOLLOWING,
    REMOVE,
    MUTUAL,
    REMOVED,
}

export const MeFollowItem = ({ item, type, userId }: FollowItemProps) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const actions = useActions();
    const auth = useAuth();
    const [btnState, setBtnState] = useState<MeFollowItemState | undefined>(() => {
        // 自己的主页
        if (auth?.user?.user_id === userId) {
            if (type === 'follower') {
                return MeFollowItemState.NONE; // Following
            } else {
                if (item.user_followed_me && item.I_followed_user) {
                    return MeFollowItemState.MUTUAL;
                }
                return MeFollowItemState.REMOVE; // Remove
            }
        } else {
            return MeFollowItemState.NONE;
            // if (item.user_id === auth?.user?.user_id) {
            //     // 关注者ID == 自己的ID
            //     return MeFollowItemState.SELF;
            // } else if (item.I_followed_user) {
            //     // 他关注的人或他的追随者 自己已经关注
            //     return MeFollowItemState.REMOVE; // Remove
            // } else if (!item.I_followed_user) {
            //     // 他关注的人或他的追随者 自己没有关注
            //     return MeFollowItemState.FOLLOWING; // Following
            // } else if (item.user_followed_me) {
            //     // 关注者也关注了自己
            //     return MeFollowItemState.MUTUAL;
            // }
        }
    });

    async function myFollow() {
        await actions.follow(item.user_id);
        setBtnState(MeFollowItemState.REMOVE);
    }

    async function myUnFollow() {
        await actions.unfollow(item.user_id);
        setBtnState(MeFollowItemState.REMOVED);
    }

    const FollowBtn = () => {
        if (btnState === MeFollowItemState.FOLLOWING) {
            return (
                <GCButton type="secondary" onClick={myFollow}>
                    <Text>Following</Text>
                </GCButton>
            );
        } else if (btnState === MeFollowItemState.REMOVE) {
            return (
                <GCButton type="secondary" onClick={myUnFollow}>
                    <Text>Remove</Text>
                </GCButton>
            );
        } else if (btnState === MeFollowItemState.MUTUAL) {
            return (
                <GCButton type="secondary" onClick={myUnFollow}>
                    <Text>Mutual</Text>
                </GCButton>
            );
        } else if (btnState === MeFollowItemState.REMOVED) {
            return <Text>Removed</Text>;
        } else {
            return null;
        }
    };

    return (
        <View style={styles.followWrapper}>
            <View style={styles.followItemContainer}>
                <Pressable onPress={() => navigation.push('User', { id: item.user_id })}>
                    <View style={styles.followImageContainer}>
                        <Image
                            style={styles.followImage}
                            source={{
                                uri: item.user_profile_photo_url || DEFAULT_USER_AVATAR,
                            }}
                        />
                    </View>
                </Pressable>

                <View style={styles.followInfo}>
                    <Text style={styles.followName}>{item.user_display_name}</Text>
                    {item.user_bio && (
                        <Text style={styles.followDesc} numberOfLines={1}>
                            {item.user_bio}
                        </Text>
                    )}
                </View>

                {btnState != MeFollowItemState.NONE && (
                    <View style={styles.followButton}>{FollowBtn()}</View>
                )}
            </View>
        </View>
    );
};
