import { ReactNode } from 'react';
import { GestureResponderEvent, View } from 'react-native';
import { GCButton, GCButtonProps } from '../Button/GCButton';
import { styles } from './styles';

export interface BottomButtonProps extends GCButtonProps {
    children?: ReactNode;
    text?: string;
    onClick?: (e: GestureResponderEvent) => void;
}
export const BottomButton = (props: BottomButtonProps) => {
    return (
        <View style={styles.wrapper}>
            <GCButton {...props} />
        </View>
    );
};
