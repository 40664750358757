import { Dimensions, DimensionValue } from 'react-native';

const calcGridCols = (itemWidth: number, minCol = 2) => {
    const screenWidth = Dimensions.get('window').width;
    const n = screenWidth / itemWidth;
    console.log(screenWidth, n, 1 / Math.floor(n), Dimensions.get('window').width);
    const cols = Math.max(minCol, Math.floor(n));

    return cols === 1 ? undefined : cols;
};

const colsToWidthPercentage = (n: number): DimensionValue => {
    return `${(1 / n) * 100}%`;
};

export const ScreenUtils = { calcGridCols, colsToWidthPercentage };
