import * as FileSystem from 'expo-file-system';
import { ajax, GeneralArrayResponse } from 'gc-common';
import { Image as ImageCompressor } from 'react-native-compressor';
import mime from 'react-native-mime-types';

export interface ImageIdUrl {
    url: string;
    image_id: string;
}
const generateUploadUrls = async (num_of_images: number) => {
    const uploadUrls = (
        await ajax.get<GeneralArrayResponse<ImageIdUrl>>('/api/v2/generate_presigned_url', {
            num_of_images,
        })
    ).data.data;
    console.log('upload urls', uploadUrls);

    return uploadUrls;
};

const uploadImage = async (file: string, uploadUrl: string) => {
    return new Promise((resolver, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.timeout = 20000;

        xhr.onload = () => {
            if (xhr.status < 400) {
                resolver(true);
            } else {
                const error = new Error(xhr.response);
                reject(error);
            }
        };
        xhr.onerror = (error) => {
            reject(error);
        };

        xhr.ontimeout = (e) => {
            reject(e);
        };

        xhr.open('PUT', uploadUrl);
        xhr.setRequestHeader('Content-Type', mime.lookup(file) || 'image/jpg');
        xhr.setRequestHeader('x-amz-acl', 'public-read');
        xhr.send({ uri: file });
    });
};

const compressImages = async (images: string[]) => {
    return await Promise.all(
        images.map((i) => {
            return ImageCompressor.compress(i, {
                compressionMethod: 'auto',
            });
        })
    );
};

const uploadImages = async (photos: string[], updateStatus?: (text: string) => void) => {
    photos.map(async (p) => {
        const st = await FileSystem.getInfoAsync(p);
        console.log(`${p} => ${st.size}`);
    });
    updateStatus?.('Compressing Images...');
    const compressedPhotos = await compressImages(photos);
    updateStatus?.('Images Compressed!');
    const uploadUrls = await generateUploadUrls(compressedPhotos.length);

    for (let i = 0; i < compressedPhotos.length; i++) {
        updateStatus?.(`Uploading images (${i + 1}/${compressedPhotos.length})`);
        const photo = compressedPhotos[i];
        const uploadUrl = uploadUrls[i];
        const uploadRsp = await uploadImage(photo, uploadUrl.url);
    }
    updateStatus?.('~ Submit Successful ~');

    return uploadUrls;
};

export const ImageService = {
    generateUploadUrls,
    compressImages,
    uploadImage,
    uploadImages,
};
