import _BottomSheet, { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { BottomSheet } from '../webLibs/BottomSheet/BottomSheet';
import { AddressModel } from 'gc-common';
import React, { useRef, useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { BottomSheetStyles } from '../components/Styles/BottomSheetStyles';
import { openMapLink } from '../utils/commonUtils';

export const useMapSelector = () => {
    const bottomSheetRef = useRef<_BottomSheet>(null);

    const [address, setAddress] = useState<AddressModel>();

    const onNavPressed = (addr?: AddressModel) => {
        if (!addr) return;

        setAddress(addr);
        if (Platform.OS === 'web') {
            openMapLink(addr, true);
            return;
        }
        if (Platform.OS === 'android') {
            openMapLink(addr);
        } else {
            bottomSheetRef.current?.expand();
        }
    };

    const renderBottomSheet = () => {
        return (
            <BottomSheet
                ref={bottomSheetRef}
                index={-1}
                snapPoints={[160]}
                enablePanDownToClose
                style={BottomSheetStyles.style}
                handleStyle={BottomSheetStyles.handleStyle}
                backdropComponent={(props) => (
                    <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />
                )}
            >
                <View
                    style={[
                        BottomSheetStyles.contentStyle,
                        {
                            alignItems: 'center',
                        },
                    ]}
                >
                    <TouchableOpacity
                        style={BottomSheetStyles.actionItem}
                        onPress={() => {
                            openMapLink(address, true);
                        }}
                    >
                        <Text style={BottomSheetStyles.actionItemText}>Google Map</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={BottomSheetStyles.actionItem}
                        onPress={() => {
                            openMapLink(address);
                        }}
                    >
                        <Text style={BottomSheetStyles.actionItemText}>Apple Map</Text>
                    </TouchableOpacity>
                </View>
            </BottomSheet>
        );
    };

    return {
        renderBottomSheet,
        onNavPressed,
    };
};
