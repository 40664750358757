import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    ////////////////    Pic Carousel
    picsContainer: {
        position: 'relative',
        alignItems: 'center',
    },
    pic: {
        height: 300,
    },
    picPagination: {
        position: 'absolute',
        bottom: -10,
    },
});
