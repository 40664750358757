import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    pageWrapper: {
        backgroundColor: Colors.white,
    },
    divider: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 0,
        width: '100%',
    },
    socialButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: 100,
    },
    text: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 50,
    },
    buttonContainer: {
        borderRadius: 50,
        marginTop: 30,
        height: 40,
        width: '100%',
        justifyContent: 'center',
        backgroundColor: Colors.primary,
    },
    screenContainer: {
        paddingHorizontal: 20,
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    topBarContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 70,
        paddingBottom: 15,
    },
    inputContainer: {
        marginTop: 30,
        width: '100%',
    },
    inputTitle: {
        fontSize: 15,
        fontFamily: 'eina01',
    },
    inputView: {
        borderRadius: 30,
        height: 45,
        marginBottom: 20,
        marginTop: 10,
    },
    title: {
        fontSize: 35,
        fontFamily: 'eina01-bold',
    },
    subTitle: {
        fontSize: 12,
        marginTop: 3,
        fontFamily: 'eina01-semibold',
    },
});
