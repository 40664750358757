import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { CollectionEnum, Dish } from 'gc-common';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { GotoHomeWidget } from '../../components/GotoHomeWidget';
import { Ratings } from '../../components/Ratings/Ratings';
import Spinner from '../../components/Spinner/Spinner';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { useCollection } from '../../hooks/useCollection';
import { LikeEnum, useLike } from '../../hooks/useLike';
import { useMapSelector } from '../../hooks/useMapSelector';
import { RootStackParamList } from '../../types';
import { CommonUtils, formatAddress } from '../../utils/commonUtils';
import { styles } from './styles';
import {EventLogger} from "../../utils/EventLogger";

export const useDishDetails = (dish?: Dish) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const mapSelector = useMapSelector();
    const auth = useAuth();
    const route = useRoute();
    const like = useLike(LikeEnum.dish, dish?.dish_id);
    const collection = useCollection(CollectionEnum.dish, dish?.restaurant_id, dish?.dish_id);

    const onRestaurantPressed = () => {
        navigation.push('RestaurantDetail', { id: dish!.restaurant_id });
    };

    const onShare = () => {
        let message = dish?.name;
        EventLogger.clickDishShare(dish?.dish_id || 'undefined');
        CommonUtils.share(`${Env.WEB_SHARE_ROOT}/dish?id=${dish?.dish_id}`, message);
    };

    const render = () => {
        return (
            <View style={styles.titleContainer}>
                <View style={styles.titleRow}>
                    <Text style={styles.title} selectable>
                        {dish?.name}
                    </Text>
                    {dish?.price !== 0 && <Text style={styles.price}>{`$${dish?.price}`}</Text>}
                </View>
                <View style={styles.storeDetailWrapper}>
                    <View style={[styles.titleRow, styles.storeDetailRow]}>
                        <View style={[CommonStyles.flexRowCenter, { alignItems: 'center' }]}>
                            <Text>
                                <Feather name={'home'} size={15} />{' '}
                            </Text>
                            <Text style={styles.storeName} onPress={onRestaurantPressed}>
                                {dish?.restaurant_name}
                            </Text>
                        </View>
                        <View
                            style={[
                                CommonStyles.flexRowCenter,
                                { alignItems: 'flex-start', marginTop: 10 },
                            ]}
                        >
                            <Text>
                                <Ionicons name={'location'} size={18} />{' '}
                            </Text>
                            <Text style={styles.storeAddr}>
                                {formatAddress(dish?.address, true)}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.storeActionsContainer}>
                        <TouchableOpacity
                            style={styles.storeActionButton}
                            onPress={onRestaurantPressed}
                        >
                            <Feather style={styles.storeActionButtonIcon} name={'home'} />
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.storeActionButton}
                            onPress={() => mapSelector.onNavPressed(dish?.address)}
                        >
                            <Feather style={styles.storeActionButtonIcon} name={'navigation-2'} />
                        </TouchableOpacity>
                        {/*<TouchableOpacity style={styles.storeActionButton} onPress={onShare}>*/}
                        {/*    <Feather style={styles.storeActionButtonIcon} name={'share'} />*/}
                        {/*</TouchableOpacity>*/}
                    </View>
                </View>

                <View style={styles.titleRow}>
                    <Ratings rating={dish?.rating || 0} />
                </View>

                <View style={[styles.titleRow, styles.extraActionContainer]}>
                    {like.isLiking ? (
                        <View style={styles.extraButtonWrapper}>
                            <Spinner style={styles.extraButton} />
                        </View>
                    ) : (
                        <TouchableOpacity style={styles.extraButtonWrapper} onPress={like.onLike}>
                            {like.isLike ? (
                                <Text style={[styles.extraButton, styles.extraActivationButton]}>
                                    <FontAwesome name={'thumbs-up'} /> Unlike
                                </Text>
                            ) : (
                                <Text style={styles.extraButton}>
                                    <FontAwesome name={'thumbs-o-up'} /> Like
                                </Text>
                            )}
                        </TouchableOpacity>
                    )}
                    {collection.isCollecting ? (
                        <View style={styles.extraButtonWrapper}>
                            <Spinner style={styles.extraButton} />
                        </View>
                    ) : (
                        <TouchableOpacity
                            style={styles.extraButtonWrapper}
                            onPress={collection.onCollect}
                        >
                            {collection.isCollect ? (
                                <Text style={[styles.extraButton, styles.extraActivationButton]}>
                                    <FontAwesome name={'star'} /> Uncollect
                                </Text>
                            ) : (
                                <Text style={styles.extraButton}>
                                    <FontAwesome name={'star-o'} /> Collect
                                </Text>
                            )}
                        </TouchableOpacity>
                    )}
                    <TouchableOpacity style={styles.extraButtonWrapper} onPress={onShare}>
                        <Text style={styles.extraButton}>
                            <FontAwesome name={'share-alt'} /> Share
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.titleRow}>
                    <Text style={styles.description}>{dish?.description}</Text>
                </View>

                <GotoHomeWidget />
            </View>
        );
    };

    return {
        render,
        renderBottomSheet: mapSelector.renderBottomSheet,
    };
};
