import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { GCButton } from './Button/GCButton';

export interface GotoHomeWidgetProps {}
export const GotoHomeWidget = () => {
    const navigation = useNavigation();
    const onPress = () => {
        navigation.navigate('Root');
    };

    if (Platform.OS !== 'web') return <></>;
    return (
        <View style={styles.container}>
            <GCButton type={'secondary'} onClick={onPress}>
                <Text>
                    <Feather name={'home'} size={15} /> Check out Homepage for more
                </Text>
            </GCButton>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: 15,
        paddingVertical: 15,
    },
});
