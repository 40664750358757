import { Entypo } from '@expo/vector-icons';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_USER_AVATAR } from '../../types/Constants';
import { Image } from '../../webLibs/FastImage/FastImage';
import { useFollow } from './useFollow';

const Tab = createMaterialTopTabNavigator();

export const MeFollow = ({ route }: RootStackScreenProps<'MeFollow'>) => {
    const { type, userId } = route.params;

    const initialPage = type === 'follower' ? 'Followers' : 'Following';

    return (
        <Tab.Navigator
            initialRouteName={initialPage}
            screenOptions={{
                tabBarStyle: { height: 40 },
                tabBarIndicatorStyle: { backgroundColor: Colors.primary },
                tabBarInactiveTintColor: Colors.dark_gray,
            }}
        >
            <Tab.Screen name="Followers" initialParams={{ userId }} component={FollowerList} />
            <Tab.Screen name="Following" initialParams={{ userId }} component={FollowingList} />
            <Tab.Screen name="Blocked" initialParams={{ userId }} component={BlockList} />
        </Tab.Navigator>
    );
};

function FollowerList({ route }: any) {
    const follow = useFollow({ userId: route.params.userId, type: 'follower' });
    const [refreshing, setRefreshing] = useState(false);

    return (
        <FlatList
            data={follow.data}
            renderItem={follow.renderItem}
            refreshing={refreshing}
            onRefresh={async () => {
                setRefreshing(true);
                await follow.fetchData(1);
                setRefreshing(false);
            }}
            onEndReached={() => follow.data.length > 10 && follow.fetchData(follow.page + 1)}
        />
    );
}

const FollowingList = ({ route }: any) => {
    const follow = useFollow({ userId: route.params.userId, type: 'following' });
    const [refreshing, setRefreshing] = useState(false);

    return (
        <FlatList
            data={follow.data}
            renderItem={follow.renderItem}
            refreshing={refreshing}
            onRefresh={async () => {
                setRefreshing(true);
                await follow.fetchData(1);
                setRefreshing(false);
            }}
            onEndReached={() => follow.data.length > 10 && follow.fetchData(follow.page + 1)}
        />
    );
};

const BlockList = ({ route }: any) => {
    const auth = useAuth();
    const data = Object.values(auth.getBlockedUsers);

    return (
        <FlatList
            data={data}
            renderItem={({ item }) => {
                return (
                    <View style={[CommonStyles.flexRowCenter, { padding: 10 }]}>
                        <Image
                            source={{ uri: item.profile_photo || DEFAULT_USER_AVATAR }}
                            style={{ height: 35, width: 35, borderRadius: 35 }}
                        />
                        <Text style={{ flex: 1, marginHorizontal: 10, fontSize: 18 }}>
                            {item.display_name}
                        </Text>
                        <Entypo
                            name="cross"
                            size={24}
                            onPress={async () => {
                                await auth.unblockUser(item);
                            }}
                        />
                    </View>
                );
            }}
        />
    );
};
