export const AF_ONE_LINK_URL = 'https://gourmet.onelink.me/gB9s';
export const AF_MEDIA_SRC = { keys: ['media','utm_source'], defaultValue: 'web' };
export const AF_SS_UI = { paramKey: 'af_ss_ui', defaultValue: 'true' };

export type AF_CONFIG_OBJ = {
    keys?: string[];
    overrideValues?: { [key: string]: string };
    defaultValue?: string | string[];
};

export interface AF_CONFIG_PARAM {
    mediaSource: AF_CONFIG_OBJ;
    campaign?: AF_CONFIG_OBJ;
    channel?: AF_CONFIG_OBJ;
    ad?: AF_CONFIG_OBJ;
    adSet?: AF_CONFIG_OBJ;
    deepLinkValue?: AF_CONFIG_OBJ | string;
    afSub1?: any;
    afSub2?: any;
    afSub3?: any;
    afSub4?: any;
    afSub5?: any;
    afCustom?: {
        keys?: string[];
        paramKey?: string;
        defaultValue?: string;
    }[];
}

export interface AF_SMART_SCRIPT_CONFIG {
    oneLinkURL: string;
    afParameters: AF_CONFIG_PARAM;
    referrerSkipList?: string[]; // optional
    urlSkipList?: string[];
}

declare global {
    interface Window {
        AF_SMART_SCRIPT: {
            generateOneLinkURL: (config: AF_SMART_SCRIPT_CONFIG) => { clickURL: string } | null;
        };
    }
}
