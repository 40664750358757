import { User, UserService } from 'gc-common';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { useAuth } from '../../context/authContext';
import { styles } from './styles';

export const useActions = (user?: User) => {
    const [isFollowed, setIsFollowed] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        user && auth.isLoggedIn && checkFollowing(user.user_id);
    }, [user]);

    const checkFollowing = async (userId: string) => {
        const result = await UserService.hasFollowed(userId);
        setIsFollowed(result.data);
    };

    const follow = async (userId: string) => {
        const rsp = await UserService.follow(userId);
        setIsFollowed(true);
    };

    const unfollow = async (userId: string) => {
        const rsp = await UserService.unfollow(userId);
        setIsFollowed(false);
    };

    const render = () => {
        return (
            <>
                {user && !isFollowed && (
                    <View style={styles.actionButtonWrapper}>
                        <GCButton
                            text={'Follow'}
                            type={'primary'}
                            onClick={() => follow(user.user_id)}
                        />
                    </View>
                )}

                {user && isFollowed && (
                    <View style={styles.actionButtonWrapper}>
                        <GCButton
                            text={'Unfollow'}
                            type={'secondary'}
                            onClick={() => unfollow(user.user_id)}
                        />
                    </View>
                )}
            </>
        );
    };

    return {
        isFollowed,
        render,
        follow,
        unfollow,
    };
};
