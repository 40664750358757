import React, { useEffect } from 'react';
import {
    DeviceEventEmitter,
    Image,
    Linking,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { Colors } from '../constants/Colors';
import { LinkingUtil } from '../navigation/LinkingConfiguration';
import { AF_MEDIA_SRC, AF_ONE_LINK_URL } from '../types/AppsFlyer';
import { CommonUtils } from '../utils/commonUtils';
import { EventLogger } from '../utils/EventLogger';
import { GCButton } from './Button/GCButton';
import { CommonStyles } from './Styles/CommonStyles';

const onPress = () => {
    EventLogger.clickOpenInApp(LinkingUtil.getPageName(window.location.pathname.slice(1)));
    const af = window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL: AF_ONE_LINK_URL,
        afParameters: {
            mediaSource: AF_MEDIA_SRC,
            campaign: { keys: ['utm_campaign'] },
            deepLinkValue: {
                defaultValue: LinkingUtil.getPageName(window.location.pathname.slice(1)),
            },
            afCustom: [
                {
                    paramKey: 'deep_link_sub1',
                    defaultValue: encodeURIComponent(JSON.stringify(CommonUtils.urlQueryToObj())),
                },
            ],
        },
    });

    console.log(af?.clickURL);
    if (!af) return;
    Linking.openURL(af?.clickURL);
};

export interface AppDownloaderProps {}
export const AppDownloader = () => {
    const [show, setShow] = React.useState(true);
    useEffect(() => {
        DeviceEventEmitter.addListener('route-change', (page: string) => {
            if (page === 'SignIn' || page === 'SignUp') setShow(false);
            else setShow(true);
        });

        return () => {
            DeviceEventEmitter.removeAllListeners('route-change');
        };
    }, []);
    return show ? (
        <TouchableOpacity style={styles.container} onPress={onPress}>
            <View style={CommonStyles.flexRowCenter}>
                <Image style={styles.logo} source={require('../../assets/images/icon.png')} />
                <Text>Open in App</Text>
            </View>
        </TouchableOpacity>
    ) : (
        <></>
    );
};

export const AppDownloaderBar = () => {
    const iOSSafari = () => {
        const userAgent = window.navigator.userAgent;
        return (
            /iP(ad|od|hone)/i.test(userAgent) &&
            /WebKit/i.test(userAgent) &&
            !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
        );
    };

    if (iOSSafari()) return <></>;

    return (
        <View style={styles.barContainer}>
            <Image style={styles.icon} source={require('../../assets/images/icon.png')} />
            <View style={styles.content}>
                <Text style={styles.title}>Gourmet Carte</Text>
                <Text style={styles.desc}>The App for Foodlovers</Text>
            </View>
            <GCButton text={'Open App'} onClick={onPress} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.white,

        shadowColor: '#171717',
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 4,

        borderRadius: 60,
        paddingVertical: 5,
        paddingHorizontal: 10,
    },

    logo: {
        width: 20,
        height: 20,
    },

    barContainer: {
        backgroundColor: Colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: Colors.gray,
        padding: 10,
        height: 60,
    },

    icon: {
        width: 40,
        height: 40,
    },

    content: {
        marginHorizontal: 10,
        flex: 1,
    },
    title: {
        fontSize: 18,
        fontFamily: 'eina01-semibold',
        color: Colors.charcoal,
    },
    desc: {
        fontSize: 13,
        color: Colors.dark_gray,
    },
});
