import { StyleSheet } from 'react-native';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    section: CommonStyles.section,
    titleContainer: CommonStyles.titleContainer,
    titleRow: CommonStyles.titleRow,
    carouselPlaceHolder: {
        height: 60,
        backgroundColor: Colors.white,
    },
    storeDetailRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    title: CommonStyles.title,
    titleIcon: CommonStyles.titleIcon,
    price: {
        color: Colors.primary,
        fontSize: 20,
    },
    description: {
        color: Colors.dark_gray,
    },
    storeAddr: {
        fontSize: 12,
        color: 'gray',
        flex: 1,
    },
    storeAddrWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    storeActionButton: {
        backgroundColor: Colors.primary,
        margin: 5,
        height: 35,
        width: 35,
        borderRadius: 35,
        alignItems: 'center',
        justifyContent: 'center',
    },
    storeActionButtonIcon: {
        fontSize: 20,
        color: Colors.white,
    },
    linkText: {
        color: Colors.primary,
        paddingRight: 10,
    },

    // Info
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 16,
        maxWidth: '90%',
    },
    infoIcon: {
        marginRight: 12,
        color: Colors.dark_gray,
        fontSize: 20,
    },
    infoText: {
        fontSize: 16,
        color: Colors.dark_gray,
    },

    descriptionContent: {
        marginLeft: 32,
        lineHeight: 18,
        fontSize: 14,
    },

    // Popular Dish
    popDishWrapper: {
        marginLeft: 32,
    },
    dishImage: {
        borderRadius: 10,
        width: 125,
        height: 88,
        marginRight: 16,
        marginVertical: 8,
    },
    dishText: {
        maxWidth: 125,
    },

    // action bar
    extraActionContainer: {
        height: 30,
    },
    extraButtonWrapper: {
        // flex: 1,
        minWidth: 80,
    },
    extraButton: {
        borderRadius: 5,
        borderColor: Colors.gray,
        borderWidth: 1,
        paddingVertical: 3,
        paddingHorizontal: 5,
        color: Colors.gray,
        marginHorizontal: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    extraActivationButton: {
        borderColor: Colors.primary,
        color: Colors.primary,
    },
    checkInWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
    },
    checkInTitle: {
        fontSize: 16,
        lineHeight: 25,
        fontFamily: 'eina01',
        flex: 1,
    },
    checkInContentWrapper: {
        // maxWidth: '85%',
        flex: 1,
    },
    checkInButton: {
        backgroundColor: Colors.light_gray,
        margin: 5,
        height: 35,
        width: 35,
        borderRadius: 35,
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkInButtonActive: {
        backgroundColor: Colors.primary,
    },
});
