import { useNavigation } from '@react-navigation/native';
import { Post, PostService } from 'gc-common';
import LottieView from 'lottie-react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { DimensionValue, FlatList, Text, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { ItemCard } from '../../components/ItemCard';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { Env } from '../../Env';
import { usePagination } from '../../hooks/usePagination';
import { DEFAULT_USER_AVATAR, LOCATIONS } from '../../types/Constants';
import { CommonUtils } from '../../utils/commonUtils';
import { Logger } from '../../utils/logger';
import { ScreenUtils } from '../../utils/screenUtils';
import { styles } from './styles';
import { useFilterSection } from './useFilterSection';

interface HomeDishViewProps {
    setCurrentView: (i: number) => void;
}
export const HomePostView = ({ setCurrentView }: HomeDishViewProps) => {
    const auth = useAuth();
    const modal = useModal();
    const navigation = useNavigation();
    const filterSection = useFilterSection('dish', setCurrentView, () => {
        pagination.reset();
        fetchData();
    });
    const [error, setError] = useState(false);

    const cols = useMemo(() => {
        return ScreenUtils.calcGridCols(185, 2);
    }, []);

    const { data, page, isFetching, fetchData, ...pagination } = usePagination<Post>(
        async (page, size) => {
            let location = auth.location;
            if (!location || location.length != 2) location = LOCATIONS[0].location;
            Logger.log('[HOME][POST] Fetching Post requested');
            let _data: Post[] = [];
            if (filterSection.isFiltering) {
                const filters = filterSection.getFilterParams();
                _data = (await PostService.getPostsByFilter(location, 999999999, filters, page, 12))
                    .data;
            } else if (filterSection.sort === 'recommendation')
                _data = (await PostService.getPostsByRecommendation(location, 999999999, page, 12))
                    .data;
            else if (filterSection.sort === 'distance')
                _data = (await PostService.getPostsByDistance(location, 999999999, page, 12)).data;
            else if (filterSection.sort === 'rating')
                _data = (await PostService.getPostsByRating(location, 999999999, page, 12)).data;
            Logger.log('[HOME][POST] Fetching Post successful');
            return CommonUtils.shuffle(_data);
        },
        2,
        () => {
            setError(true);
            Logger.error('[HOME][POST] Fetching Post failed');
        }
    );

    const initFetch = () => {
        setError(false);
        pagination.reset(true);
        auth.location && fetchData(1);
    };

    useEffect(() => {
        initFetch();
    }, [filterSection.sort]);

    useEffect(() => {
        initFetch();
    }, [auth.location]);

    const onItemPressed = (item: Post) => {
        if (!item.dish_id) {
            Logger.error('[HOME][POST] User clicked on post skeleton.');
            return;
        }
        navigation.navigate('DishDetail', { id: item.dish_id, post: item.dish_post_id });
    };

    const cardWidth = useMemo<DimensionValue>(
        () => ScreenUtils.colsToWidthPercentage(cols || 1),
        [cols]
    );
    return (
        <>
            {auth.location && !error && (
                <FlatList
                    windowSize={2}
                    stickyHeaderIndices={[0]}
                    data={data.filter((item) => !auth.isUserBlocked(item.user_id))}
                    numColumns={cols}
                    renderItem={({ item }) => {
                        return (
                            <View style={{ width: cardWidth }}>
                                <ItemCard
                                    pic={item.image_urls?.[0]}
                                    title={item.dish_name}
                                    author={{
                                        avatar: item.user_profile_photo_url || DEFAULT_USER_AVATAR,
                                        username: item.user_display_name,
                                    }}
                                    rating={item.rating}
                                    likes={item.num_of_likes}
                                    onPress={() => onItemPressed(item)}
                                    skeleton={item.$isLoader}
                                />
                            </View>
                        );
                    }}
                    columnWrapperStyle={styles.cardContainer}
                    keyExtractor={(item, i) => '' + i}
                    ListHeaderComponent={<>{filterSection.render()}</>}
                    onRefresh={pagination.refresh}
                    refreshing={pagination.isRefreshing}
                    onEndReached={() => fetchData(page + 1)}
                    onEndReachedThreshold={0.5}
                />
            )}

            {error && (
                <View style={[CommonStyles.flexColumnCenter, { paddingVertical: 20 }]}>
                    <Text style={{ textAlign: 'center', fontSize: 18, paddingVertical: 10 }}>
                        Sorry, we are having issues fetching data.
                    </Text>
                    <Text style={{ textAlign: 'center', fontSize: 16 }}>Please try again.</Text>
                    <View style={{ marginVertical: 20, marginHorizontal: 20 }}>
                        <GCButton
                            text={'Click here to try refresh'}
                            onClick={() => {
                                Logger.log('[HOME][POST] Fetching Post retried');
                                initFetch();
                            }}
                        />
                    </View>
                </View>
            )}

            {!auth.location && Env.isNative && (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ width: 200, height: 200 }}>
                        <LottieView
                            source={require('../../../assets/images/location.json')}
                            autoPlay
                            loop
                        />
                    </View>
                </View>
            )}
            {filterSection.renderBottomSheet()}
            {filterSection.renderSortingBox()}
        </>
    );
};
