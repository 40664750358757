import * as Updates from 'expo-updates';
import { useEffect } from 'react';
import { Alert, AppState } from 'react-native';
import { Env } from '../Env';
import { Logger } from '../utils/logger';

export const useUpdate = () => {
    useEffect(() => {
        if (Env.APP_ENV != 'staging' && Env.APP_ENV != 'production') return;
        const subscription = AppState.addEventListener('change', async (nextAppState) => {
            if (nextAppState === 'active') {
                const hasUpdate = await Updates.checkForUpdateAsync();

                if (hasUpdate.isAvailable) {
                    Logger.log('[UPDATE] Update available found.');
                    await update();
                }
            }
        });

        return () => {
            subscription.remove();
        };
    }, []);

    const update = async () => {
        await Updates.fetchUpdateAsync();
        Alert.alert('Update Available', 'There is a new update available, please update it now!', [
            {
                text: 'Cancel',
                onPress: () => {
                    Logger.log('[UPDATE] Update cancelled.');
                },
                style: 'cancel',
            },
            {
                text: 'Update Now',
                onPress: () => {
                    Logger.log('[UPDATE] Update applied.');
                    Updates.reloadAsync();
                },
            },
        ]);
    };
};
