import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    wrapper: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        paddingHorizontal: 18,
        paddingTop: 12,
        paddingBottom: 14,
        marginBottom: 20,
    },

    button: {
        backgroundColor: Colors.primary,
        color: Colors.white,
        borderRadius: 50,
        paddingVertical: 8,
        display: 'flex',
        alignItems: 'center',
        paddingHorizontal: 8,
    },

    largeButton: {
        paddingVertical: 12,
    },

    tinyButton: {
        paddingVertical: 4,
    },

    text: {
        color: Colors.white,
        fontSize: 14,
        paddingHorizontal: 10,
    },

    largeText: {
        fontSize: 16,
    },

    secondary: {
        backgroundColor: Colors.white,
        borderWidth: 1,
        borderColor: Colors.gray,
        color: Colors.dark_gray,
    },
    secondaryText: {
        color: Colors.dark_gray,
    },

    disabled: {
        backgroundColor: Colors.light_gray,
        color: Colors.white,
    },
});
