import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    wrapper: {
        padding: 10,
        backgroundColor: Colors.white,
        flex: 1,
    },

    likesText: {
        color: Colors.primary,
    },

    commentsContainer: {
        paddingVertical: 10,
        flexDirection: 'column',
        paddingBottom: 100,
    },

    commentItem: {
        marginBottom: 8,
        flexDirection: 'row',
    },
    author: {
        fontFamily: 'eina01-semibold',
    },
    content: {
        flex: 1,
        lineHeight: 18,
    },

    bottomWrapper: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        flexDirection: 'row',
        backgroundColor: Colors.light_gray_2,
        alignItems: 'center',
        // borderWidth:1,
    },
    inner: {
        flex: 1,
        paddingTop: 10,
        paddingHorizontal: 15,
        paddingBottom: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    sendInputWrapper: {
        flex: 1,
        // backgroundColor: Colors.primary,
    },
    sendIcon: {
        width: 50,
        padding: 10,
    },
    userLikeButton: {
        width: 17,
        height: 18,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    userLikeButtonIcon: {
        fontSize: 14,
        color: Colors.dark_gray,
        fontWeight: 'bold',
    },
    userActivationLikeButtonIcon: {
        color: Colors.primary,
    },

    restaurantInfoWrapper: {
        // flexDirection:"row",
        // alignItems:"center",
        // marginVertical:2,
    },
    restaurantInfoText: {
        paddingBottom: 2,
        marginRight: 6,
        color: Colors.dark_gray,
        fontSize: 12,
    },
});
