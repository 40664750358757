import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, FlatListProps, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Route, TabBar, TabView, TabViewProps } from 'react-native-tab-view';
import { Colors } from '../../constants/Colors';
import { TabViewFlatList } from './TabViewFlatList';
import { ListRefItem } from './useTabViewFlatList';
import AnimatedProps = Animated.AnimatedProps;

export interface TabRoute extends Route {}
export interface CollapsibleTabViewProps {
    header?: ReactNode;
    routes: TabRoute[];
    listProsProvider?: (key: string) => Partial<AnimatedProps<FlatListProps<any>>> | undefined;
    scrollY?: (y: Animated.Value) => void;
}
export const CollapsibleTabView = (props: CollapsibleTabViewProps) => {
    const [tabIndex, setIndex] = useState(0);
    const [routes] = useState(props.routes);
    const [HeaderHeight, setHeaderHeight] = useState(300);
    const safeArea = useSafeAreaInsets();
    const scrollY = useRef(new Animated.Value(0)).current;
    // const scrollY = useSharedValue(0);
    let listRefArr = useRef<ListRefItem[]>([]);
    let listOffset = useRef<any>({});
    let isListGliding = useRef(false);
    const TabBarHeight = 48;

    const [headerSectionZindex, setHeaderSectionZindex] = useState(1);

    useEffect(() => {
        scrollY.addListener(({ value }) => {
            const curRoute = routes[tabIndex].key;
            listOffset.current[curRoute] = value;
            setHeaderSectionZindex(value < 0 ? 0 : 1);
        });
        return () => {
            scrollY.removeAllListeners();
        };
    }, [routes, tabIndex]);

    useEffect(() => {
        props.scrollY?.(scrollY);
    }, [scrollY]);

    const syncScrollOffset = () => {
        const curRouteKey = routes[tabIndex].key;
        const adjustedHeight = HeaderHeight - safeArea.top;
        listRefArr.current.forEach((item) => {
            if (item.key !== curRouteKey) {
                if ((scrollY as any)._value < adjustedHeight && (scrollY as any)._value >= 0) {
                    if (item.value) {
                        item.value.scrollToOffset({
                            offset: (scrollY as any)._value,
                            animated: false,
                        });
                        listOffset.current[item.key] = (scrollY as any)._value;
                    }
                } else if ((scrollY as any)._value >= adjustedHeight) {
                    if (
                        listOffset.current[item.key] < adjustedHeight ||
                        listOffset.current[item.key] == null
                    ) {
                        if (item.value) {
                            item.value.scrollToOffset({
                                offset: adjustedHeight,
                                animated: false,
                            });
                            listOffset.current[item.key] = adjustedHeight;
                        }
                    }
                }
            }
        });
    };

    const renderHeader = () => {
        const y = scrollY.interpolate({
            inputRange: [0, HeaderHeight - safeArea.top],
            outputRange: [0, -HeaderHeight + safeArea.top],
            extrapolateRight: 'clamp',
            // extrapolateLeft: 'clamp',
        });
        const o = scrollY.interpolate({
            inputRange: [HeaderHeight - 120, HeaderHeight - safeArea.top],
            outputRange: [1, 0],
            extrapolateRight: 'clamp',
        });
        return (
            <Animated.View
                style={[
                    styles.header,
                    { zIndex: headerSectionZindex, transform: [{ translateY: y }] },
                ]}
                onLayout={(l) => {
                    setHeaderHeight(l.nativeEvent.layout.height);
                }}
            >
                <Animated.View style={{ opacity: o }}>{props.header}</Animated.View>
            </Animated.View>
        );
    };

    const renderScene: TabViewProps<any>['renderScene'] = ({ route }) => {
        return (
            <TabViewFlatList
                data={[]}
                renderItem={() => <></>}
                scrollEventThrottle={16}
                headerHeight={HeaderHeight}
                tabBarHeight={TabBarHeight}
                currentTabIndex={tabIndex}
                scrollY={scrollY}
                listRefArr={listRefArr}
                listOffset={listOffset}
                isListGliding={isListGliding}
                syncScrollOffset={syncScrollOffset}
                viewKey={route.key}
                {...props.listProsProvider?.(route.key)}
            />
        );
    };

    const renderTabBar = (props: any) => {
        const y = scrollY.interpolate({
            inputRange: [0, HeaderHeight - safeArea.top],
            outputRange: [HeaderHeight, safeArea.top],
            extrapolateRight: 'clamp',
        });
        return (
            <Animated.View
                style={{
                    top: 0,
                    zIndex: headerSectionZindex,
                    position: 'absolute',
                    transform: [{ translateY: y }],
                    width: '100%',
                }}
            >
                <TabBar
                    {...props}
                    onTabPress={({ route, preventDefault }) => {
                        if (isListGliding.current) {
                            preventDefault();
                        }
                    }}
                    style={styles.tab}
                    labelStyle={styles.label}
                    // renderLabel={renderLabel}
                    indicatorStyle={styles.indicator}
                />
            </Animated.View>
        );
    };

    const renderTabView = () => {
        return (
            <TabView
                onIndexChange={(index) => setIndex(index)}
                navigationState={{ index: tabIndex, routes }}
                renderScene={renderScene}
                renderTabBar={renderTabBar}
                initialLayout={{
                    height: 0,
                    width: Dimensions.get('window').width,
                }}
            />
        );
    };

    return (
        <View style={{ flex: 1 }}>
            {renderHeader()}
            {renderTabView()}
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        top: 0,
        // height: HeaderHeight,
        width: '100%',
        backgroundColor: Colors.white,
        // alignItems: 'center',
        // justifyContent: 'center',
        position: 'absolute',
    },
    label: { color: Colors.dark_gray },
    tab: { elevation: 0, shadowOpacity: 0, backgroundColor: Colors.white },
    indicator: { backgroundColor: Colors.primary },
});
