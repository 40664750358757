import { useNavigation } from '@react-navigation/native';
import {
    Collection,
    CollectionService,
    DishInfoWithAvatarImageUrl,
    formatAddress,
} from 'gc-common';
import LottieView from 'lottie-react-native';
import React, { useEffect } from 'react';
import { FlatList, Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { RowItem } from '../../components/RowItem/RowItem';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { useDynamicColumns } from '../../hooks/useDynamicColumns';
import { usePagination } from '../../hooks/usePagination';
import { DEFAULT_DISH_IMAGE, DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { CommonUtils } from '../../utils/commonUtils';
import { styles } from './styles';

export const CollectionPage = () => {
    const auth = useAuth();
    const navigation = useNavigation();
    const { cols, cardWidth } = useDynamicColumns(300);

    const { data, page, isFetching, fetchData, ...pagination } = usePagination<Collection>(
        async (page, size) => {
            let data: Collection[] = [];
            data = (await CollectionService.get(page, 12)).data;

            return data;
        },
        2
    );
    const onInvite = () => {
        let username = auth.user?.display_name;
        let message = `Join ${username} in discovering this amazing restaurant through GourmetCarte`;
        CommonUtils.share(
            `${Env.WEB_SHARE_ROOT}/restaurant?id=${(item: Collection) => {
                item.restaurant_id;
            }}`,
            message
        );
    };

    useEffect(() => {
        pagination.reset();
        auth.location && fetchData(1);

        //locationDetector.bottomSheet.current?.expand();
    }, [auth.location]);

    const onRestaurantItemPressed = (item: Collection) => {
        navigation.navigate('RestaurantDetail', { id: item.restaurant_id });
    };

    const onDishItemPressed = (item: DishInfoWithAvatarImageUrl) => {
        navigation.navigate('DishDetail', { id: item.dish_id });
    };

    return (
        <>
            {auth.location && (
                <FlatList
                    data={data}
                    numColumns={cols}
                    renderItem={({ item }) => {
                        return (
                            <View style={[styles.rowItemWrapper, { width: cardWidth }]}>
                                <RowItem
                                    image={
                                        item.restaurant_avatar_image_url || DEFAULT_RESTAURANT_IMAGE
                                    }
                                    title={item.restaurant_name}
                                    subTitle={formatAddress(item.restaurant_address)}
                                    rating={item.restaurant_rating}
                                    onPress={() => onRestaurantItemPressed(item)}
                                >
                                    <ScrollView horizontal style={styles.dishItemWrapper}>
                                        {item.dish_infos_with_avatar_url?.map((dish, i) => {
                                            return (
                                                <TouchableOpacity
                                                    onPress={() => onDishItemPressed(dish)}
                                                    accessibilityLabel={'Collected Dish'}
                                                >
                                                    <Image
                                                        style={styles.dishImage}
                                                        source={{
                                                            uri:
                                                                dish.dish_avatar_image_url ||
                                                                DEFAULT_DISH_IMAGE,
                                                        }}
                                                    />
                                                    <Text numberOfLines={2} style={styles.dishName}>
                                                        {dish.dish_name}
                                                    </Text>
                                                </TouchableOpacity>
                                            );
                                        })}
                                    </ScrollView>
                                    <View style={styles.inviteButtonWrapper}>
                                        <TouchableOpacity onPress={onInvite}>
                                            <View style={styles.inviteButton}>
                                                <Text style={styles.inviteText}>Invite</Text>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                </RowItem>
                            </View>
                        );
                    }}
                    keyExtractor={(item, i) => '' + i}
                    ListFooterComponent={<View style={styles.listFooterComponent} />}
                    onRefresh={pagination.refresh}
                    refreshing={pagination.isRefreshing}
                    onEndReached={() => fetchData(page + 1)}
                    onEndReachedThreshold={0.5}
                />
            )}
            {!auth.location && Env.isNative && (
                <View style={styles.additionalItemWrapper}>
                    <View style={styles.additionalItem}>
                        <LottieView
                            source={require('../../../assets/images/location.json')}
                            autoPlay
                            loop
                        />
                    </View>
                </View>
            )}
        </>
    );
};
