import { Notification, NotifyService } from 'gc-common';
import { useEffect, useState } from 'react';
import { DeviceEventEmitter, Text, View } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { PaginationDataWithLoader } from '../../hooks/usePagination';
import { RootStackScreenProps } from '../../types';
import { useNotify } from './useNotify';

export const NotificationPage = ({}: RootStackScreenProps<'Notification'>) => {
    const notify = useNotify();
    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {

        (async () => {
            await notify.fetchData();
            await notify.readAll();
        })();
    }, []);

    const onRead = (data: { isActivated: boolean; value: number; key: string }) => {
        if (!data.isActivated) return;
        // Vibration.vibrate();
        DeviceEventEmitter.emit('notification-read', data.key);
        NotifyService.read(data.key);
        // notify.reduceUnreadCount();
    };
    return (
        <SwipeListView<PaginationDataWithLoader<Notification>>
            data={notify.data}
            renderItem={notify.renderItem}
            renderHiddenItem={({ item }) => {
                if (item.$isLoader) return <></>;
                return (
                    <View style={[CommonStyles.flexRowCenter, { height: '100%' }]}>
                        <View
                            style={{
                                flex: 1,
                                padding: 10,
                                backgroundColor: '#2ecc71',
                                height: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <Text style={{ color: Colors.white }}>Read</Text>
                        </View>
                        {/*<View*/}
                        {/*    style={{*/}
                        {/*        flex: 1,*/}
                        {/*        padding: 10,*/}
                        {/*        backgroundColor: Colors.primary,*/}
                        {/*        height: '100%',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*        alignItems: 'flex-end',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Text style={{ color: Colors.white }}>Delete</Text>*/}
                        {/*</View>*/}
                    </View>
                );
            }}
            keyExtractor={(data, index) => data._id!}
            leftActivationValue={50}
            rightActivationValue={50}
            disableLeftSwipe={true}
            onLeftActionStatusChange={onRead}
            refreshing={refreshing}
            onRefresh={async () => {
                setRefreshing(true);
                await notify.refresh();
                setRefreshing(false);
            }}
            onEndReached={() => notify.fetchData(notify.page + 1)}
        />
    );
};
