import { Platform, StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const CommonStyles = StyleSheet.create({
    section: {
        paddingHorizontal: 15,
        borderColor: Colors.light_gray,
        borderStyle: 'solid',
        borderBottomWidth: 1,
        backgroundColor: Colors.white,
        paddingVertical: 24,
    },
    titleContainer: {
        padding: 15,
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 3,
        backgroundColor: Colors.white,
        paddingBottom: 20,
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    title: {
        fontSize: 20,
        fontFamily: 'eina01-bold',
        flex: 1,
    },
    titleIcon: {
        marginRight: 12,
        color: Colors.dark_gray,
        fontSize: 20,
        paddingBottom: Platform.OS === 'ios' ? 5 : 0,
    },
    sectionContent: {
        paddingLeft: 30,
    },

    flexRow: {
        flexDirection: 'row',
    },
    flexColumn: {
        flexDirection: 'column',
    },
    flexRowCenter: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexColumnCenter: {
        flexDirection: 'column',
        justifyContent: 'center',
    },

    shadow: {
        elevation: 5,
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
    },
    stickyFooter: {
        position: 'absolute',
        bottom: 0,
        backgroundColor: Colors.white,
        borderTopWidth: 1,
        borderTopColor: Colors.light_gray,
        width: '100%',
        padding: 15,
    },
});
