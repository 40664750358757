import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'row',
        alignItems: 'stretch',
        margin: 5,
    },

    contentWrapper: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
    },

    flexRow: {
        flexDirection: 'row',
    },

    image: {
        height: 60,
        width: 60,
        borderRadius: 7,
        marginRight: 8,
    },

    title: {
        flex: 1,
        fontSize: 18,
        fontFamily: 'eina01-semibold',
    },
    subTitle: {
        color: Colors.dark_gray,
        fontSize: 12,
    },
    rating: {
        marginTop: 5,
    },
    price: {
        fontSize: 18,
        color: Colors.primary,
    },
});
