import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    headerContainer: {
        backgroundColor: Colors.white,
        elevation: 3,
    },
    searchBarContainer: {
        paddingTop: 0,
        padding: 10,
    },
    selectionList: {
        backgroundColor: Colors.white,
    },
    rowItemWrapper: {
        marginHorizontal: 5,
    },

    creationButtonContainer: {
        marginTop: 15,
        marginBottom: 50,
        marginHorizontal: 15,
    },
    creationButtonHelperText: {
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 15,
    },
    scrollContainer: {
        height: 40,
        position: 'relative',
    },
    scroll: {
        position: 'absolute',
        left: 0,
        width: '100%',
    },
    tabBar: {
        borderTopWidth: 1,
        borderColor: Colors.light_gray,
        position: 'relative',
    },
    englishWordSpecial: {
        marginTop: 2,
    },
    tabItemBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginHorizontal: 10,
        paddingVertical: 10,
        paddingHorizontal: 15,
        position: 'relative',
    },
    tabItem: {
        fontFamily: 'eina01',
    },
    activeTabItem: {
        fontFamily: 'eina01-semibold',
    },
    activeTabBar: {
        borderTopWidth: 3,
        position: 'absolute',
        top: 0,
        marginHorizontal: 15,
        width: '100%',
        borderColor: Colors.primary,
    },
});
