import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Env } from '../../Env';

export const DebugWidget = () => {
    return (
        <View style={styles.wrapper}>
            <Text>
                API_URL: <Text style={styles.value}>{Env.API_URL}</Text>
            </Text>
            <Text>
                APP_ENV: <Text style={styles.value}>{Env.APP_ENV}</Text>
            </Text>
            <Text>
                APP_VERSION: <Text style={styles.value}>{Env.APP_VERSION}</Text>
            </Text>
            <Text>
                BUILD_VERSION: <Text style={styles.value}>{Env.BUILD_VERSION}</Text>
            </Text>
            <Text>
                RUNTIME: <Text style={styles.value}>{Env.RUNTIME}</Text>
            </Text>
            <Text>
                UPDATE_VERSION: <Text style={styles.value}>{Env.UPDATE_VERSION}</Text>
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        padding: 10,
        margin: 10,
        borderRadius: 10,
        borderColor: 'gray',
        backgroundColor: 'white',
    },
    value: {
        fontFamily: 'eina01-semibold',
    },
});
