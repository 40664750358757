import { Entypo, Feather } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import {
    Dish,
    DishService,
    formatAddress,
    Post,
    PostService,
    Restaurant,
    RestaurantService,
} from 'gc-common';
import React, { useEffect, useRef, useState } from 'react';
import { DeviceEventEmitter, Image, Text, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { GCButton } from '../../components/Button/GCButton';
import { Card } from '../../components/Card/Card';
import { RowItem } from '../../components/RowItem/RowItem';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useModal } from '../../context/modalContext';
import { ImageService } from '../../services/image/imageService';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_DISH_IMAGE, DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { Logger } from '../../utils/logger';
import { styles } from './styles';
import { useReviewCreation } from './useReviewCreation';

export const PostCreationPage = ({ route, navigation }: RootStackScreenProps<'PostCreation'>) => {
    const [photos, setPhotos] = useState<string[]>([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | null>(null);
    const [selectedDish, setSelectedDish] = useState<Dish | null>(route.params.dish || null);
    const scrollViewRef = useRef<KeyboardAwareScrollView>(null);
    const isUpdate = route.params.post?.dish_post_id ? true : false;
    const {
        rating,
        content,
        render: renderReviewCreation,
    } = useReviewCreation(
        selectedDish?.dish_id,
        route.params.post?.rating || 0,
        route.params.post?.content || '',
        scrollViewRef
    );

    const modal = useModal();

    useEffect(() => {
        if (route.params.dish?.restaurant_id) {
            getRestaurantByRestaurantId(route.params.dish?.restaurant_id).then((restaurant) => {
                setSelectedRestaurant(restaurant);
            });
        } else if (route.params.post?.restaurant_id) {
            getRestaurantByRestaurantId(route.params.post?.restaurant_id as string).then(
                (restaurant) => {
                    setSelectedRestaurant(restaurant);
                }
            );
            getDishByDishId(route.params.post?.dish_id as string).then((dish) => {
                setSelectedDish(dish);
            });
        }

        if (route.params.post) {
            setPhotos(route.params.post?.image_urls);
        }

        DeviceEventEmitter.addListener('restaurant-selected', (data) => {
            setSelectedRestaurant(data);
            setSelectedDish(null);
        });

        DeviceEventEmitter.addListener('dish-selected', (data) => {
            setSelectedDish(data);
        });

        return () => {
            DeviceEventEmitter.removeAllListeners('restaurant-selected');
            DeviceEventEmitter.removeAllListeners('dish-selected');
        };
    }, []);

    const getRestaurantByRestaurantId = async (restaurant_id: string) => {
        let result = await RestaurantService.get(restaurant_id);
        return result.data;
    };

    const getDishByDishId = async (dish_id: string) => {
        let result = await DishService.get(dish_id);
        return result.data;
    };

    const handleDeletePhoto = (index: number) => {
        setPhotos(photos.filter((_, i) => i !== index));
    };

    const handleImageUpdate = async () => {
        let oldPhotoIds = [];
        let newPhotos = [];
        let uploadedPics = [];
        for (let i = 0; i < photos.length; i++) {
            if (route.params.post?.image_urls?.includes(photos[i])) {
                const index = route.params.post?.image_urls?.indexOf(photos[i]);
                oldPhotoIds.push(route.params.post?.image_ids[index as number]);
            } else {
                newPhotos.push(photos[i]);
            }
        }

        if (newPhotos.length) {
            uploadedPics = await ImageService.uploadImages(newPhotos);
            return oldPhotoIds.concat(uploadedPics.map((i) => i.image_id));
        } else {
            return oldPhotoIds;
        }
    };

    const onPhotoPickerClicked = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: true,
            selectionLimit: 9 - photos.length,
        });

        if (!result.canceled) {
            if (result.assets.length > 9) {
                modal.open('warning', 'Sorry, we only support 9 photos at maximum.');
            }
            setPhotos([...photos, ...result.assets.map((i) => i.uri)]);
        }
    };

    const onPostSubmit = async () => {
        if (!selectedRestaurant && !selectedDish) {
            modal.open('fail', 'Please select a restaurant');
            return;
        }

        if (!selectedDish) {
            modal.open('fail', 'Please select a dish');
            return;
        }

        if (isUpdate) {
            const updatedData: Partial<Post> = {
                content: content,
                rating: rating,
                image_ids: [],
            };

            modal.open('loader');
            if (photos.length) {
                updatedData['image_ids'] = await handleImageUpdate();
            }

            try {
                const updateRsp = await PostService.updateDishPost(
                    route.params.post?.dish_post_id as string,
                    updatedData
                );
                console.log(updateRsp);
                modal.open('success', undefined, () => {
                    // @ts-ignore
                    navigation.replace('PostDetail', { id: updateRsp.data.dish_post_id });
                });
            } catch (e) {
                modal.open('fail', "We can't process your request now.");
                Logger.error('[POST][UPDATE] Post update failed.', e);
            }
            return;
        }

        modal.open('loader');
        try {
            let uploadedPics = undefined;
            if (photos.length) {
                uploadedPics = await ImageService.uploadImages(photos);
            }

            const data: Partial<Post> = {
                dish_name: selectedDish.name,
                dish_id: selectedDish.dish_id,
                restaurant_id: selectedDish.restaurant_id,
                content: content,
                rating: rating,
            };

            if (uploadedPics) data['image_ids'] = uploadedPics.map((i) => i.image_id);

            const rsp = await PostService.create(data);
            console.log(rsp);
            modal.open('success', undefined, () => {
                // @ts-ignore
                navigation.replace('PostDetail', { id: rsp.data.dish_post_id });
            });
        } catch (e) {
            modal.open('fail', "We can't process your request now.");
            Logger.error('[POST][CREAT] Post creation failed.', e);
        }
    };

    return (
        <>
            <KeyboardAwareScrollView ref={scrollViewRef}>
                <View style={CommonStyles.section}>
                    <TouchableOpacity onPress={() => navigation.navigate('RestaurantSelect')}>
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'home'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Restaurant</Text>
                            <Feather name={'chevron-right'} size={20} />
                        </View>
                    </TouchableOpacity>

                    {selectedRestaurant && (
                        <RowItem
                            image={selectedRestaurant.avatar_image_url || DEFAULT_RESTAURANT_IMAGE}
                            title={selectedRestaurant.name}
                            subTitle={formatAddress(selectedRestaurant.address)}
                        />
                    )}
                    {!selectedRestaurant && selectedDish && (
                        <Card>
                            <Text>{selectedDish.restaurant_name}</Text>
                        </Card>
                    )}
                    {!selectedRestaurant && !selectedDish && (
                        <View style={{ marginTop: 20 }}>
                            <GCButton
                                text={'Please select a restaurant'}
                                onClick={() => navigation.navigate('RestaurantSelect')}
                            />
                        </View>
                    )}
                </View>
                <View style={CommonStyles.section}>
                    <TouchableOpacity
                        onPress={() => {
                            if (selectedRestaurant?.restaurant_id) {
                                navigation.navigate('DishSelect', {
                                    restaurantId: selectedRestaurant.restaurant_id,
                                });
                            } else {
                                modal.open('warning', ' Please select a restaurant first');
                            }
                        }}
                    >
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'bowl'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Dish</Text>
                            <Feather name={'chevron-right'} size={20} />
                        </View>
                    </TouchableOpacity>

                    {selectedDish && (
                        <RowItem
                            image={selectedDish.image_urls?.[0] || DEFAULT_DISH_IMAGE}
                            title={selectedDish.name}
                            subTitle={selectedDish.description}
                            price={`$${selectedDish.price}`}
                            rating={selectedDish.rating}
                        />
                    )}
                    {!selectedDish && selectedRestaurant && (
                        <View style={{ marginTop: 20 }}>
                            <GCButton
                                text={'Please select a Dish'}
                                onClick={() => {
                                    if (selectedRestaurant?.restaurant_id) {
                                        navigation.navigate('DishSelect', {
                                            restaurantId: selectedRestaurant.restaurant_id,
                                        });
                                    } else {
                                        modal.open('warning', ' Please select a restaurant first');
                                    }
                                }}
                            />
                        </View>
                    )}
                </View>
                {
                    <View style={CommonStyles.section}>
                        <View style={CommonStyles.titleRow}>
                            <Entypo name={'camera'} style={CommonStyles.titleIcon} />
                            <Text style={CommonStyles.title}>Upload Photos</Text>
                        </View>
                        <View style={styles.photoContainer}>
                            {photos.map((photoUrl, index) => (
                                <View style={styles.photoBox} key={photoUrl}>
                                    <Image style={styles.photo} source={{ uri: photoUrl }} />
                                    <TouchableOpacity
                                        style={styles.deletePhoto}
                                        onPress={() => handleDeletePhoto(index)}
                                    >
                                        <Entypo name={'cross'} size={24} color={Colors.white} />
                                    </TouchableOpacity>
                                </View>
                            ))}
                            {photos.length < 9 && (
                                <TouchableOpacity
                                    style={[styles.photoBox]}
                                    onPress={onPhotoPickerClicked}
                                >
                                    <View style={[styles.addPhoto, { borderWidth: 1 }]}>
                                        <Entypo name={'plus'} size={50} color={Colors.gray} />
                                    </View>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                }
                {renderReviewCreation(!!selectedDish)}

                <View style={styles.bottomSpacer} />
            </KeyboardAwareScrollView>
            <View style={CommonStyles.stickyFooter}>
                <GCButton text={'Submit Post'} onClick={onPostSubmit} disabled={rating == 0} />
                <View style={{ height: 50 }} />
            </View>
        </>
    );
};
