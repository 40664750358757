import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { DishService, PostService, RestaurantService } from 'gc-common';
import { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext';
import { RootStackParamList } from '../types';
import {EventLogger} from "../utils/EventLogger";

export enum LikeEnum {
    dish = 'dish',
    post = 'dish_post',
    restaurant = 'restaurant',
}

export const useLike = (type: LikeEnum, likeId?: string, autoStop: boolean = true) => {
    const [isLike, setIsLike] = useState<boolean>(false);
    const [isLiking, setIsLiking] = useState<boolean>(true);
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    const auth = useAuth();

    useEffect(() => {
        fetchLike();
    }, [likeId]);

    const fetchLike = async () => {
        if (!likeId || !auth.isLoggedIn) return setIsLiking(false);
        let res = { data: false };

        if (type == LikeEnum.dish) {
            res = await DishService.hasDishLiked(likeId);
        } else if (type == LikeEnum.post) {
            res = await PostService.hasPostLiked(likeId);
        } else if (type == LikeEnum.restaurant) {
            res = await RestaurantService.hasRestaurantLiked(likeId);
        }

        setIsLike(res.data);
        setIsLiking(false);
    };
    const onLike = async () => {
        if (!likeId || !auth.isLoggedIn) {
            EventLogger.redirectToAuth('dish_like');
            navigation.push('SignInStack');
            return;
        }
        if (autoStop) setIsLiking(true);

        let res;
        if (type == LikeEnum.dish) {
            EventLogger.clickDishLike(likeId);
            res = await DishService.actionDishLike(likeId, !isLike);
        } else if (type == LikeEnum.post) {
            // EventLogger.clickPostLike(likeId);
            res = await PostService.actionPostLike(likeId, !isLike);
        } else if (type == LikeEnum.restaurant) {
            EventLogger.clickRestaurantLike(likeId);
            res = await RestaurantService.actionRestaurantLike(likeId, !isLike);
        }
        if (!res?.errorMessage) setIsLike(!isLike);
        if (autoStop) setIsLiking(false);
    };

    return {
        isLike,
        isLiking,
        setIsLiking,
        fetchLike,
        onLike,
    };
};
