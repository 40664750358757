import { useNavigation } from '@react-navigation/native';
import { UserService } from 'gc-common';
import LottieView from 'lottie-react-native';
import React from 'react';
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { LOTTIE_JSONS } from '../../types/Constants';
import { styles } from './styles';

export function DeleteAccountPage() {
    const navigation = useNavigation();
    const modal = useModal();
    const auth = useAuth();
    const submit = async () => {
        if (!auth.isLoggedIn) {
            modal.open('fail', 'Please login first!');
            return;
        }
        modal.open('loader');
        await UserService.deleteMyAccount();
        modal.open(
            'success',
            <Text style={{ width: 200, textAlign: 'center' }}>
                We are processing your deletion and it will take 24h to completely delete your
                account.
            </Text>,
            () => {
                auth.logout();
                navigation.navigate('Root');
            }
        );
    };

    return (
        <>
            <ScrollView>
                <View style={styles.screenContainer}>
                    <View style={{ height: 200 }}>
                        {Platform.OS !== 'web' ? (
                            <LottieView
                                source={require('../../../assets/images/117330-warning.json')}
                                autoPlay
                                loop
                            />
                        ) : (
                            <lottie-player src={LOTTIE_JSONS.WARNING} loop autoplay />
                        )}
                    </View>

                    <View style={[styles.titleContainer]}>
                        <Text style={[styles.title, lstyles.title]}>Delete Account !!!</Text>
                        {/*<Text style={styles.subTitle}>Last updated March 18, 2022</Text>*/}
                    </View>
                    <Text style={[styles.content, { fontSize: 18 }]}>
                        We are sorry to see you are leaving. Please confirm if you want to leave us
                        and delete your account.
                    </Text>

                    <Text style={[styles.content, { fontSize: 13, color: 'red' }]}>
                        Note: Deleting your account will delete all contents you have created,
                        including Posts / Dishes / Restaurants / Comments etc. Once you delete your
                        account, there is no way to restore it!
                    </Text>

                    <View
                        style={[
                            CommonStyles.flexRowCenter,
                            { justifyContent: 'center', marginTop: 30 },
                        ]}
                    >
                        <View style={{ marginRight: 10 }}>
                            <GCButton type={'secondary'} text={'Yes'} onClick={submit} />
                        </View>
                        <View>
                            <GCButton
                                type={'primary'}
                                text={'No, please keep my account'}
                                onClick={navigation.goBack}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>
        </>
    );
}

const lstyles = StyleSheet.create({
    title: {
        color: Colors.primary,
        textAlign: 'center',
    },
});
