import React from 'react';
import { Dimensions, Image, Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ImageSource } from 'react-native-image-viewing/dist/@types';

export interface GCImageView {
    images: ImageSource[];
    imageIndex: number;
    visible: boolean;
    onRequestClose: () => void;
}

const HIT_SLOP = { top: 16, left: 16, bottom: 16, right: 16 };

export default function GCImageView({ images, imageIndex, visible, onRequestClose }: GCImageView) {
    const SCREEN = Dimensions.get('screen');
    const SCREEN_WIDTH = SCREEN.width;
    console.log('SCREEN_WIDTH', imageIndex * SCREEN_WIDTH);
    const imageListRef = React.useRef<View>(null);

    return (
        <Modal
            transparent={true}
            visible={visible}
            presentationStyle={'overFullScreen'}
            animationType={'fade'}
            onRequestClose={onRequestClose}
            supportedOrientations={['portrait']}
        >
            <View ref={imageListRef} style={styles.closeBtn}>
                <TouchableOpacity
                    style={styles.closeButton}
                    onPress={onRequestClose}
                    hitSlop={HIT_SLOP}
                >
                    <Text style={styles.closeText}>✕</Text>
                </TouchableOpacity>
            </View>

            <View style={[styles.container]}>
                <View style={styles.imgList}>
                    {images.map((item) => (
                        <View style={styles.imageBox} key={item?.uri}>
                            <Image source={item} style={styles.image}></Image>
                        </View>
                    ))}
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#000',
    },
    imgList: {
        flex: 1,
        overflow: 'scroll',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    imageBox: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '50%',
        width: '100%',
    },
    image: {
        flex: 1,
        width: '100%',
        height: 'auto',
    },
    closeBtn: {
        position: 'absolute',
        zIndex: 1,
        right: 0,
    },
    closeButton: {
        marginRight: 8,
        marginTop: 8,
        width: 45,
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 22.5,
        backgroundColor: '#00000077',
    },
    closeText: {
        lineHeight: 25,
        fontSize: 25,
        paddingTop: 2,
        textAlign: 'center',
        color: '#FFF',
        includeFontPadding: false,
    },
});
