import { FontAwesome5 } from '@expo/vector-icons';
import { Dish, DishService, UserService } from 'gc-common';
import React, { useEffect, useState } from 'react';
import { Animated, Text, View } from 'react-native';
import appsFlyer from 'react-native-appsflyer';
import { useAnimatedTopHeader } from '../../components/AnimatedTopHeader';
import { BottomButton } from '../../components/BottomButton/BottomButton';
import { PicCarousel } from '../../components/PicCarousel/PicCarousel';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_DISH_IMAGE } from '../../types/Constants';
import { styles } from './styles';
import { useDishDetails } from './useDishDetails';
import { useReviews } from './useReviews';
import {EventLogger} from "../../utils/EventLogger";

export const DishDetailScreen = ({ route, navigation }: RootStackScreenProps<'DishDetail'>) => {
    const { id: dishId, post: selectedPostId } = route.params;
    const auth = useAuth();
    const [dish, setDish] = useState<Dish | undefined>();
    const dishDetail = useDishDetails(dish);
    const reviews = useReviews(dishId, selectedPostId);
    const animationHeader = useAnimatedTopHeader(navigation, Colors.white);

    const fetchData = async (id: string) => {
        const data = await DishService.get(id);
        setDish(data.data);
    };

    useEffect(() => {
        if (selectedPostId && auth.isLoggedIn) {
            UserService.incrementUserView(dishId, selectedPostId);
        }
    }, [selectedPostId]);

    useEffect(() => {
        if (Env.isWeb) return;
        console.log('appsFlyer logEvent gc_dish_detail_viewed');
        appsFlyer.logEvent('gc_dish_detail_viewed', { dishId: dishId });
        dish && navigation.setOptions({ title: `${dish.name} | ${dish.restaurant_name}` });
    }, [dish]);

    useEffect(() => {
        console.log('Dish Detail....', dishId);
        EventLogger.viewDish(dishId);
        // DdLogs.debug('Dish Detail....', { dishId });
        fetchData(dishId);
    }, [dishId]);

    const addReview = async () => {
        if (auth.isLoggedIn) navigation.navigate('PostCreation', { dish });
        else navigation.navigate('SignIn' as any);
    };

    const topSectionOfFlatList = () => {
        return (
            <>
                <View style={{ position: 'relative' }}>
                    <PicCarousel
                        pics={
                            dish && dish?.image_urls.length > 0
                                ? dish?.image_urls
                                : [DEFAULT_DISH_IMAGE]
                        }
                        imageHeight={animationHeader.yOffset}
                    />
                </View>

                {dishDetail.render()}

                <View style={styles.detailsContainer}>
                    <View style={styles.sectionTitleContainer}>
                        <FontAwesome5 name={'edit'} size={18} />
                        <Text style={styles.sectionTitle}>Reviews</Text>
                        <Text style={styles.sectionTitleHint}>
                            {reviews?.reviews?.length} Reviews
                        </Text>
                        <View style={styles.sectionTitleFiller} />
                        <Text style={styles.sectionRightTitle}>
                            Most Recent <FontAwesome5 name={'chevron-right'} />
                        </Text>
                    </View>
                </View>

                {reviews?.renderSelectedPost()}
            </>
        );
    };

    const bottomSectionOfFlatList = () => {
        return <View style={{ marginBottom: 100 }} />;
    };

    return (
        <View style={{ flex: 1 }}>
            {dish && (
                <>
                    <Animated.FlatList
                        onScroll={animationHeader.onScrollEvent}
                        scrollEventThrottle={16}
                        ListHeaderComponent={topSectionOfFlatList}
                        ListFooterComponent={bottomSectionOfFlatList}
                        data={reviews?.reviews}
                        renderItem={({ item, index }) => {
                            return (
                                <View style={{ marginHorizontal: 10 }}>
                                    {reviews?.renderCard(item)}
                                </View>
                            );
                        }}
                        refreshing={false}
                        onRefresh={() => {
                            navigation.replace('DishDetail', { id: dishId });
                        }}
                    />

                    <BottomButton text={'Add Review'} onClick={addReview} shadow />
                </>
            )}
            {dishDetail.renderBottomSheet()}
        </View>
    );
};
