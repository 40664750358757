import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Notification, NotifyService } from 'gc-common';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { DeviceEventEmitter, ListRenderItem, Text, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { PaginationDataWithLoader, usePagination } from '../../hooks/usePagination';
import { NotificationState } from '../../states/notification';
import { RootStackParamList } from '../../types';
import { Image } from '../../webLibs/FastImage/FastImage';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { styles } from './styles';

export const useNotify = () => {
    const { data, page, isFetching, fetchData, refresh, ...pagination } =
        usePagination<Notification>(async (page, size) => {
            const rsp = await NotifyService.fetchData(page, 30);
            return rsp.data;
        });

    const [unreadCount, setUnreadCount] = useAtom(NotificationState.unreadCount);

    const getUnreadCount = async () => {
        console.log('fetching unread count');
        const rsp = await NotifyService.countUnread();
        if (rsp.data !== undefined) setUnreadCount(rsp.data);
    };

    const reduceUnreadCount = () => {
        if (unreadCount > 0) setUnreadCount((c) => c - 1);
    };

    const readAll = async () => {
        const r = await NotifyService.readAll();
        setUnreadCount(0);
        console.log(r);
    }

    const renderUnraedBadge = () => {
        if (unreadCount === 0) return <></>;
        return (
            <View
                style={{
                    position: 'absolute',
                    zIndex: 10,
                    minWidth: 17,
                    padding: 2,
                    backgroundColor: 'red',
                    right: 3,
                    top: 0,
                    borderColor: 'white',
                    borderWidth: 1,
                    borderRadius: 7,
                }}
            >
                <Text style={{ color: 'white', fontSize: 10, textAlign: 'center' }}>
                    {unreadCount <= 99 ? unreadCount : '∞'}
                </Text>
            </View>
        );
    };

    const renderItem: ListRenderItem<PaginationDataWithLoader<Notification>> = ({ item }) => {
        return (
            <>
                {!item.$isLoader ? (
                    <NotifyItem item={item} />
                ) : (
                    <SkeletonPlaceholder>
                        <View style={[styles.followWrapper, { backgroundColor: 'white' }]}>
                            <View style={styles.followItemContainer}>
                                <View style={styles.followImageContainer}>
                                    <SkeletonPlaceholder.Item
                                        width={55}
                                        height={55}
                                        borderRadius={50}
                                    />
                                </View>
                                <View style={styles.followInfo}>
                                    <SkeletonPlaceholder.Item
                                        width={'100%'}
                                        height={55}
                                        borderRadius={10}
                                    />
                                </View>
                            </View>
                        </View>
                    </SkeletonPlaceholder>
                )}
            </>
        );
    };

    return {
        data,
        page,
        fetchData,
        isFetching,
        renderItem,
        refresh,
        unreadCount,
        getUnreadCount,
        reduceUnreadCount,
        renderUnraedBadge,
        readAll,
    };
};

const NotifyItem = ({ item }: { item: Notification }) => {
    const [isRead, setIsRead] = useState<boolean>(item.isRead);
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const notify = useNotify();

    useEffect(() => {
        const e = DeviceEventEmitter.addListener('notification-read', (id: string) => {
            // console.log(id);
            if (id === item._id) {
                // if (!isRead) notify.reduceUnreadCount();
                setIsRead(true);
            }
        });
        return () => e.remove();
    }, []);

    const onAvatarPress = (userId: string) => {
        navigation.push('User', { id: userId });
    };

    const onItemPress = (item: Notification) => {
        item._id && NotifyService.read(item._id);
        setIsRead(true);
        notify.reduceUnreadCount();

        if (item.data?.type === 'user.follow') {
            navigation.push('User', { id: item.from });
            return;
        }

        if (item.data?.type === 'dish_post.like') {
            item.data?.data && navigation.push('PostDetail', { id: item.data?.data });
            return;
        }

        if (item.data?.type === 'dish_post.comment') {
            item.data?.data && navigation.push('PostDetail', { id: item.data?.data });
            return;
        }

        navigation.push('User', { id: item.from });
    };

    return (
        <TouchableOpacity
            activeOpacity={1}
            onPress={() => onItemPress(item)}
            style={[styles.followWrapper, { backgroundColor: 'white' }]}
        >
            {!isRead && (
                <View
                    style={{
                        height: 5,
                        width: 5,
                        backgroundColor: Colors.primary,
                        borderRadius: 5,
                        position: 'absolute',
                        left: 10,
                        top: '55%',
                    }}
                />
            )}
            <View style={styles.followItemContainer}>
                <TouchableOpacity
                    activeOpacity={0.3}
                    style={styles.followImageContainer}
                    onPress={() => onAvatarPress(item.from)}
                >
                    {item.fromObj?.profile_photo ? (
                        <Image
                            source={{ uri: item.fromObj.profile_photo }}
                            style={styles.followImage}
                        />
                    ) : (
                        <MaterialCommunityIcons
                            name="bell-circle"
                            size={55}
                            color={Colors.primary}
                        />
                    )}
                </TouchableOpacity>

                <View style={styles.followInfo}>
                    <Text style={styles.followName}>{item.fromObj?.display_name}</Text>
                    {item.body && (
                        <Text style={styles.followDesc} numberOfLines={2} ellipsizeMode={'tail'}>
                            {item.body}
                        </Text>
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
};
