import { ExploreService, Post } from 'gc-common';
import { useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { ItemCard } from '../../components/ItemCard';
import { RootTabScreenProps } from '../../types';
import { useFilterSection } from './useFilterSection';

export const TrendngCusineScreen = ({
    route,
    navigation,
}: RootTabScreenProps<'TrendingCuisine'>) => {
    const [data, setData] = useState<Post[]>([]);
    const filterSection = useFilterSection();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const data = await ExploreService.get();
        setData(data);
    };

    return (
        <View style={styles.screenContainer}>
            <FlatList
                data={data}
                numColumns={2}
                renderItem={({ item }) => {
                    return (
                        <ItemCard
                            pic={
                                'https://images.unsplash.com/photo-1499028344343-cd173ffc68a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
                            }
                            title={item.name}
                            author={{
                                avatar: item.user_avatar,
                                username: item.user_name,
                            }}
                            rating={item.rating}
                            likes={50}
                            onPress={() => {}}
                        />
                    );
                }}
                style={styles.cardContainer}
                keyExtractor={(item, i) => '' + i}
                ListHeaderComponent={filterSection.render}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        width: '100%',
        paddingHorizontal: 10,
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    cardContainer: {
        marginHorizontal: -5,
    },

    item: {},
});
