import { Feather } from '@expo/vector-icons';
import { useEffect, useRef } from 'react';
import { Animated, Easing, StyleProp, View, ViewStyle } from 'react-native';
import { styles } from './styles';

function Spinner({ style }: { style?: StyleProp<ViewStyle> }) {
    const rotateVal = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.loop(
            Animated.timing(rotateVal, {
                useNativeDriver: false,
                toValue: 360,
                duration: 2000,
                easing: Easing.linear,
            })
        ).start();
    }, []);

    const transform = {
        transform: [
            {
                rotate: rotateVal.interpolate({
                    inputRange: [0, 360],
                    outputRange: ['0deg', '360deg'],
                }),
            },
        ],
    };

    return (
        <View style={[style, styles.spinnerContainer]}>
            <Animated.Text style={transform}>
                <Feather name="loader" style={{ fontSize: 14 }} />
            </Animated.Text>
        </View>
    );
}

export default Spinner;
