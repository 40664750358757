import React from 'react';
import { Linking, ScrollView, Text, View } from 'react-native';
import { styles } from './styles';

export function ContactUsPage() {
    const handleBack = () => {
        console.log('click back');
    };
    return (
        <>
            <ScrollView>
                <View style={styles.screenContainer}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>Contact GourmetCarte</Text>
                        {/*<Text style={styles.subTitle}>Last updated March 18, 2022</Text>*/}
                    </View>
                    <Text style={[styles.content, { fontSize: 18 }]}>
                        Should you encounter any problems, please email us at{' '}
                        <Text
                            style={styles.hyperlinkStyle}
                            onPress={() => {
                                Linking.openURL('mailto:admin@gourmetcarte.com');
                            }}
                        >
                            admin@gourmetcarte.com
                        </Text>
                    </Text>
                </View>
            </ScrollView>
        </>
    );
}
