import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Post, PostService, User } from 'gc-common';
import React, { useEffect, useState } from 'react';
import {
    Animated,
    Image,
    ListRenderItem,
    NativeScrollEvent,
    NativeSyntheticEvent,
    TouchableOpacity,
    View,
} from 'react-native';
import { PaginationDataWithLoader, usePagination } from '../../hooks/usePagination';
import { RootStackParamList } from '../../types';
import { DEFAULT_DISH_IMAGE } from '../../types/Constants';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { HeaderDimension, styles } from './styles';

export const usePosts = (user?: User) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const [refreshing, setRefreshing] = useState(false);
    const { tabHeight } = HeaderDimension;

    useEffect(() => {
        if (!user) return;
        fetchData();
    }, [user]);

    const { data, page, isFetching, fetchData, refresh, ...pagination } = usePagination<Post>(
        async (page, size) => {
            if (!user) return [];
            console.log('calling fetch');
            const rsp = await PostService.getAllPostsForX(user.user_id, 'user', page);

            return rsp.data;
        }
    );

    const renderListItem: ListRenderItem<PaginationDataWithLoader<Post>> = ({ item }) => {
        // console.log(item);

        return (
            <View style={styles.postImageWrapper}>
                {!item.$isLoader && (
                    <TouchableOpacity
                        onPress={() => {
                            navigation.push('PostDetail', {
                                id: item.dish_post_id,
                            });
                        }}
                    >
                        <Image
                            style={styles.postImage}
                            source={{ uri: item.image_urls?.[0] }}
                            defaultSource={{ uri: DEFAULT_DISH_IMAGE }}
                        />
                    </TouchableOpacity>
                )}

                {item.$isLoader && (
                    <SkeletonPlaceholder>
                        <SkeletonPlaceholder.Item width={'100%'} aspectRatio={1} borderRadius={5} />
                    </SkeletonPlaceholder>
                )}
            </View>
        );
    };

    const MyPost = (
        headerHeight: number,
        onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
    ) => (
        <Animated.FlatList
            contentContainerStyle={{
                backgroundColor: 'white',
                marginTop: headerHeight + tabHeight,
            }}
            numColumns={3}
            data={data}
            renderItem={renderListItem}
            scrollEventThrottle={16}
            onScroll={onScroll}
            columnWrapperStyle={{
                padding: 1,
            }}
            refreshing={refreshing}
            onRefresh={async () => {
                setRefreshing(true);
                // await getMeInfo();
                setRefreshing(false);
            }}
            onEndReached={() => {
                fetchData(page + 1);
            }}
        />
    );

    return {
        posts: data,
        renderListItem,
        fetchData,
        isFetching,
        page,
        refresh,
        MyPost,
    };
};
