import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { LocationType, parseAddress, Restaurant, RestaurantService } from 'gc-common';
import React, { useState } from 'react';
import { DeviceEventEmitter, ScrollView, Text, View } from 'react-native';
import { uuidv4 } from 'react-native-compressor';
import {
    GooglePlaceData,
    GooglePlaceDetail,
    GooglePlacesAutocomplete,
} from 'react-native-google-places-autocomplete';
import { GCButton } from '../../components/Button/GCButton';
import { InputRow } from '../../components/CustomInputText/InputRow';
import { Colors } from '../../constants/Colors';
import { _useModalHook } from '../../context/modalContext';
import { ImageService } from '../../services/image/imageService';
import { RootStackParamList } from '../../types';
import { GOOGLE_PLACE_API_KEY } from '../../types/Constants';
import { Logger } from '../../utils/logger';
import { styles } from './styles';

export const RestaurantCreationPage = () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const modal = _useModalHook();

    const [googleSessionToken, setGoogleSessionToken] = useState(uuidv4());
    const [newRestaurant, setNewRestaurant] = useState({
        image: undefined,
        name: undefined,
        address: undefined,
        phone: undefined,
        website: undefined,
        hours: undefined as string[] | undefined,
        location: [0, 0],
    });

    const updateField = (field: string, value: any) => {
        setNewRestaurant({
            ...newRestaurant,
            [field]: value,
        } as any);
    };

    const onSelection = (value: Restaurant) => {
        DeviceEventEmitter.emit('restaurant-selected', value);
        navigation.pop(2);
    };

    const onCreateRestaurant = async () => {
        const parsedAddr = parseAddress(newRestaurant.address);
        parsedAddr.location = newRestaurant.location as LocationType;
        console.log(newRestaurant.address, parsedAddr);

        const businessHour = {
            Monday: 'Unknown',
            Tuesday: 'Unknown',
            Wednesday: 'Unknown',
            Thursday: 'Unknown',
            Friday: 'Unknown',
            Saturday: 'Unknown',
            Sunday: 'Unknown',
        };

        if (!newRestaurant.name) {
            modal.open('warning', 'Restaurant name is required.');
            return;
        }

        if (!parsedAddr.address) {
            modal.open('warning', 'Address is required.');
            return;
        }

        modal.open('loader');
        let image_ids: string[] = [];

        try {
            if (newRestaurant.image) {
                const { image_id } = (await ImageService.uploadImages([newRestaurant.image]))[0];
                image_ids = [image_id];
            }
        } catch (e) {
            Logger.error('[Restaurant] [PIC] Restaurant pic upload failed.');
        }

        // Parse BusinessHour
        if (newRestaurant.hours) {
            newRestaurant.hours.map((i) => {
                if (i.includes(': ')) {
                    const parsed = i.split(': ').map((i) => i.trim());
                    // @ts-ignore
                    businessHour[parsed[0]] = parsed[1];
                    // console.log(businessHour,parsed);
                }
            });
        }

        const newResObj: Partial<Restaurant> = {
            name: newRestaurant.name,
            address: parsedAddr,
            business_hour: businessHour,
            currency: 'USD',
            is_active: true,
            image_ids,
            supported_delivery: {
                ubereats: null,
                grubhub: null,
                doordash: null,
                postmates: null,
            },
            supported_reservation: {
                opentable: null,
                tock: null,
            },
        };
        if (newRestaurant.website) newResObj.website = newRestaurant.website;
        if (newRestaurant.phone) newResObj.phone = newRestaurant.phone;

        try {
            const rsp = await RestaurantService.create(newResObj);

            if (rsp.data) {
                // try {
                //     const syncData = await RestaurantService.syncDishes(rsp.data.restaurant_id);
                //     Logger.log(`[DishSync] [Success] Restaurant: ${rsp.data.name}`, rsp.data);
                // } catch (e) {
                //     Logger.error(`[DishSync] [Fail] Restaurant: ${rsp.data.name}`, rsp.data);
                // }
                modal.close();
                onSelection(rsp.data);
            } else {
                modal.open('fail', rsp.errorMessage);
                Logger.error('[Restaurant] [Creation] Creation Failed', rsp.errorMessage);
            }
        } catch (e) {
            modal.open('fail', 'Sorry, we can not process your request now.');
            Logger.error('[Restaurant] [Creation] Creation Failed');
        }
    };

    const onRestaurantSearch = (data: GooglePlaceData, details: GooglePlaceDetail | any) => {
        const newData = {
            name: details.name,
            address: details.formatted_address,
            phone: details.formatted_phone_number,
            website: details.website,
            location: [details.geometry.location.lng, details.geometry.location.lat],
            hours: details.current_opening_hours?.weekday_text,
        };
        setNewRestaurant({
            ...newRestaurant,
            ...newData,
        } as any);
        console.log('Opening Hours:', details.current_opening_hours.weekday_text);
        setGoogleSessionToken(uuidv4());
    };

    return (
        <>
            {modal.render()}
            <ScrollView keyboardShouldPersistTaps={'handled'} style={{ margin: 10 }}>
                <InputRow
                    type={'image'}
                    title={'Upload a pic (Optional)'}
                    initValue={newRestaurant.image}
                    onChangeText={(t) => updateField('image', t)}
                />
                <InputRow type={'custom'} title={'Search Restaurant name'}>
                    <GooglePlacesAutocomplete
                        placeholder={'Please enter restaurant name'}
                        query={{
                            key: GOOGLE_PLACE_API_KEY,
                            language: 'en',
                            types: 'restaurant|food|cafe',
                            sessiontoken: googleSessionToken,
                        }}
                        GooglePlacesDetailsQuery={{ sessiontoken: googleSessionToken }}
                        onPress={onRestaurantSearch as any}
                        onFail={(error) => console.error(error)}
                        fetchDetails={true}
                        //debounce={500}
                        textInputProps={{
                            style: {
                                backgroundColor: Colors.light_gray_2,
                                flex: 1,
                                paddingHorizontal: 15,
                                paddingVertical: 10,
                                borderRadius: 30,
                            },
                            placeholderTextColor: Colors.gray,
                            clearButtonMode: 'always',
                        }}
                    />
                </InputRow>

                {newRestaurant.name && (
                    <InputRow type={'custom'} title={'Restaurant Name:'}>
                        <Text style={styles.dataConfirmationText}>{newRestaurant.name}</Text>
                    </InputRow>
                )}
                {newRestaurant.address && (
                    <InputRow type={'custom'} title={'Address:'}>
                        <Text style={styles.dataConfirmationText}>{newRestaurant.address}</Text>
                    </InputRow>
                )}
                <View style={{ marginBottom: 20 }} />
                <GCButton text={'Create'} onClick={onCreateRestaurant} />
                <View style={{ height: 400 }} />
            </ScrollView>
        </>
    );
};
