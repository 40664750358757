import * as AppleAuthentication from 'expo-apple-authentication';
import * as SecureStore from 'expo-secure-store';
import { useAuth } from '../../context/authContext';
import { authService } from '../../services/auth/authService';

export const useAppleLogin = (callback?: (data: { success: boolean }) => void) => {
    const auth = useAuth();
    const signIn = async () => {
        try {
            const credential = await AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
            });
            // signed in
            const appleFullName = credential.fullName;
            let fullName = '';
            let email = '';
            if (appleFullName?.givenName || appleFullName?.givenName || appleFullName?.nickname) {
                fullName =
                    appleFullName.nickname ||
                    `${appleFullName.givenName} ${appleFullName.familyName}`;
                await SecureStore.setItemAsync('apple-sign-in-fullname', fullName);
                await SecureStore.setItemAsync('apple-sign-in-email', credential?.email || '');
            } else {
                console.log('getting from secure store...');
                fullName = (await SecureStore.getItemAsync('apple-sign-in-fullname')) || '';
                email = (await SecureStore.getItemAsync('apple-sign-in-email')) || '';
            }
            console.log('UserName:', fullName);
            console.log('UserEmail:', email);
            console.log('jwt:', credential.identityToken);

            if (!credential.identityToken) {
                callback?.({ success: false });
                return;
            }

            await authService.signInWithApple(credential.identityToken, fullName);
            await auth.checkIsLoggedIn();

            callback?.({ success: true });
        } catch (e) {
            if (e.code === 'ERR_REQUEST_CANCELED') {
                // handle that the user canceled the sign-in flow
            } else {
                // handle other errors
            }
            callback?.({ success: false });
        }
    };

    return { signIn };
};
