import * as React from 'react';
import { Pressable, PressableProps, Text, View } from 'react-native';
import { Image } from '../../webLibs/FastImage/FastImage';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { Ratings } from '../Ratings/Ratings';
import { styles } from './styles';

export interface ItemCardProps extends PressableProps {
    pic: string;
    rating: number;
    title: string;
    subText?: string;
    author?: {
        avatar?: string;
        username?: string;
    };
    likes?: number;
    isLiked?: boolean;
    tag?: string;
    description?: string;
    extraText?: string;
    skeleton?: boolean;
}

export const ItemCard = (props: ItemCardProps) => {
    if (props.skeleton) {
        return (
            <View style={styles.itemCardWrapper}>
                <View style={styles.itemCard}>
                    <SkeletonPlaceholder>
                        <>
                            <View style={styles.picWrapper}>
                                <View style={styles.pic} />
                            </View>
                            <View style={styles.infoWrapper}>
                                <View style={styles.titleWrapper}>
                                    <SkeletonPlaceholder.Item
                                        width={100}
                                        height={13}
                                        borderRadius={5}
                                    />
                                </View>

                                <View style={styles.ratingWrapper}>
                                    <SkeletonPlaceholder.Item
                                        width={70}
                                        height={13}
                                        borderRadius={5}
                                    />
                                </View>
                                <View style={styles.authorWrapper}>
                                    <View style={styles.authorAvatar} />
                                    <SkeletonPlaceholder.Item
                                        width={100}
                                        height={13}
                                        borderRadius={5}
                                    />
                                </View>
                            </View>
                        </>
                    </SkeletonPlaceholder>
                </View>
            </View>
        );
    } else
        return (
            <View style={styles.itemCardWrapper}>
                <Pressable onPress={props.onPress} accessibilityLabel={'ItemCard'}>
                    <View style={styles.itemCard}>
                        <View style={styles.picWrapper}>
                            <Image style={styles.pic} source={{ uri: props.pic }} />
                            {/*<AntDesign name={'close'} style={styles.deleteButton} />*/}
                        </View>
                        <View style={styles.infoWrapper}>
                            <View style={styles.titleWrapper}>
                                <Text style={styles.title} numberOfLines={1}>
                                    {props.title}
                                </Text>
                            </View>
                            <View style={styles.ratingWrapper}>
                                <Ratings rating={props.rating} />
                            </View>
                            <View style={styles.authorWrapper}>
                                {props.author && (
                                    <>
                                        <Image
                                            style={styles.authorAvatar}
                                            source={{ uri: props.author.avatar }}
                                        />
                                        <Text style={styles.authorName} numberOfLines={1}>
                                            {props.author.username}
                                        </Text>
                                    </>
                                )}
                                {/*<FontAwesome*/}
                                {/*    style={{ paddingTop: Platform.OS === 'android' ? 2 : 0 }}*/}
                                {/*    name={('heart' + (props.isLiked ? '' : '-o')) as any}*/}
                                {/*    color={props.isLiked ? Colors.primary : Colors.dark_gray}*/}
                                {/*/>*/}
                                {/*<Text*/}
                                {/*    style={{*/}
                                {/*        marginLeft: 3,*/}
                                {/*        color: props.isLiked ? Colors.primary : Colors.dark_gray,*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {props.likes}*/}
                                {/*</Text>*/}
                            </View>
                        </View>
                    </View>
                </Pressable>
            </View>
        );
};
