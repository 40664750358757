import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
    CollectionEnum,
    formatAddress,
    formatPriceRange,
    Restaurant,
    SupportedDelivery,
    SupportedReservation,
} from 'gc-common';
import { BusinessHour } from 'gc-common/types/BusinessHour';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { GotoHomeWidget } from '../../components/GotoHomeWidget';
import Spinner from '../../components/Spinner/Spinner';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { useCheckIn } from '../../hooks/useCheckIn';
import { useCollection } from '../../hooks/useCollection';
import { LikeEnum, useLike } from '../../hooks/useLike';
import { useMapSelector } from '../../hooks/useMapSelector';
import { RootStackParamList } from '../../types';
import { CommonUtils } from '../../utils/commonUtils';
import { styles } from './styles';

export const useRestaurantDetails = (restaurant?: Restaurant) => {
    const mapSelector = useMapSelector();
    const auth = useAuth();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const collection = useCollection(CollectionEnum.restaurant, restaurant?.restaurant_id);
    const like = useLike(LikeEnum.restaurant, restaurant?.restaurant_id);
    const checkIn = useCheckIn(restaurant?.restaurant_id);

    const onShare = () => {
        let message = restaurant?.name;
        CommonUtils.share(
            `${Env.WEB_SHARE_ROOT}/restaurant?id=${restaurant?.restaurant_id}`,
            message
        );
    };

    const openMenu = () => {
        restaurant &&
            navigation.push('Menu', {
                restaurantId: restaurant.restaurant_id,
                restaurantName: restaurant.name,
            });
    };

    const render = () => {
        if (!restaurant) return <></>;
        return (
            <View style={styles.section}>
                <View style={styles.titleRow}>
                    <Text style={styles.title} selectable>
                        {restaurant.name}
                    </Text>
                    <Text style={styles.price}>{formatPriceRange(restaurant.price_range)}</Text>
                </View>
                <View style={[styles.titleRow, styles.storeDetailRow]}>
                    <View style={styles.storeAddrWrapper}>
                        <Text style={styles.storeAddr} selectable>
                            <Ionicons name={'location'} size={18} />{' '}
                            {restaurant.address && formatAddress(restaurant.address)} {'\n'}
                        </Text>
                        <TouchableOpacity
                            style={styles.storeActionButton}
                            onPress={() => mapSelector.onNavPressed(restaurant.address)}
                        >
                            <Feather style={styles.storeActionButtonIcon} name={'navigation-2'} />
                        </TouchableOpacity>
                    </View>
                </View>
                {/*<View style={styles.titleRow}>*/}
                {/*    <Ratings rating={restaurant.rating || 0} />*/}
                {/*</View>*/}
                {renderExtraAction()}

                <GotoHomeWidget />

                {/*Uncomment following to add description section*/}
                {/*<View style={styles.titleRow}>*/}
                {/*    <Text style={styles.description}>{restaurant.description}</Text>*/}
                {/*</View>*/}
            </View>
        );
    };

    const renderCheckIn = () => {
        return (
            <View style={styles.section}>
                <View style={styles.checkInWrapper}>
                    <View style={styles.checkInContentWrapper}>
                        <View style={styles.titleRow}>
                            <Text style={styles.checkInTitle}>Are you at this restaurant?</Text>
                        </View>
                        <Text style={styles.description}>Check in and let your friends know!</Text>
                    </View>
                    <View>
                        {checkIn.isCheckingIn ? (
                            <View>
                                <Spinner style={styles.checkInButton} />
                            </View>
                        ) : (
                            <TouchableOpacity onPress={checkIn.onCheckIn}>
                                {checkIn.isCheckIn ? (
                                    <View
                                        style={[styles.checkInButton, styles.checkInButtonActive]}
                                    >
                                        <FontAwesome name={'check'} size={19} color={'white'} />
                                    </View>
                                ) : (
                                    <View style={styles.checkInButton}>
                                        <FontAwesome name={'check'} size={19} color={'white'} />
                                    </View>
                                )}
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
            </View>
        );
    };

    const openHour = () => {
        const today = moment().format('dddd') as keyof BusinessHour;
        return restaurant?.business_hour[today];
    };

    const deliveryInfo = useMemo(() => {
        const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);
        if (!restaurant) return [];
        return Object.keys(restaurant.supported_delivery)
            .map((service) => {
                const url = restaurant.supported_delivery[service as keyof SupportedDelivery];
                if (!url) return null;
                return (
                    <Text style={styles.linkText} onPress={() => Linking.openURL(url)} key={url}>
                        {capitalize(service)} <Ionicons name="open-outline" size={18} />{' '}
                    </Text>
                );
            })
            .filter((i) => i !== null);
    }, [restaurant]);

    const tableInfo = useMemo(() => {
        const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);
        if (!restaurant) return [];
        return Object.keys(restaurant.supported_reservation)
            .map((service) => {
                const url = restaurant.supported_reservation[service as keyof SupportedReservation];
                if (!url) return null;
                return (
                    <Text style={styles.linkText} onPress={() => Linking.openURL(url)} key={url}>
                        {capitalize(service)} <Ionicons name="open-outline" size={18} />{' '}
                    </Text>
                );
            })
            .filter((i) => i !== null);
    }, [restaurant]);

    const renderInfo = () => {
        if (!restaurant) return <></>;
        return (
            <View style={styles.section}>
                {
                    <View style={styles.infoRow}>
                        <Feather name={'clock'} style={styles.infoIcon} />
                        <Text style={styles.infoText}>{openHour()}</Text>
                    </View>
                }
                {restaurant.phone && (
                    <TouchableOpacity
                        style={styles.infoRow}
                        onPress={() => Linking.openURL(`tel:${restaurant.phone}`)}
                    >
                        <Feather name={'phone'} style={styles.infoIcon} />
                        <Text style={styles.infoText}>{restaurant.phone}</Text>
                    </TouchableOpacity>
                )}
                {restaurant.website && (
                    <TouchableOpacity
                        style={styles.infoRow}
                        onPress={() => Linking.openURL(restaurant.website)}
                    >
                        <Feather name={'globe'} style={styles.infoIcon} />
                        <Text style={[styles.infoText, styles.linkText]}>
                            Website <Ionicons name="open-outline" size={18} />
                        </Text>
                    </TouchableOpacity>
                )}
                {restaurant.supported_delivery && deliveryInfo.length > 0 && (
                    <View style={styles.infoRow}>
                        <Feather name={'truck'} style={styles.infoIcon} />
                        <Text style={[styles.infoText]}>Delivery • {deliveryInfo}</Text>
                    </View>
                )}
                {restaurant.supported_reservation && tableInfo.length > 0 && (
                    <View style={styles.infoRow}>
                        <Feather name={'calendar'} style={styles.infoIcon} />
                        <Text style={styles.infoText}>Find a table • {tableInfo}</Text>
                    </View>
                )}
            </View>
        );
    };

    const renderDescription = () => {
        if (!restaurant || !restaurant.info) return <></>;
        return (
            <View style={styles.section}>
                <View style={styles.titleRow}>
                    <Feather name={'info'} style={styles.titleIcon} />
                    <Text style={styles.title}>Restaurant Information</Text>
                </View>
                <Text style={styles.descriptionContent} selectable>
                    {restaurant.info}{' '}
                </Text>
            </View>
        );
    };

    const renderExtraAction = () => {
        return (
            <ScrollView horizontal style={styles.extraActionContainer}>
                {like.isLiking ? (
                    <View style={styles.extraButtonWrapper}>
                        <Spinner style={styles.extraButton} />
                    </View>
                ) : (
                    <TouchableOpacity style={styles.extraButtonWrapper} onPress={like.onLike}>
                        {like.isLike ? (
                            <Text style={[styles.extraButton, styles.extraActivationButton]}>
                                <FontAwesome name={'thumbs-up'} /> Unlike
                            </Text>
                        ) : (
                            <Text style={styles.extraButton}>
                                <FontAwesome name={'thumbs-o-up'} /> Like
                            </Text>
                        )}
                    </TouchableOpacity>
                )}

                {collection.isCollecting ? (
                    <View style={styles.extraButtonWrapper}>
                        <Spinner style={styles.extraButton} />
                    </View>
                ) : (
                    <TouchableOpacity
                        style={styles.extraButtonWrapper}
                        onPress={collection.onCollect}
                    >
                        {collection.isCollect ? (
                            <Text style={[styles.extraButton, styles.extraActivationButton]}>
                                <FontAwesome name={'star'} /> Uncollect
                            </Text>
                        ) : (
                            <Text style={styles.extraButton}>
                                <FontAwesome name={'star-o'} /> Collect
                            </Text>
                        )}
                    </TouchableOpacity>
                )}

                <TouchableOpacity style={styles.extraButtonWrapper} onPress={openMenu}>
                    <Text style={styles.extraButton}>
                        <Ionicons name={'menu'} /> Menu
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.extraButtonWrapper} onPress={onShare}>
                    <Text style={styles.extraButton}>
                        <FontAwesome name={'share-alt'} /> Share
                    </Text>
                </TouchableOpacity>
            </ScrollView>
        );
    };

    return {
        render,
        renderInfo,
        renderCheckIn,
        renderDescription,
        renderExtraAction,
        renderMapSelectorBottomSheet: mapSelector.renderBottomSheet,
    };
};
