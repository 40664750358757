import { useEffect } from 'react';
import WebView from 'react-native-webview';
import { RootStackScreenProps } from '../../types';

export const WebviewScreen = ({ route, navigation }: RootStackScreenProps<'Webview'>) => {
    useEffect(() => {}, []);

    const { url } = route.params;

    const injectedJavaScript = `
    window.ReactNativeWebView.postMessage(document.title);
    `;

    const onMessage = (event: any) => {
        // console.log(event.nativeEvent.data);
        navigation.setOptions({ title: event.nativeEvent.data || '' });
    };
    return (
        <WebView
            source={{ uri: url }}
            injectedJavaScript={injectedJavaScript}
            onMessage={onMessage}
        />
    );
};
