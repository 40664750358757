import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { meterToMiles, Post, Restaurant, RestaurantService } from 'gc-common';
import React, { useEffect } from 'react';
import { FlatList, ListRenderItem, Text, View } from 'react-native';
import { FullItemCard } from '../../components/ItemCard/FullItemCard';
import { PaginationDataWithLoader, usePagination } from '../../hooks/usePagination';
import { RootStackParamList } from '../../types';
import { DEFAULT_USER_AVATAR } from '../../types/Constants';
import { styles } from './styles';

export const usePosts = (restaurant?: Restaurant) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const {
        data: posts,
        fetchData,
        page,
    } = usePagination<Post>(async (page, size) => {
        if (!restaurant) return [];
        const data = await RestaurantService.getRelevantPosts(
            restaurant?.restaurant_id,
            [0, 0],
            99999999,
            page,
            20
        );
        return data.data;
    }, 1);

    useEffect(() => {
        restaurant && fetchData();
    }, [restaurant]);

    const renderItem: ListRenderItem<PaginationDataWithLoader<Post>> = ({ item }) => {
        return (
            <View style={{ marginHorizontal: 10, marginVertical: 5, marginBottom: 35 }}>
                <FullItemCard
                    title={item.dish_name}
                    pic={item.image_urls?.[0]}
                    author={{ avatar: item.user_profile_photo_url || DEFAULT_USER_AVATAR }}
                    subText={item.restaurant_name}
                    rating={item.rating}
                    description={item.content}
                    extraText={meterToMiles(item.distance)}
                    likes={item.num_of_likes}
                    key={item.dish_post_id}
                    width={300}
                    skeleton={item.$isLoader}
                    onPress={() =>
                        navigation.push('DishDetail', { id: item.dish_id, post: item.dish_post_id })
                    }
                    fixedHeight={400}
                    // skeleton
                />
            </View>
        );
    };

    const render = () => {
        if (!restaurant) return <></>;

        return (
            <View style={styles.section}>
                <View style={styles.titleRow}>
                    <FontAwesome5 name={'edit'} style={styles.titleIcon} />
                    <Text style={styles.title}>Relevant Posts</Text>
                </View>
                {/*{posts.map((post) => renderItem(post))}*/}
                <FlatList
                    data={posts}
                    renderItem={renderItem}
                    horizontal={true}
                    onEndReached={() => fetchData(page + 1)}
                    onEndReachedThreshold={200}
                />
            </View>
        );
    };

    return {
        render,
        posts,
    };
};
