import { useNavigation } from '@react-navigation/native';
import {
    formatAddress,
    LocationUtils,
    meterToMiles,
    Restaurant,
    RestaurantService,
} from 'gc-common';
import LottieView from 'lottie-react-native';
import React, { useEffect, useMemo } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { FullItemCard } from '../../components/ItemCard/FullItemCard';
import { useAuth } from '../../context/authContext';
import { Env } from '../../Env';
import { usePagination } from '../../hooks/usePagination';
import { DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { ScreenUtils } from '../../utils/screenUtils';
import { useFilterSection } from './useFilterSection';

interface HomeRestaurantViewProps {
    setCurrentView: (i: number) => void;
}
export const HomeRestaurantView = ({ setCurrentView }: HomeRestaurantViewProps) => {
    const auth = useAuth();
    const navigation = useNavigation();
    const filterSection = useFilterSection('restaurant', setCurrentView);
    const cols = useMemo(() => {
        return ScreenUtils.calcGridCols(300, 1);
    }, []);

    const { data, page, isFetching, fetchData, ...pagination } = usePagination<Restaurant>(
        async (page, size) => {
            let data: Restaurant[] = [];
            if (filterSection.sort === 'distance')
                data = (
                    await RestaurantService.getByGeo(auth.location || [0, 0], 999999999, page, 12)
                ).data;
            else if (filterSection.sort === 'rating')
                data = (
                    await RestaurantService.getByGeo(auth.location || [0, 0], 999999999, page, 12)
                ).data;

            return data;
        },
        2
    );

    useEffect(() => {
        pagination.reset();
        fetchData();
    }, [filterSection.sort]);

    useEffect(() => {
        pagination.reset();
        auth.location && fetchData(1);

        //locationDetector.bottomSheet.current?.expand();
    }, [auth.location]);

    const onItemPressed = (item: Restaurant) => {
        navigation.navigate('RestaurantDetail', { id: item.restaurant_id });
    };

    return (
        <>
            {auth.location && (
                <FlatList
                    stickyHeaderIndices={[0]}
                    data={data}
                    numColumns={cols}
                    renderItem={({ item }) => {
                        return (
                            <TouchableOpacity
                                style={{
                                    padding: 10,
                                    width: ScreenUtils.colsToWidthPercentage(cols || 1),
                                }}
                                onPress={() => {
                                    navigation.navigate('RestaurantDetail', {
                                        id: item.restaurant_id,
                                    });
                                }}
                            >
                                <FullItemCard
                                    pic={item.image_urls?.[0] || DEFAULT_RESTAURANT_IMAGE}
                                    title={item.name}
                                    subText={formatAddress(item.address)}
                                    rating={item.rating ?? 0}
                                    onPress={() => onItemPressed(item)}
                                    extraText={meterToMiles(
                                        LocationUtils.calcDistance(
                                            auth.location || [0, 0],
                                            item.address?.location || [0, 0]
                                        )
                                    )}
                                    skeleton={item.$isLoader}
                                />
                            </TouchableOpacity>
                        );
                    }}
                    keyExtractor={(item, i) => '' + i}
                    ListHeaderComponent={<>{filterSection.render()}</>}
                    ListFooterComponent={<View style={{ height: 100 }} />}
                    onRefresh={pagination.refresh}
                    refreshing={pagination.isRefreshing}
                    onEndReached={() => fetchData(page + 1)}
                    onEndReachedThreshold={0.5}
                />
            )}
            {!auth.location && Env.isNative && (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ width: 200, height: 200 }}>
                        <LottieView
                            source={require('../../../assets/images/location.json')}
                            autoPlay
                            loop
                        />
                    </View>
                </View>
            )}
            {filterSection.renderBottomSheet()}
            {filterSection.renderSortingBox()}
        </>
    );
};
