import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';
import { styles as RestaurantDetailStyles } from '../RestaurantDetail/styles';
export const styles = StyleSheet.create({
    screenContainer: {
        width: '100%',
        // paddingHorizontal: 5,
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: Colors.white,
    },
    cardContainer: {
        marginHorizontal: 5,
    },

    skeletonContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    wrapper: {
        padding: 10,
    },
    title: {
        fontFamily: 'eina01-semibold',
        fontSize: 18,
        marginBottom: 10,
    },
    storeCardWrapper: {
        marginBottom: 5,
    },
    checkInButton: RestaurantDetailStyles.checkInButton,
    checkInButtonActive: RestaurantDetailStyles.checkInButtonActive,
    item: {},
});
