import { useNavigation } from '@react-navigation/native';
import * as Google from 'expo-auth-session/providers/google';
import { useEffect, useState } from 'react';
import { Button, Text, View } from 'react-native';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { Env } from '../../Env';
import { authService } from '../../services/auth/authService';

export const useGoogleLogin = (callback?: (data: { success: boolean }) => void) => {
    const [token, setToken] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const modal = useModal();
    const auth = useAuth();
    const navigation = useNavigation();

    const [request, response, promptAsync] = Google.useAuthRequest({
        androidClientId: Env.ANDROID_GOOGLE_CLIENT_ID,
        iosClientId: Env.IOS_GOOGLE_CLIENT_ID,
        webClientId: Env.WEB_GOOGLE_CLIENT_ID,
    });

    useEffect(() => {
        if (response?.type === 'success') {
            const token = response.authentication?.accessToken;
            if (!token) {
                callback?.({ success: false });
                return;
            }
            console.log('Get google token=>');
            login(token);
        }
    }, [response]);

    const login = async (token: string) => {
        try {
            await authService.signInWithGoogle(token);
            await auth.checkIsLoggedIn();

            callback?.({ success: true });
        } catch (e) {
            callback?.({ success: false });
        }
    };

    const render = () => {
        return (
            <View>
                {userInfo === null ? (
                    <Button
                        title="Sign in with Google"
                        disabled={!request}
                        onPress={() => {
                            promptAsync();
                        }}
                    />
                ) : (
                    <Text>{userInfo.name}</Text>
                )}
            </View>
        );
    };

    return {
        render,
        promptAsync,
    };
};
