import { Image, StyleSheet, Text, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import { GCButton } from '../../components/Button/GCButton';
import { Colors } from '../../constants/Colors';

export default function CollectionNoLogin() {
    const navigation = useNavigation<any>();
    useEffect(() => {}, []);

    return (
        <View style={styles.container}>
            <Image
                resizeMethod={'scale'}
                resizeMode={'contain'}
                style={styles.icon}
                source={require('../../../assets/images/logo-no-margin.png')}
            />
            <Text style={styles.title}>Please sign up</Text>
            <Text style={styles.subTitle}>
                To collect your favorite dishes and restaurants, please create an account with us.
            </Text>
            <View style={{ marginVertical: 20 }}>
                <GCButton
                    text={' Sign In / Create an account '}
                    onClick={() => navigation.navigate('SignIn')}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        // padding: 20,
        paddingHorizontal: 40,
        paddingBottom: 100,
        backgroundColor: Colors.white,
    },
    title: {
        fontSize: 36,
        marginVertical: 20,
        color: Colors.charcoal,
        fontFamily: 'eina01-bold',
    },
    subTitle: {
        fontSize: 20,
        marginBottom: 5,
        color: Colors.dark_gray,
    },
    icon: {
        width: '100%',
        // aspectRatio:1,
        // borderWidth:1,
        height: 200,
    },
});
