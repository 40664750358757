import { Entypo, Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { User } from 'gc-common';
import React from 'react';
import { Alert, Animated, Text, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors } from '../../constants/Colors';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { Env } from '../../Env';
import { CommonUtils } from '../../utils/commonUtils';
import { styles } from './styles';
import { useNotify } from './useNotify';

export const useHeader = (user?: User, isSelf?: boolean, refresh?: () => void) => {
    const navigation = useNavigation();
    const auth = useAuth();
    const model = useModal();
    const notify = useNotify();

    const onShare = () => {
        CommonUtils.share(`${Env.WEB_SHARE_ROOT}/user?id=${user?.user_id}`);
    };

    const onBlockUser = async () => {
        // Linking.openURL('https://apps.apple.com/us/app/gourmetcarte/id1673184423');
        // Linking.openURL('https://play.google.com/store/apps/details?id=com.gourmetcarte.app');
        // return;
        if (user) {
            Alert.alert(
                'Are you sure you want to block this user?',
                "You won't be able to his/her posts anymore.",
                [
                    {
                        text: 'No',
                        onPress: () => {},
                        style: 'cancel',
                    },
                    {
                        text: 'Yes',
                        style: 'destructive',
                        onPress: async () => {
                            await auth.blockUser(user);
                            model.open('success', 'Successfully blocked this user.');
                        },
                    },
                ]
            );
        }
    };

    const render = (scrollY: Animated.Value) => {
        const s = scrollY.interpolate({
            inputRange: [-100, 0],
            outputRange: [2.5, 1],
            extrapolate: 'clamp',
        });
        const y = scrollY.interpolate({
            inputRange: [-100, 0],
            outputRange: [-30, 0],
            extrapolate: 'clamp',
        });
        return (
            <SafeAreaView edges={['top']} style={styles.topSectionContainer}>
                <Animated.Image
                    style={[
                        styles.headerBG,
                        {
                            transform: [{ scale: s }, { translateY: y }],
                        },
                    ]}
                    source={require('../../../assets/images/me_bg_red.jpg')}
                />
                <View style={styles.toolbar}>
                    {true && (
                        <TouchableOpacity
                            style={styles.toolBarIcon}
                            onPress={() => navigation.goBack()}
                        >
                            <Feather name={'arrow-left'} size={20} color={Colors.white} />
                        </TouchableOpacity>
                    )}

                    <View style={{ flex: 1 }} />
                    {isSelf && (
                        <TouchableOpacity
                            style={styles.toolBarIcon}
                            onPress={() => navigation.navigate('Notification')}
                        >
                            {notify.renderUnraedBadge()}
                            <Ionicons name="notifications" size={20} color={Colors.white} />
                        </TouchableOpacity>
                    )}
                    <TouchableOpacity style={styles.toolBarIcon} onPress={refresh}>
                        <FontAwesome name={'refresh'} size={20} color={Colors.white} />
                    </TouchableOpacity>
                    {Env.isNative && (
                        <TouchableOpacity style={styles.toolBarIcon} onPress={onShare}>
                            <FontAwesome name={'share-alt'} size={20} color={Colors.white} />
                        </TouchableOpacity>
                    )}
                    {isSelf && (
                        <TouchableOpacity
                            style={styles.toolBarIcon}
                            onPress={() => navigation.navigate('Settings')}
                        >
                            <FontAwesome name={'ellipsis-h'} size={20} color={Colors.white} />
                        </TouchableOpacity>
                    )}
                    {!isSelf && (
                        <TouchableOpacity style={styles.toolBarIcon} onPress={onBlockUser}>
                            <Entypo name="block" size={20} color={Colors.white} />
                        </TouchableOpacity>
                    )}
                </View>

                <View style={styles.detailsContainer}>
                    <Text style={styles.displayName}>{user?.display_name}</Text>
                </View>
            </SafeAreaView>
        );
    };

    return {
        render,
        notify,
    };
};
