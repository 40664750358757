import { StyleSheet } from 'react-native';
import { Colors } from '../../constants/Colors';

export const styles = StyleSheet.create({
    buttonWrapper: {
        backgroundColor: Colors.white,
        borderRadius: 7,
        borderColor: Colors.gray,
        borderWidth: 1,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeButtonWrapper: {
        backgroundColor: Colors.charcoal,
        borderColor: Colors.charcoal,
    },

    groupedButtonWrapper: {
        borderWidth: 0,
        borderRadius: 6,
    },

    buttonGroup: {
        flexDirection: 'row',
        backgroundColor: Colors.white,
        borderRadius: 7,
        borderColor: Colors.gray,
        borderWidth: 1,
    },

    text: {
        color: Colors.dark_gray,
    },

    activeText: {
        color: Colors.white,
    },
});
