import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { UPDATE_VERSION } from '../version';

function get(name: string) {
    const value = Constants?.expoConfig?.extra?.[name] || '';

    if (!value) {
        console.error(name + ' is missing.');
    }

    return value;
}

export const Env = {
    API_URL: get('API_URL'),
    APP_ENV: get('ENV') || 'unknown',
    APP_VERSION: get('VERSION_PREFIX') + Application.nativeApplicationVersion,
    ANDROID_GOOGLE_CLIENT_ID: get('ANDROID_GOOGLE_CLIENT_ID'),
    IOS_GOOGLE_CLIENT_ID: get('IOS_GOOGLE_CLIENT_ID'),
    WEB_GOOGLE_CLIENT_ID: get('WEB_GOOGLE_CLIENT_ID'),
    BUILD_VERSION: Application.nativeBuildVersion,
    RUNTIME: Constants?.expoConfig?.runtimeVersion as string,
    UPDATE_VERSION: UPDATE_VERSION,
    WEB_SHARE_ROOT:
        get('ENV') === 'production'
            ? 'https://web.gourmetcarte.com'
            : 'https://web.staging.gourmetcarte.com',
    isNative: Platform.OS !== 'web',
    isWeb: Platform.OS === 'web',
};
